<template>
  <div class="detail-image-edit">
    <!-- 로딩 중일 때 표시 -->
    <div v-if="loading" class="loading-container">
      <b-spinner variant="primary"></b-spinner>
      <p>{{ progressMessage || '상세 이미지 작업 중...' }}</p>
    </div>

    <!-- 에디터 전체 화면 레이아웃 -->
    <div v-else class="editor-fullscreen-layout">
      <!-- 수정된 editor-header 부분 -->
      <div class="editor-header">
        <div class="editor-title">
          <h5>상세 페이지 편집</h5>
        </div>
        <div class="mode-toggle">
          <b-button-group size="sm">
            <b-button :variant="isPreviewMode ? 'outline-primary' : 'primary'" @click="switchToEditMode">
              <i class="las la-edit"></i> 편집 모드
            </b-button>
            <b-button :variant="isPreviewMode ? 'primary' : 'outline-primary'" @click="switchToPreviewMode">
              <i class="las la-eye"></i> 미리보기 모드
            </b-button>
          </b-button-group>
        </div>
        <div class="editor-actions">
          <!-- 이미지 간격 조절 컨트롤을 여기로 이동 -->
          <div class="margin-control d-inline-flex mr-2">
            <b-input-group size="sm">
              <b-input-group-prepend>
                <b-input-group-text>이미지 간격</b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="number" v-model="imageMargin" min="0" max="100" step="5" style="width: 60px;"
                @change="applyImageMargin"></b-form-input>
              <b-input-group-append>
                <b-input-group-text>px</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </div>
          <b-button variant="outline-primary" size="sm" @click="test" title="테스트">
            <i class="las la-undo"></i> 테스트
          </b-button>
          <b-button variant="outline-secondary" size="sm" @click="resetEditor" title="초기화">
            <i class="las la-undo"></i> 초기화
          </b-button>
          <b-button variant="outline-success" size="sm" @click="processAllImages" title="AI 자동 생성" class="ml-2">
            <i class="las la-magic"></i> AI 자동 생성
          </b-button>
          <b-button variant="success" size="sm" @click="saveDetailPage" title="저장" class="ml-2">
            <i class="las la-save"></i> 저장
          </b-button>
          <b-button variant="outline-primary" size="sm" @click="goToNextStep" title="다음 단계" class="ml-2">
            <i class="las la-arrow-right"></i> 다음 단계
          </b-button>
        </div>
      </div>

      <!-- 미리보기 모드 UI -->
      <div v-if="isPreviewMode" class="preview-container">
        <div class="preview-scroll-container">
          <div class="detail-content-preview">
            <div v-html="compiledHtml"></div>
          </div>
        </div>
      </div>

      <!-- 수정: 에디터 영역 (편집 모드) 부분 -->
      <div v-else class="editor-main-area">
        <div class="editor-container">
          <ImageEditor v-if="editorReady" ref="imageEditor" :html="detailHtml" :hideBlockManager="false"
            @save="handleEditorSave" @error="handleEditorError" />
          <div v-else class="editor-loading">에디터 로딩 중...</div>
        </div>

        <!-- 로딩 오버레이 -->
        <div v-if="isProcessing" class="loading-overlay">
          <div class="loading-content">
            <b-spinner variant="primary" label="처리 중..."></b-spinner>
            <p>상세 페이지를 처리 중입니다...</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 이미지 업로드를 위한 숨겨진 input 추가 -->
    <input ref="imageUploadInput" type="file" accept="image/*" multiple class="d-none" @change="handleImageUpload">
  </div>
</template>

<script>
import ImageEditor from './ImageEditor.vue'
import { mapState, mapActions } from 'vuex'
import detailImageService from '../../services/works/detailImageService'
// import html2canvas from 'html2canvas'
// import imageService from '../../services/imageService'

export default {
  name: "DetailImageEdit",
  components: {
    ImageEditor
  },
  props: {
    wizardData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      activeTab: 'detail',
      selectedImageType: '',
      selectedImageIndex: -1,
      selectedImageUrl: null,
      isPreviewMode: false,
      detailHtml: '', // 상세 페이지 HTML
      processedImages: [], // 처리된 이미지 배열
      isProcessing: false, // 처리 중 상태
      selectedTemplate: 'basic',
      templateOptions: [
        { value: 'basic', text: '기본 템플릿' },
        { value: 'gallery', text: '갤러리 템플릿' },
        { value: 'catalog', text: '카탈로그 템플릿' }
      ],
      uploadFiles: null,
      currentDetailImage: null,
      showModal: false,
      showFullPreview: false,
      lastModifiedDate: null,
      editorReady: false,
      imageMargin: 20,
    };
  },
  computed: {
    // Vuex 상태 매핑
    ...mapState({
      product: state => state.selectedRocketProduct,
      processing: state => state.processing,
      progressMessage: state => state.progressMessage,
      defaultSettings: state => {
        // Vuex store에서 defaultSettings 가져오기
        return state.settings && state.settings[0] && state.settings[0].defaultSettings
          ? state.settings[0].defaultSettings
          : {};
      }
    }),

    // 로딩 상태
    loading() {
      return this.processing;
    },

    // 메인 이미지 목록
    mainImages() {
      return this.product?.mainImages || [];
    },

    // 상세 이미지 목록
    detailImages() {
      return this.product?.detailImages || [];
    },

    // 상세 이미지 상태
    detailImageStatus() {
      return this.product?.detailImageStatus === 'completed';
    },

    // 상세 이미지 개수
    detailImagesCount() {
      return this.detailImages ? this.detailImages.length : 0;
    },

    // 최근 수정일 (포맷팅)
    lastModified() {
      if (!this.lastModifiedDate) {
        return '없음';
      }

      const date = new Date(this.lastModifiedDate);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    },

    // 컴파일된 HTML
    compiledHtml() {
      // console.log(this.detailHtml)
      return this.detailHtml;
    },

    // 축약된 HTML (미리보기용)
    compiledHtmlShort() {
      if (!this.detailHtml) return '';

      // 첫 번째 이미지만 표시
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.detailHtml, 'text/html');
      const images = doc.querySelectorAll('img');

      if (images.length > 0) {
        return `<div style="text-align:center;"><img src="${images[0].src}" style="max-width:100%; max-height:200px;" /><div style="margin-top:10px; font-size:12px; color:#888;">외 ${images.length - 1}개 이미지</div></div>`;
      }

      return '<div style="text-align:center; padding:20px;">이미지가 없습니다</div>';
    },

    // 현재 작업 중인 이미지 URL
    currentImageUrl() {
      return this.currentDetailImage || this.selectedImageUrl || '';
    }
  },
  created() {
    // 초기 HTML 생성


    // 상품에 이미 상세 페이지가 있는 경우
    if (this.product && this.product.detailHtml) {
      this.detailHtml = this.product.detailHtml;
      this.lastModifiedDate = this.product.lastModified || new Date();
    } else {
      this.initializeDetailHtml();
    }
  },
  mounted() {
    console.log('DetailImageEdit 마운트됨, 선택된 제품:', this.product);
    console.log('defaultSettings:', this.defaultSettings);

    // 최초 HTML 내용이 있는지 확인
    if (!this.detailHtml || this.detailHtml.trim() === '') {
      this.initializeDetailHtml();
    }

    // 에디터 초기화를 위한 짧은 지연
    setTimeout(() => {
      this.editorReady = true;

      this.$nextTick(() => {
        if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
          console.log('에디터에 HTML 설정:', this.detailHtml.length, '자');
          // console.log(this.detailHtml)
          // HTML 설정
          this.$refs.imageEditor.editor.setComponents(this.detailHtml);

          // 에디터 설정 완료 후 추가 작업
          this.$refs.imageEditor.editor.on('load', () => {
            console.log('GrapesJS 에디터 로드 완료');
          });
        }
      });
    }, 500);
  },
  beforeDestroy() {
    // 이벤트 리스너 제거
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('resize', this.handleWindowResize);

    // 타이머 정리
    clearTimeout(this.resizeTimer);

    // observer 정리
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  },
  methods: {
    // Vuex 액션 매핑
    ...mapActions([
      'updateRocketProduct',
      'setProcessing'
    ]),
    handleWindowResize() {
      // 디바운스 적용
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.adjustEditorHeight();
      }, 300);
    },
    initializeDetailHtml() {
      console.log('initializeDetailHtml 시작');
      if (!this.product) {
        this.detailHtml = '<div style="text-align:center; padding:20px;">상품 정보가 없습니다.</div>';
        return;
      }

      let html = `
  <div style="width:100%; max-width:800px; margin:0 auto; background-color:#ffffff; padding:20px;">
  `;

      // 상단 이미지 추가 (defaultSettings에서 가져옴)
      const settings = this.defaultSettings.imageSettings || {};
      if (settings.useTopImage && settings.topImageUrl) {
        html += `
    <div style="margin-bottom:${this.imageMargin}px; text-align:center;">
      <img src="${settings.topImageUrl}" alt="상단 이미지" style="max-width:100%; height:auto; width:760px; display:block; margin:0 auto;">
    </div>
  `;
      }

      // 상세 이미지 추가
      if (this.product.detailImages && this.product.detailImages.length > 0) {
        this.product.detailImages.forEach((imageUrl, index) => {
          html += `
      <div style="margin-bottom:${this.imageMargin}px; text-align:center;">
        <img src="${imageUrl}" alt="상세 이미지 ${index + 1}" style="max-width:100%; height:auto; width:760px; display:block; margin:0 auto;">
      </div>
    `;
        });
      } else {
        html += '<div style="text-align:center; padding:40px; color:#999; font-size:18px;">상세 이미지가 없습니다.</div>';
      }

      // 하단 이미지 추가 (defaultSettings에서 가져옴)
      if (settings.useBottomImage && settings.bottomImageUrl) {
        html += `
    <div style="margin-bottom:${this.imageMargin}px; text-align:center;">
      <img src="${settings.bottomImageUrl}" alt="하단 이미지" style="max-width:100%; height:auto; width:760px; display:block; margin:0 auto;">
    </div>
  `;
      }

      html += '</div>';
      this.detailHtml = html;
      console.log('initializeDetailHtml 완료, HTML 길이:', html.length);
    },

    // 이미지 선택 처리
    selectImage(type, index, imageUrl) {
      console.log(`이미지 선택: ${type} 이미지, 인덱스: ${index}`);

      if (!imageUrl) return;

      // 선택 상태 업데이트
      this.selectedImageType = type;
      this.selectedImageIndex = index;
      this.selectedImageUrl = imageUrl;
      this.currentDetailImage = imageUrl;

      // 선택된 이미지를 detailHtml에 추가
      this.appendImageToDetailHtml(imageUrl);
    },

    // 처리된 이미지 선택
    selectProcessedImage(index, image) {
      console.log("처리된 이미지 선택:", index, image);

      // 이미지가 유효한지 확인
      if (!image || !image.url) {
        console.error("유효하지 않은 이미지 정보입니다.");
        return;
      }

      this.selectedImageType = 'processed';
      this.selectedImageIndex = index;
      this.selectedImageUrl = image.url;
      this.currentDetailImage = image.url;

      // 선택된 이미지를 detailHtml에 추가
      this.appendImageToDetailHtml(image.url);
    },
    appendImageToDetailHtml(imageUrl) {
      // 에디터가 있으면 이미지 추가
      if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
        const editor = this.$refs.imageEditor.editor;
        const html = editor.getHtml();
        const css = editor.getCss();

        // HTML과 CSS 결합
        const existingHtml = `
              <style>
              ${css}
              </style>
              ${html}`;

        // 이미지 삽입 위치를 결정 (여기서는 마지막에 추가)
        const imgHtml = `
      <div style="margin-bottom:${this.imageMargin}px; text-align:center;">
        <img src="${imageUrl}" alt="추가된 이미지" style="max-width:100%; height:auto; display:block; margin:0 auto;">
      </div>
    `;

        // 닫는 div 태그 앞에 이미지 삽입
        const containerClosingTag = '</div>';
        const insertIndex = existingHtml.lastIndexOf(containerClosingTag);

        if (insertIndex !== -1) {
          const newHtml = existingHtml.slice(0, insertIndex) + imgHtml + existingHtml.slice(insertIndex);
          this.$refs.imageEditor.editor.setComponents(newHtml);
          this.detailHtml = newHtml;
        } else {
          console.error('HTML 구조에 문제가 있습니다. 이미지를 추가할 수 없습니다.');
        }
      }
    },

    async processAllImages() {
      if (this.detailImages.length === 0 && this.mainImages.length === 0) {
        this.$bvToast.toast('처리할 이미지가 없습니다.', {
          title: '알림',
          variant: 'warning',
          autoHideDelay: 2000,
          toaster: 'b-toaster-bottom-right'
        });
        return;
      }

      try {
        // 로딩 시작
        this.setProcessing({
          isProcessing: true,
          message: '모든 상세 이미지 처리 중...'
        });
        this.isProcessing = true;

        // 상단/하단 이미지 정보 저장 (처리 후 다시 추가하기 위함)
        const settings = this.defaultSettings.imageSettings || {};
        const useTopImage = settings.useTopImage && settings.topImageUrl;
        const useBottomImage = settings.useBottomImage && settings.bottomImageUrl;
        const topImageHtml = useTopImage ?
          `<div style="margin-bottom:${this.imageMargin}px; text-align:center;">
        <img src="${settings.topImageUrl}" alt="상단 이미지" style="max-width:100%; height:auto; width:760px; display:block; margin:0 auto;">
      </div>` : '';
        const bottomImageHtml = useBottomImage ?
          `<div style="margin-bottom:${this.imageMargin}px; text-align:center;">
        <img src="${settings.bottomImageUrl}" alt="하단 이미지" style="max-width:100%; height:auto; width:760px; display:block; margin:0 auto;">
      </div>` : '';

        // 처리 서비스 호출 - 이미지 S3 업로드 없이 base64로 처리
        const result = await detailImageService.processDetailImages(this.product, {
          imageMargin: parseInt(this.imageMargin)
        });

        if (result && result.status === 'completed') {
          // 처리된 이미지 정보 저장 (base64 형태로)
          if (result.processedImages && result.processedImages.length > 0) {
            this.processedImages = result.processedImages.map((image, index) => ({
              url: image.url, // base64 데이터
              name: `상세이미지_${index + 1}`,
              dimensions: image.dimensions || '800x800',
              processType: 'processed',
              isBase64: true,
              originalUrl: image.originalUrl,
              textAreas: image.textAreas || []
            }));
          }

          let finalHtml = '';

          // 컨테이너 확인 및 추가
          if (result.detailHtml && !result.detailHtml.includes('max-width:800px')) {
            // 컨테이너 시작 태그
            finalHtml = '<div style="width:100%; max-width:800px; margin:0 auto; background-color:#ffffff; padding:20px;">';

            // 상단 이미지 추가 (있는 경우)
            if (useTopImage) {
              finalHtml += topImageHtml;
            }

            // 처리된 컨텐츠 추가
            finalHtml += result.detailHtml;

            // 하단 이미지 추가 (있는 경우)
            if (useBottomImage) {
              finalHtml += bottomImageHtml;
            }

            // 컨테이너 종료 태그
            finalHtml += '</div>';
          } else {
            // 이미 컨테이너가 있는 경우, 상단/하단 이미지를 적절한 위치에 삽입
            const parser = new DOMParser();
            const doc = parser.parseFromString(result.detailHtml, 'text/html');
            const container = doc.querySelector('div[style*="max-width:800px"]');

            if (container) {
              // 상단 이미지를 컨테이너의 첫 번째 자식으로 추가
              if (useTopImage) {
                const topDiv = parser.parseFromString(topImageHtml, 'text/html').body.firstChild;
                container.insertBefore(topDiv, container.firstChild);
              }

              // 하단 이미지를 컨테이너의 마지막 자식으로 추가
              if (useBottomImage) {
                const bottomDiv = parser.parseFromString(bottomImageHtml, 'text/html').body.firstChild;
                container.appendChild(bottomDiv);
              }

              finalHtml = doc.documentElement.innerHTML;
            } else {
              // 컨테이너를 찾을 수 없는 경우 초기 방식으로 HTML 생성
              finalHtml = '<div style="width:100%; max-width:800px; margin:0 auto; background-color:#ffffff; padding:20px;">';
              if (useTopImage) finalHtml += topImageHtml;
              finalHtml += result.detailHtml;
              if (useBottomImage) finalHtml += bottomImageHtml;
              finalHtml += '</div>';
            }
          }

          // 처리된 HTML 저장
          this.detailHtml = finalHtml;

          // 최근 수정일 업데이트
          this.lastModifiedDate = new Date();

          // 에디터에 업데이트
          if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
            this.$refs.imageEditor.editor.setComponents(this.detailHtml);
          }

          // 성공 메시지
          this.$bvToast.toast('상세 페이지가 생성되었습니다. 저장 버튼을 클릭하여 최종 저장하세요.', {
            title: '처리 완료',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-bottom-right'
          });

          this.$nextTick(() => {
            // HTML이 렌더링된 후 간격 재적용
            this.applyImageMargin();
          });

        } else {
          throw new Error('이미지 처리 실패');
        }
      } catch (error) {
        console.error('일괄 처리 오류:', error);
        this.$bvToast.toast(`이미지 처리 중 오류가 발생했습니다: ${error.message}`, {
          title: '오류',
          variant: 'danger',
          autoHideDelay: 4000,
          toaster: 'b-toaster-bottom-right'
        });
      } finally {
        // 로딩 종료
        this.setProcessing({ isProcessing: false });
        this.isProcessing = false;
      }
    },

    applyImageMargin() {
      if (!this.$refs.imageEditor || !this.$refs.imageEditor.editor) return;

      // 입력값 검증
      const margin = parseInt(this.imageMargin);
      if (isNaN(margin) || margin < 0) {
        this.imageMargin = 0;
        return;
      }

      const editor = this.$refs.imageEditor.editor;

      // 에디터의 모든 컴포넌트 가져오기
      const components = editor.DomComponents.getWrapper().find('*');

      // 이미지 컨테이너 컴포넌트 찾기
      components.forEach(component => {
        // div 요소이면서 안에 img가 있는 컴포넌트 찾기
        if (component.get('tagName') === 'div') {
          const children = component.components();
          const hasImage = children.some(child => child.get('tagName') === 'img');

          // 이미지를 포함하는 div인 경우
          if (hasImage) {
            // margin-bottom만 설정하고 다른 마진은 건드리지 않음
            const style = { ...component.getStyle() };
            style['margin-bottom'] = `${margin}px`;

            // 너비 관련 스타일 명시적 설정 (100%로 유지)
            style['width'] = '100%';
            style['max-width'] = '100%';
            style['box-sizing'] = 'border-box';

            component.setStyle(style);

            // 내부 이미지 요소 크기도 확인
            children.forEach(child => {
              if (child.get('tagName') === 'img') {
                const imgStyle = { ...child.getStyle() };
                imgStyle['max-width'] = '100%';
                child.setStyle(imgStyle);
              }
            });
          }
        }
      });

      // 변경 내용 알림
      this.$bvToast.toast(`이미지 간격이 ${margin}px로 설정되었습니다`, {
        title: '설정 변경',
        variant: 'success',
        solid: true,
        toaster: 'b-toaster-bottom-right',
        autoHideDelay: 1500
      });
    },

    // 에디터 초기화
    resetEditor() {
      // 편집기가 있는지 확인
      if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
        this.initializeDetailHtml();
        this.$refs.imageEditor.editor.setComponents(this.detailHtml);
      }
    },
    // 편집 모드로 전환
    switchToEditMode() {
      if (this.isPreviewMode) {
        // 현재 내용 저장
        // this.saveCurrentContent();
        this.isPreviewMode = false;

        // 에디터가 준비되면 내용 설정
        this.$nextTick(() => {
          if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
            const editor = this.$refs.imageEditor.editor;
            const html = editor.getHtml();
            const css = editor.getCss();

            // HTML과 CSS 결합
            this.detailHtml = `
              <style>
              ${css}
              </style>
              ${html}`;
            this.$refs.imageEditor.editor.setComponents(this.detailHtml);
          }
        });
      }
    },

    // 미리보기 모드로 전환
    switchToPreviewMode() {
      // 편집기 내 선택 요소 해제
      this.$refs.imageEditor.editor.select(null);
      if (!this.isPreviewMode) {
        // 에디터에서 HTML과 CSS 모두 가져오기
        if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
          const editor = this.$refs.imageEditor.editor;
          const html = editor.getHtml();
          const css = editor.getCss();

          // HTML과 CSS 결합
          this.detailHtml = `
<style>
${css}
</style>
${html}`;
        }
        this.isPreviewMode = true;
      }
    },

    // 에디터에서 저장 이벤트 처리
    handleEditorSave(htmlContent) {
      console.log('에디터 저장 이벤트 발생, HTML 길이:', htmlContent.length);
      // const editor = this.$refs.imageEditor.editor;
      // const html = editor.getHtml();
      // const css = editor.getCss();

      // HTML과 CSS 결합
      // this.detailHtml = `
      //   <style>
      //   ${css}
      //   </style>
      //   ${html}`;
      //   this.$refs.imageEditor.editor.setComponents(this.detailHtml);

      this.lastModifiedDate = new Date();
    },
    test() {
      const editor = this.$refs.imageEditor.editor;
      const html = editor.getHtml();
      const css = editor.getCss();

      this.detailHtml = `
        <style>
        ${css}
        </style>
        ${html}`;
      this.$refs.imageEditor.editor.setComponents(this.detailHtml);
    },
    async saveDetailPage() {
      try {
        // 처리 중 상태 설정
        this.setProcessing({
          isProcessing: true,
          message: '상세 페이지 저장 중...'
        });

        // 현재 편집기의 내용 가져오기 - 편집 모드에서는 iframe 내용 직접 가져오기
        if (!this.isPreviewMode && this.$refs.imageEditor && this.$refs.imageEditor.editor) {
          const editor = this.$refs.imageEditor.editor;
          const html = editor.getHtml();
          const css = editor.getCss();

          // HTML과 CSS 결합
          this.detailHtml = `
    <style>
    ${css}
    </style>
    ${html}`;
        }

        // 이미지가 없으면 경고
        const base64Images = this.extractImagesFromHtml(this.detailHtml);
        if (base64Images.length === 0) {
          this.$bvToast.toast('상세 페이지에 이미지가 없습니다', {
            title: '경고',
            variant: 'warning',
            autoHideDelay: 3000,
            toaster: 'b-toaster-bottom-right'
          });

          this.setProcessing({ isProcessing: false });
          return;
        }

        // 미리보기 모드 상태 저장
        const wasPreviewMode = this.isPreviewMode;

        // 썸네일 생성이 필요한 경우만 미리보기로 전환
        // 미리보기 상태가 아니면 먼저 전환
        if (!wasPreviewMode) {
          // 실제 switchToPreviewMode 메서드 호출
          this.switchToPreviewMode();

          // 미리보기 렌더링을 위한 시간 확보
          await new Promise(resolve => setTimeout(resolve, 800));
        }

        // 처리된 이미지 배열 생성 (processedImages가 있으면 사용, 없으면 현재 HTML에서 추출)
        let processedImages = this.processedImages;
        const supplierCode = this.$store.state.user.suppliercode || this.$store.state.user.companyid;

        // processedImages가 비어있거나 없는 경우, 현재 HTML에서 추출
        if (!processedImages || processedImages.length === 0) {
          // 현재 HTML에서 base64 이미지 추출하여 가상의 processedImages 생성
          processedImages = base64Images.map((base64Url) => ({
            url: base64Url,
            name: `${supplierCode}_${this.product.registrationId}_detail.jpg`, // 파일명 형식 통일
            isBase64: base64Url.startsWith('data:'),
            dimensions: '800x800', // 기본값
            processType: 'processed'
          }));
        }

        // 1. base64 이미지를 S3에 업로드하여 URL 얻기
        if (processedImages.some(img => img.isBase64)) {
          this.setProcessing({
            isProcessing: true,
            message: '이미지 업로드 중...'
          });

          // S3 업로드 처리
          processedImages = await detailImageService.uploadProcessedImagesToS3(processedImages);

          // 2. HTML에서 base64를 S3 URL로 교체
          const imageMap = {};
          processedImages.forEach(img => {
            if (!img.isBase64 && img.url) {
              // base64 데이터의 앞부분만 추출 (너무 길어서 전체를 비교하기 어려움)
              const base64Prefix = img.originalBase64 ?
                img.originalBase64.substring(0, 100) :
                (img.url.startsWith('data:') ? img.url.substring(0, 100) : '');

              if (base64Prefix) {
                imageMap[base64Prefix] = img.url;
              }
            }
          });

          // HTML 내의 base64 이미지 URL을 S3 URL로 교체
          this.detailHtml = detailImageService.replaceBase64WithS3Urls(this.detailHtml, imageMap);
        }

        // 3. HTML을 이미지로 변환 (추가된 부분)
        this.setProcessing({
          isProcessing: true,
          message: 'HTML을 이미지로 변환 중...'
        });

        // HTML 전체를 이미지로 변환하여 S3에 업로드
        const fullHtmlImageUrl = await detailImageService.convertHtmlToImage(this.detailHtml, this.product.registrationId);

        // 썸네일 기능은 제거 - fullHtmlImageUrl이 이미 대표 이미지 역할을 하기 때문

        // 필요하면 원래 모드로 복구
        if (!wasPreviewMode) {
          this.switchToEditMode();
        }

        // 새 상세 이미지 URL 목록 생성 - 기존 목록 대체
        const detailImageUrls = [];

        // 변환된 전체 HTML 이미지를 리스트에 추가 (맨 앞에)
        if (fullHtmlImageUrl) {
          detailImageUrls.push(fullHtmlImageUrl);
        }

        // 처리된 개별 이미지들 추가
        const processedUrls = processedImages
          .filter(img => !img.isBase64)
          .map(img => img.url);

        detailImageUrls.push(...processedUrls);

        // 상품 객체 업데이트
        const updatedProduct = {
          ...this.product,
          detailHtml: this.detailHtml,
          detailImages: detailImageUrls,
          detailImageStatus: 'completed',
          lastModified: new Date()
        };

        // 4. coupangDoc 업데이트 (추가된 부분)
        if (updatedProduct.coupangDoc && updatedProduct.coupangDoc.jsonDocument &&
          updatedProduct.coupangDoc.jsonDocument.length > 0) {
          const docObj = updatedProduct.coupangDoc.jsonDocument[0];
          if (docObj.imagePage && docObj.imagePage.details) {
            // HTML을 이미지로 변환한 결과를 detailedImage에 설정
            if (fullHtmlImageUrl) {
              docObj.imagePage.details.detailedImage = fullHtmlImageUrl;
              // HTML 콘텐츠는 비워두기
              docObj.imagePage.details.htmlProductDetailContent = "";
            }
            // 변환된 이미지가 없는 경우에만 첫 번째 상세 이미지 사용
            else if (detailImageUrls && detailImageUrls.length > 0) {
              docObj.imagePage.details.detailedImage = detailImageUrls[0];
              docObj.imagePage.details.htmlProductDetailContent = "";
            }

            // altText 설정 (상품 설명용 대체 텍스트)
            if (!docObj.imagePage.details.altText) {
              docObj.imagePage.details.altText = updatedProduct.seo && updatedProduct.seo.metaDescription
                ? updatedProduct.seo.metaDescription
                : `${updatedProduct.productName} 상세 설명`;
            }
          }
        }

        // 옵션이 있는 경우 각 옵션의 coupangDoc도 업데이트
        if (updatedProduct.options && updatedProduct.options.length > 0) {
          updatedProduct.options.forEach(option => {
            if (option.coupangDoc && option.coupangDoc.jsonDocument &&
              option.coupangDoc.jsonDocument.length > 0) {
              const optionDocObj = option.coupangDoc.jsonDocument[0];
              if (optionDocObj.imagePage && optionDocObj.imagePage.details) {
                // HTML을 이미지로 변환한 결과를 detailedImage에 설정
                if (fullHtmlImageUrl) {
                  optionDocObj.imagePage.details.detailedImage = fullHtmlImageUrl;
                  optionDocObj.imagePage.details.htmlProductDetailContent = "";
                }
                // 변환된 이미지가 없는 경우에만 첫 번째 상세 이미지 사용
                else if (detailImageUrls && detailImageUrls.length > 0) {
                  optionDocObj.imagePage.details.detailedImage = detailImageUrls[0];
                  optionDocObj.imagePage.details.htmlProductDetailContent = "";
                }

                // altText 설정 (상품 설명용 대체 텍스트)
                let altText = updatedProduct.seo && updatedProduct.seo.metaDescription
                  ? updatedProduct.seo.metaDescription
                  : `${updatedProduct.productName} 상세 설명`;

                // 옵션명이 있으면 altText에 추가
                if (option.korName) {
                  altText = `${altText} - ${option.korName}`;
                }

                optionDocObj.imagePage.details.altText = altText;
              }
            }
          });
        }

        // Vuex 액션으로 상품 업데이트
        await this.updateRocketProduct(updatedProduct);

        // 성공 메시지
        this.$bvToast.toast('상세 페이지가 저장되었습니다', {
          title: '저장 완료',
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-bottom-right'
        });
      } catch (error) {
        console.error('상세 페이지 저장 중 오류:', error);
        this.$bvToast.toast(`상세 페이지 저장 중 오류: ${error.message}`, {
          title: '오류',
          variant: 'danger',
          autoHideDelay: 4000,
          toaster: 'b-toaster-bottom-right'
        });
      } finally {
        // 로딩 상태 해제
        this.setProcessing({ isProcessing: false });
      }
    },

    // 다음 단계로 이동
    goToNextStep() {
      this.$emit('next');
    },

    // 이미지 업로드 관련 메서드
    showImageUpload() {
      this.$refs.imageUploadInput.click();
    },

    // 이미지 업로드 처리
    async handleImageUpload(event) {
      const files = event.target.files;
      if (!files || files.length === 0) return;

      try {
        // 로딩 시작
        this.setProcessing({
          isProcessing: true,
          message: '이미지 업로드 중...'
        });
        this.isProcessing = true;

        // 각 파일 처리
        const uploadPromises = Array.from(files).map(file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = e => {
              resolve({
                data: e.target.result,
                name: file.name
              });
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
        });

        const uploadedImages = await Promise.all(uploadPromises);

        // 이미지 추가
        for (const img of uploadedImages) {
          const imgElement = `
            <div class="detail-image-wrapper" style="margin-bottom:20px; text-align:center;">
              <img src="${img.data}" alt="${img.name}" class="detail-image" style="max-width:100%; height:auto; display:block; margin:0 auto;">
            </div>
          `;

          if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
            const editor = this.$refs.imageEditor.editor;
            const html = editor.getHtml();
            const css = editor.getCss();

            // HTML과 CSS 결합
            const existingHtml = `
              <style>
              ${css}
              </style>
              ${html}`;
            const containerClosingTag = '</div>';
            const insertIndex = existingHtml.lastIndexOf(containerClosingTag);

            if (insertIndex !== -1) {
              const newHtml = existingHtml.slice(0, insertIndex) + imgElement + existingHtml.slice(insertIndex);
              this.$refs.imageEditor.editor.setComponents(newHtml);
              this.detailHtml = newHtml;
              this.lastModifiedDate = new Date();
            }
          }
        }

        // 성공 메시지
        this.$bvToast.toast(`${uploadedImages.length}개 이미지가 업로드되었습니다`, {
          title: '업로드 완료',
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-bottom-right'
        });
      } catch (error) {
        console.error('이미지 업로드 중 오류:', error);
        this.$bvToast.toast(`이미지 업로드 중 오류가 발생했습니다: ${error.message}`, {
          title: '오류',
          variant: 'danger',
          autoHideDelay: 4000,
          toaster: 'b-toaster-bottom-right'
        });
      } finally {
        // 로딩 종료
        this.setProcessing({ isProcessing: false });
        this.isProcessing = false;

        // input 초기화
        event.target.value = '';

      }
    },

    // 에디터 오류 처리
    handleEditorError() {
      console.error("이미지 편집기 로드 오류");
      this.$bvToast.toast('이미지 편집기 로드 중 오류가 발생했습니다', {
        title: '오류',
        variant: 'danger',
        autoHideDelay: 3000,
        toaster: 'b-toaster-bottom-right'
      });
    },

    // 키보드 이벤트 처리
    handleKeyDown(e) {
      // ESC 키를 눌렀을 때 미리보기 모드 종료
      if (e.key === 'Escape' && this.isPreviewMode) {
        this.isPreviewMode = false;
      }
    },
    /**
 * HTML에서 base64 이미지 추출
 * @param {string} html - HTML 문자열 
 * @returns {Array} - 추출된 base64 이미지 URL 배열
 */
    extractImagesFromHtml(html) {
      const imgRegex = /<img[^>]+src="([^">]+)"/g;
      const urls = [];
      let match;

      while ((match = imgRegex.exec(html)) !== null) {
        if (match[1] && match[1].length > 10) {
          urls.push(match[1]);
        }
      }

      return urls;
    },

    /**
     * base64 이미지 URL에서 이미지 크기 추출 시도
     * @param {string} base64Url - base64 이미지 URL
     * @returns {Promise<{width: number, height: number}>} - 이미지 크기
     */
    async getBase64ImageDimensions(base64Url) {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          resolve({
            width: img.naturalWidth,
            height: img.naturalHeight
          });
        };
        img.onerror = () => {
          // 오류 시 기본 크기 반환
          resolve({
            width: 800,
            height: 600
          });
        };
        img.src = base64Url;
      });
    },

    /**
     * HTML에서 base64 URL을 S3 URL로 교체
     * @param {string} html - HTML 문자열
     * @param {Object} urlMap - base64 URL -> S3 URL 매핑 객체
     * @returns {string} - 교체된 HTML
     */
    replaceBase64UrlsWithS3Urls(html, urlMap) {
      let updatedHtml = html;

      Object.keys(urlMap).forEach(base64Url => {
        // 안전한 문자열 교체를 위해 정규식 이스케이프
        const escapedUrl = base64Url.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(escapedUrl, 'g');
        updatedHtml = updatedHtml.replace(regex, urlMap[base64Url]);
      });

      return updatedHtml;
    }
  }
};
</script>

<style scoped>
.detail-image-edit {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* 전체 컨테이너는 overflow 숨김 */
}

/* 로딩 컨테이너 */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

/* 에디터 전체 화면 레이아웃 */
.editor-fullscreen-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* 레이아웃 컨테이너도 overflow 숨김 */
}

.margin-control {
  display: inline-flex;
  align-items: center;
}

/* 인풋 그룹 사이즈 조정 */
.margin-control .input-group {
  width: auto;
}

/* 너비 제한 */
.margin-control input[type="number"] {
  width: 60px !important;
  min-width: 60px !important;
}

/* 상단 헤더 영역 */
.editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  z-index: 10;
  flex-shrink: 0;
}

.editor-title {
  flex: 0 0 200px;
}

.editor-title h5 {
  margin: 0;
  font-weight: 600;
}

.mode-toggle {
  margin-right: auto;
}

/* 반응형 조정 */
@media (max-width: 992px) {
  .editor-header {
    flex-wrap: wrap;
  }

  .editor-title {
    flex: 1 0 100%;
    margin-bottom: 10px;
  }

  .mode-toggle,
  .editor-actions {
    flex: 1 1 auto;
  }
}

@media (max-width: 768px) {
  .editor-actions {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 10px;
  }

  .margin-control {
    margin-bottom: 10px;
    width: 100%;
  }
}

.editor-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

/* 에디터 메인 영역 - 스크롤 처리 */
.editor-main-area {
  flex: 1;
  position: relative;
  overflow-y: auto;
  /* hidden에서 auto로 변경: 세로 스크롤 허용 */
  display: flex;
  justify-content: center;
}

/* 에디터 컨테이너 - 동적 높이 적용 */
.editor-container {
  width: 100%;
  max-width: 100% !important;
  min-height: 600px;
  height: auto !important;
  /* 자동 높이 설정 추가 */
  display: flex;
  justify-content: center;
  overflow: visible;
  /* 오버플로우 허용 */
}

/* ImageEditor 컴포넌트 스타일 */
.editor-container>>>.image-editor,
.editor-container ::v-deep .image-editor {
  width: 100%;
  max-width: 900px !important;
  /* 에디터 최대 너비 제한 */
  margin: 0 auto;
}

/* iframe에 대한 추가 스타일 */
.editor-container>>>iframe,
.editor-container ::v-deep iframe {
  min-height: 600px;
  border: none !important;
}


/* 미리보기 모드 */
.preview-container {
  flex: 1;
  overflow-y: auto;
  /* 미리보기 모드에서 스크롤 허용 */
  background-color: #f5f5f5;
}

.preview-scroll-container {
  /* padding: 20px; */
  display: flex;
  justify-content: center;
}

.detail-content-preview {
  width: 100%;
  max-width: 800px;
  background-color: white;
  /* padding: 20px; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* 로딩 오버레이 */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.loading-content {
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
}

.loading-content p {
  margin-top: 15px;
  color: #333;
}

/* 에디터 로딩 상태 */
.editor-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  background-color: #f9f9f9;
  color: #666;
  font-size: 16px;
}
</style>