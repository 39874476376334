<template>
  <div class="main-image-edit">
    <!-- 로딩 중일 때 표시 -->
    <div v-if="loading" class="loading-container">
      <b-spinner variant="primary"></b-spinner>
      <p>{{ progressMessage || '대표 이미지 작업 중...' }}</p>
    </div>

    <div v-if="isPreviewMode" class="preview-overlay">
      <div class="preview-container">
        <img :src="currentImageUrl" alt="대표 이미지" class="preview-image" v-if="currentImageUrl">
        <div class="no-image-placeholder" v-else>
          <i class="las la-image"></i>
          <p>선택된 대표 이미지가 없습니다</p>
        </div>
      </div>
      <!-- 닫기 버튼 -->
      <button class="close-preview-btn" @click="isPreviewMode = false">
        <i class="las la-times"></i>
      </button>
    </div>
    <!-- 이미지 편집 화면 -->
    <div v-else-if="!isPreviewMode" class="image-editor-layout">
      <!-- 좌측: 이미지 선택 패널 -->
      <div class="image-selector-panel">
        <div class="panel-header">
          <h5 class="panel-title">이미지 선택</h5>
          <b-button variant="outline-primary" size="sm" @click="showImageUpload" title="이미지 업로드">
            <i class="las la-upload"></i>
          </b-button>
        </div>

        <!-- 이미지 탭 -->
        <div class="image-tabs">
          <b-nav tabs>
            <b-nav-item :active="activeTab === 'processed'" @click="activeTab = 'processed'">작업이미지</b-nav-item>
            <b-nav-item :active="activeTab === 'main'" @click="activeTab = 'main'">메인이미지</b-nav-item>
            <b-nav-item :active="activeTab === 'option'" @click="activeTab = 'option'">옵션이미지</b-nav-item>
            <b-nav-item :active="activeTab === 'detail'" @click="activeTab = 'detail'">상세이미지</b-nav-item>
          </b-nav>
        </div>

        <!-- 이미지 목록 -->
        <div class="image-gallery">
          <!-- 작업 완료된 이미지 탭 -->
          <div v-if="activeTab === 'processed'" class="image-grid">
            <div v-if="processedImages.length > 0">
              <div v-for="(image, index) in processedImages" :key="`processed-${index}`" class="image-item"
                :class="{ selected: selectedImageType === 'processed' && selectedImageIndex === index }"
                @click="selectProcessedImage(index, image)">
                <img :src="image.url" alt="작업된 이미지" class="img-fluid">
                <div class="image-overlay">
                  <i class="las la-check"></i>
                </div>
                <div class="image-label">
                  <span class="text-removed-badge" v-if="image.textRemoved">텍스트 제거됨</span>
                  <span v-else>작업됨</span>
                </div>
              </div>
            </div>
            <div v-else class="empty-gallery">
              <i class="las la-images"></i>
              <p>글자 삭제 작업이 완료된 이미지가 없습니다</p>
              <p class="small text-muted">이미지를 선택한 후 '글자 삭제' 버튼을 눌러 작업을 진행해보세요</p>
            </div>
          </div>

          <!-- 메인 이미지 탭 -->
          <div v-if="activeTab === 'main'" class="image-grid">
            <div v-if="mainImages.length > 0">
              <div v-for="(image, index) in mainImages" :key="`main-${index}`" class="image-item"
                :class="{ selected: selectedImageType === 'main' && selectedImageIndex === index }"
                @click="selectImage('main', index, image)">
                <img :src="image" alt="메인 이미지" class="img-fluid">
                <div class="image-overlay">
                  <i class="las la-check"></i>
                </div>
                <div class="image-label">메인</div>
              </div>
            </div>
            <div v-else class="empty-gallery">
              <i class="las la-images"></i>
              <p>메인 이미지가 없습니다</p>
              <b-button variant="outline-primary" size="sm" @click="fetchImagesFrom1688">
                이미지 불러오기
              </b-button>
            </div>
          </div>

          <!-- 옵션 이미지 탭 -->
          <div v-if="activeTab === 'option'" class="image-grid">
            <div v-if="optionImages.length > 0">
              <div v-for="(image, index) in optionImages" :key="`option-${index}`" class="image-item"
                :class="{ selected: selectedImageType === 'option' && selectedImageIndex === index }"
                @click="selectImage('option', index, image.url)">
                <img :src="image.url" alt="옵션 이미지" class="img-fluid">
                <div class="image-overlay">
                  <i class="las la-check"></i>
                </div>
                <div class="image-label">{{ image.label || '옵션' }}</div>
              </div>
            </div>
            <div v-else class="empty-gallery">
              <i class="las la-images"></i>
              <p>옵션 이미지가 없습니다</p>
            </div>
          </div>

          <!-- 상세 이미지 탭 -->
          <div v-if="activeTab === 'detail'" class="image-grid">
            <div v-if="detailImages.length > 0">
              <div v-for="(image, index) in detailImages" :key="`detail-${index}`" class="image-item"
                :class="{ selected: selectedImageType === 'detail' && selectedImageIndex === index }"
                @click="selectImage('detail', index, image)">
                <img :src="image" alt="상세 이미지" class="img-fluid">
                <div class="image-overlay">
                  <i class="las la-check"></i>
                </div>
                <div class="image-label">상세</div>
              </div>
            </div>
            <div v-else class="empty-gallery">
              <i class="las la-images"></i>
              <p>상세 이미지가 없습니다</p>
            </div>
          </div>
        </div>

        <!-- 이미지 편집 옵션 -->
        <div class="image-options">
          <div class="text-remove-option">
            <b-button variant="danger" size="sm" class="w-100 mb-3" @click="removeTextFromImage">
              <i class="las la-eraser"></i> 글자 삭제
            </b-button>
          </div>
          <h6 class="options-title">이미지 크기</h6>
          <div class="dimension-controls">
            <b-input-group size="sm">
              <b-form-input v-model.number="resizeWidth" type="number" min="100" max="2000"
                class="text-center"></b-form-input>
              <b-input-group-text>×</b-input-group-text>
              <b-form-input v-model.number="resizeHeight" type="number" min="100" max="2000"
                class="text-center"></b-form-input>
              <b-input-group-append>
                <b-button variant="outline-primary" @click="applyResize">적용</b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </div>

      <!-- 우측: 이미지 편집기 -->
      <div class="editor-main-panel">
        <div class="editor-header">
          <div class="mode-toggle">
            <b-button-group>
              <b-button :variant="isPreviewMode ? 'outline-primary' : 'primary'" @click="isPreviewMode = false">편집
                모드</b-button>
              <b-button :variant="isPreviewMode ? 'primary' : 'outline-primary'" @click="isPreviewMode = true">미리보기
                모드</b-button>
            </b-button-group>
          </div>
          <div class="editor-actions">
            <b-button variant="outline-secondary" size="sm" @click="resetEditor" title="초기화" v-if="!isPreviewMode">
              <i class="las la-undo"></i>
            </b-button>
            <b-button variant="outline-success" size="sm" @click="applyEditorChanges" title="적용" v-if="!isPreviewMode">
              <i class="las la-save"></i> 저장
            </b-button>
          </div>
        </div>

        <!-- 이미지 표시 영역 -->
        <div class="editor-wrapper">
          <div class="image-container">
            <ImageEditor ref="imageEditor" :html="getImageHtml()" :hideBlockManager="true" @save="handleEditorSave"
              @error="handleEditorError" />
          </div>

          <!-- 로딩 오버레이 -->
          <div v-if="isRemovingText" class="loading-overlay">
            <div class="loading-content">
              <b-spinner variant="primary" label="글자 삭제 중..."></b-spinner>
              <p>AI가 이미지에서 글자를 제거 중입니다...</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 이미지 업로드를 위한 숨겨진 input 추가 -->
    <input ref="imageUploadInput" type="file" accept="image/*" class="d-none" @change="handleImageUpload">
  </div>
</template>

<script>
import ImageEditor from './ImageEditor.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
// import imageService from '../../services/imageService'
import mainImageService from '../../services/works/mainImageService'
import html2canvas from 'html2canvas'

export default {
  name: "MainImageEdit",
  components: {
    ImageEditor
  },
  data() {
    return {
      activeTab: 'main',
      selectedImageType: '',
      selectedImageIndex: -1,
      selectedImageUrl: null,
      currentMainImage: null,
      resizeWidth: 800,
      resizeHeight: 800,
      isRemovingText: false,
      editedImageUrl: null,
      imageHistory: {
        original: null,
        processed: null
      },
      processedImages: [],
      optionImages: [],
      isPreviewMode: false, // 미리보기 모드 상태 관리
    };
  },
  computed: {
    // Vuex 상태 매핑
    ...mapState({
      product: state => state.selectedRocketProduct,
      selectedOption: state => state.selectedOption,
      selectedWorkType: state => state.selectedWorkType,
      processing: state => state.processing,
      progressMessage: state => state.progressMessage
    }),

    // Vuex 게터 매핑
    ...mapGetters([
      'getCurrentOptionImageData',
    ]),

    // 로딩 상태
    loading() {
      return this.processing;
    },

    // 메인 이미지 목록
    mainImages() {
      return this.product?.mainImages || [];
    },

    // 상세 이미지 목록
    detailImages() {
      return this.product?.detailImages || [];
    },

    // 현재 작업 중인 이미지 URL
    currentImageUrl() {
      return this.currentMainImage ||
        (this.selectedOption && this.selectedOption.mainImageStatus && this.selectedOption.mainImageStatus.processedImage) ||
        (this.selectedOption && this.selectedOption.mainImageStatus && this.selectedOption.mainImageStatus.image) ||
        this.selectedImageUrl || '';
    }
  },
  watch: {
    selectedOption: {
      handler() {
        // 선택된 옵션이 변경되면 컴포넌트 다시 초기화
        this.initializeComponent();
      },
      deep: true
    }
  },
  mounted() {
    console.log('MainImageEdit 마운트됨, 선택된 옵션:', this.selectedOption);
    if (this.selectedOption && this.selectedOption.mainImageStatus) {
      console.log('메인 이미지 상태:', this.selectedOption.mainImageStatus);
      console.log('처리된 이미지 URL:', this.selectedOption.mainImageStatus.processedImage);
    }
    // 컴포넌트 마운트 시 초기화
    this.initializeComponent();

    // 키보드 이벤트 리스너 추가
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    // 컴포넌트 제거 시 이벤트 리스너 제거
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    // Vuex 액션 매핑
    ...mapActions([
      'updateOptionImage',
      'processOptionImage'
    ]),

    async initializeComponent() {
      // 기본 상태 초기화
      this.activeTab = 'main';
      this.selectedImageType = '';
      this.selectedImageIndex = -1;

      // 옵션 이미지 로드
      this.loadOptionImages();

      // 이미지 데이터 초기화 호출
      this.initializeMainImageData();

      // 선택된 옵션과 이미지 데이터 확인
      console.log('초기화 시 선택된 옵션:', this.selectedOption);
      if (this.selectedOption && this.selectedOption.mainImageStatus) {
        console.log('초기화 시 이미지 상태:', this.selectedOption.mainImageStatus);
        console.log('처리된 이미지 URL:', this.selectedOption.mainImageStatus.processedImage);
      }
    },
    // MainImageEdit.vue - initializeMainImageData 메서드 수정
    initializeMainImageData() {
      console.log("메인 이미지 데이터 초기화 시작");
      console.log("선택된 제품:", this.product);
      console.log("선택된 옵션:", this.selectedOption);

      // 선택된 옵션의 이미지 정보로 컴포넌트 상태 초기화
      if (this.selectedOption && this.selectedOption.mainImageStatus) {
        console.log("옵션 이미지 상태:", this.selectedOption.mainImageStatus);

        // 처리된 이미지가 있으면 사용
        if (this.selectedOption.mainImageStatus.processedImage) {
          this.currentMainImage = this.selectedOption.mainImageStatus.processedImage;
          this.imageHistory.processed = this.selectedOption.mainImageStatus.processedImage;

          console.log("처리된 이미지 URL:", this.currentMainImage);

          // 처리된 이미지 목록에 추가
          if (!this.processedImages.some(img => img.url === this.selectedOption.mainImageStatus.processedImage)) {
            this.processedImages.push({
              url: this.selectedOption.mainImageStatus.processedImage,
              type: 'main',
              originalUrl: this.selectedOption.mainImageStatus.image,
              textRemoved: this.selectedOption.mainImageStatus.textRemoved || false
            });
          }
        } else {
          console.log("처리된 이미지가 없습니다. 원본 이미지 사용");
          // 원본 이미지 사용
          if (this.selectedOption.mainImageStatus.image) {
            this.currentMainImage = this.selectedOption.mainImageStatus.image;
          }
        }

        // 원본 이미지 저장
        if (this.selectedOption.mainImageStatus.image) {
          this.imageHistory.original = this.selectedOption.mainImageStatus.image;
        }

        // 이미지 크기 정보 설정
        if (this.selectedOption.mainImageStatus.dimensions) {
          const dimensions = this.selectedOption.mainImageStatus.dimensions.split('x');
          if (dimensions.length === 2) {
            this.resizeWidth = parseInt(dimensions[0].trim());
            this.resizeHeight = parseInt(dimensions[1].trim());
          }
        }

        // 에디터에 이미지 로드
        this.$nextTick(() => {
          if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
            const html = this.getImageHtmlFromUrl(this.currentMainImage);
            this.$refs.imageEditor.editor.setComponents(html);
          }
        });

        console.log("메인 이미지 데이터 초기화 완료:", this.currentMainImage);
      } else {
        console.log("초기화할 옵션 이미지 정보가 없습니다");
      }
    },

    // 이미지 HTML 생성
    getImageHtml() {
      // 이미지 URL 결정 로직 단순화: 현재 작업 중인 이미지 우선
      const imageUrl = this.currentImageUrl;

      if (!imageUrl) {
        return '<p>이미지를 선택해주세요</p>';
      }

      return this.getImageHtmlFromUrl(imageUrl);
    },

    // 이미지 URL로부터 HTML 생성
    getImageHtmlFromUrl(imageUrl) {
      return `
        <div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; overflow: hidden;">
          <img src="${imageUrl}" alt="상품 이미지" style="max-width: 100%; max-height: 100%; object-fit: contain;" />
        </div>
      `;
    },

    // 이미지 선택 처리
    async selectImage(type, index, imageUrl) {
      console.log(`========== ${type} 이미지 선택 ==========`);
      console.log("선택된 인덱스:", index);
      console.log("선택된 이미지 URL:", imageUrl);

      if (!imageUrl) return;

      // 선택 상태 업데이트
      this.selectedImageType = type;
      this.selectedImageIndex = index;
      this.selectedImageUrl = imageUrl;
      this.currentMainImage = imageUrl;
      this.imageHistory.original = imageUrl;

      // 에디터 업데이트
      if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
        const newHtml = this.getImageHtmlFromUrl(imageUrl);
        this.$refs.imageEditor.editor.setComponents(newHtml);
      }

      // 이미지 데이터 구성 (S3 업로드 없이)
      const imageData = {
        originalUrl: imageUrl,
        processedUrl: imageUrl,
        dimensions: `${this.resizeWidth}x${this.resizeHeight}`,
        textRemoved: false
      };

      // Vuex 액션 호출
      if (this.selectedOption) {
        await this.updateOptionImage({
          optionNumber: this.selectedOption.optionNumber,
          imageData
        });
      }
    },

    // 처리된 이미지 선택
    selectProcessedImage(index, image) {
      console.log("========== 작업된 이미지 선택 ==========");
      console.log("선택된 인덱스:", index);
      console.log("선택된 이미지:", image);

      // 이미지가 유효한지 확인
      if (!image || !image.url) {
        console.error("유효하지 않은 이미지 정보입니다.");
        return;
      }

      this.selectedImageType = 'processed';
      this.selectedImageIndex = index;
      this.selectedImageUrl = image.url;
      this.currentMainImage = image.url;

      // 에디터에 이미지 로드
      if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
        const html = this.getImageHtmlFromUrl(image.url);
        this.$refs.imageEditor.editor.setComponents(html);
      }

      // 이미지 정보 업데이트 - Vuex 액션 호출
      this.updateOptionImage({
        optionNumber: this.selectedOption.optionNumber,
        imageData: {
          originalUrl: image.originalUrl || image.url,
          processedUrl: image.url,
          dimensions: `${this.resizeWidth}x${this.resizeHeight}`,
          filesize: this.estimateFileSize(image.url) || '100KB',
          textRemoved: image.textRemoved || false
        }
      }).then(() => {
        this.$bvToast.toast('이미지가 적용되었습니다.', {
          title: '성공',
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-bottom-right'
        });
      }).catch(error => {
        console.error("이미지 저장 중 오류 발생:", error);
        this.$bvToast.toast('이미지 저장 중 오류가 발생했습니다', {
          title: '오류',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-right'
        });
      });
    },

    // 텍스트 제거 처리
    async removeTextFromImage() {
      console.log("========== 글자 삭제 시작 ==========");

      // 현재 이미지가 없으면 중단
      if (!this.currentMainImage) {
        this.$bvToast.toast('이미지를 먼저 선택해주세요', {
          title: '알림',
          variant: 'warning',
          autoHideDelay: 2000,
          toaster: 'b-toaster-bottom-right'
        });
        return;
      }

      // 로딩 시작
      this.isRemovingText = true;

      try {
        // Vuex 액션으로 텍스트 제거 처리 요청
        const optionDummy = {
          optionNumber: this.selectedOption.optionNumber,
          mainImageStatus: {
            image: this.currentMainImage
          }
        };
        const result = await mainImageService.processOption(optionDummy);

        if (result && result.processedUrl) {
          // 현재 이미지 업데이트
          this.currentMainImage = result.processedUrl;

          // 에디터 업데이트
          if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
            const newHtml = this.getImageHtmlFromUrl(result.processedUrl);
            this.$refs.imageEditor.editor.setComponents(newHtml);
          }

          // 처리된 이미지 목록에 추가
          if (!this.processedImages.some(img => img.url === result.processedUrl)) {
            this.processedImages.push({
              url: result.processedUrl,
              type: 'main',
              originalUrl: result.originalUrl || this.imageHistory.original,
              textRemoved: result.textRemoved || false
            });
          }

          // 작업 완료 후 processed 탭으로 변경
          this.activeTab = 'processed';

          // 처리 결과 메시지
          this.$bvToast.toast(
            result.textRemoved ? '이미지에서 텍스트가 제거되었습니다.' : '이미지에 텍스트가 없거나 제거할 수 없습니다.',
            {
              title: result.textRemoved ? '처리 완료' : '알림',
              variant: result.textRemoved ? 'success' : 'info',
              solid: true,
              toaster: 'b-toaster-bottom-right'
            }
          );
        } else {
          throw new Error('이미지 처리 결과가 없습니다.');
        }

        console.log("========== 글자 삭제 완료 ==========");
      } catch (error) {
        console.error('글자 삭제 오류:', error);
        this.$bvToast.toast(`글자 삭제 중 오류가 발생했습니다: ${error.message}`, {
          title: '오류',
          variant: 'danger',
          autoHideDelay: 4000,
          toaster: 'b-toaster-bottom-right'
        });
      } finally {
        // 로딩 종료
        this.isRemovingText = false;
      }
    },

    // 이미지 크기 변경 적용
    applyResize() {
      // 현재 메인 이미지 가져오기
      const imageToResize = this.currentMainImage;
      if (!imageToResize) return;

      const img = new Image();
      img.crossOrigin = 'Anonymous';

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = this.resizeWidth;
        canvas.height = this.resizeHeight;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, this.resizeWidth, this.resizeHeight);

        const resizedImageUrl = canvas.toDataURL('image/jpeg', 0.9);

        // 현재 메인 이미지 업데이트
        this.currentMainImage = resizedImageUrl;

        // 에디터 업데이트
        if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
          const newHtml = this.getImageHtmlFromUrl(resizedImageUrl);
          this.$refs.imageEditor.editor.setComponents(newHtml);
        }

        // Vuex 액션 호출하여 이미지 업데이트
        if (this.selectedOption) {
          this.updateOptionImage({
            optionNumber: this.selectedOption.optionNumber,
            imageData: {
              originalUrl: this.imageHistory.original || resizedImageUrl,
              processedUrl: resizedImageUrl,
              dimensions: `${this.resizeWidth}x${this.resizeHeight}`,
              filesize: this.estimateFileSize(resizedImageUrl)
            }
          });
        }
      };

      img.src = imageToResize;
    },

    // 옵션 이미지 로드
    loadOptionImages() {
      this.optionImages = [];
      this.processedImages = []; // 작업된 이미지 목록 초기화

      // 중복 체크를 위한 URL 세트
      const processedImageUrls = new Set();
      const optionImageUrls = new Set();

      // 상품에 옵션이 있는 경우
      if (this.product && this.product.options && this.product.options.length > 0) {
        this.product.options.forEach((option, index) => {
          // 유효성 검사 추가
          if (!option) return;

          // 옵션1 이미지
          if (option.option1 && option.option1.imageUrl) {
            const imageUrl = option.option1.imageUrl;
            if (!optionImageUrls.has(imageUrl)) {
              optionImageUrls.add(imageUrl);
              this.optionImages.push({
                url: imageUrl,
                label: `옵션1-${option.option1.nameTrans || option.option1.name}`,
                optionNumber: option.optionNumber,
                optionIndex: index
              });
            }
          }

          // 옵션2 이미지
          if (option.option2 && option.option2.imageUrl) {
            const imageUrl = option.option2.imageUrl;
            if (!optionImageUrls.has(imageUrl)) {
              optionImageUrls.add(imageUrl);
              this.optionImages.push({
                url: imageUrl,
                label: `옵션2-${option.option2.nameTrans || option.option2.name}`,
                optionNumber: option.optionNumber,
                optionIndex: index
              });
            }
          }

          // 옵션 메인 이미지 (모든 처리된 이미지를 processedImages에 추가)
          if (option.mainImageStatus && option.mainImageStatus.processedImage) {
            const processedImageUrl = option.mainImageStatus.processedImage;

            // 이미 추가된 URL인지 확인
            if (!processedImageUrls.has(processedImageUrl)) {
              // 중복이 아닌 경우만 추가
              processedImageUrls.add(processedImageUrl);

              // 처리된 이미지가 있는 경우 모두 작업이미지로 분류
              const processedImg = {
                url: processedImageUrl,
                label: `옵션${index + 1}-처리됨`,
                optionNumber: option.optionNumber,
                optionIndex: index,
                isProcessed: true,
                textRemoved: option.mainImageStatus.textRemoved || false,
                originalUrl: option.mainImageStatus.image
              };

              // 작업된 이미지 목록에 추가
              this.processedImages.push(processedImg);
            }

            // 옵션 이미지에도 추가 (URL 중복 체크)
            if (!optionImageUrls.has(processedImageUrl)) {
              optionImageUrls.add(processedImageUrl);
              this.optionImages.push({
                url: processedImageUrl,
                label: option.mainImageStatus.textRemoved ? `옵션${index + 1}-텍스트제거` : `옵션${index + 1}-처리됨`,
                optionNumber: option.optionNumber,
                optionIndex: index,
                isProcessed: true
              });
            }
          } else if (option.mainImageStatus && option.mainImageStatus.image) {
            const imageUrl = option.mainImageStatus.image;
            if (!optionImageUrls.has(imageUrl)) {
              optionImageUrls.add(imageUrl);
              this.optionImages.push({
                url: imageUrl,
                label: `옵션${index + 1}-원본`,
                optionNumber: option.optionNumber,
                optionIndex: index
              });
            }
          }
        });
      }

      // 현재 선택된 옵션의 처리된 이미지가 있는지 확인하고 표시
      if (this.selectedOption && this.selectedOption.mainImageStatus) {
        // 처리된 이미지가 있는 경우
        if (this.selectedOption.mainImageStatus.processedImage) {
          const processedImageUrl = this.selectedOption.mainImageStatus.processedImage;

          // 이미 processedImages에 있는지 확인
          if (!processedImageUrls.has(processedImageUrl)) {
            // 목록에 없으면 추가
            processedImageUrls.add(processedImageUrl);
            this.processedImages.push({
              url: processedImageUrl,
              label: `현재 옵션`,
              optionNumber: this.selectedOption.optionNumber,
              textRemoved: this.selectedOption.mainImageStatus.textRemoved || false,
              originalUrl: this.selectedOption.mainImageStatus.image
            });
          }
        }
        // loadOptionImages 메서드 이어서...
        // 원본 이미지가 있는 경우 (processedImage가 없는 경우에만 추가)
        else if (this.selectedOption.mainImageStatus.image && !this.selectedOption.mainImageStatus.processedImage) {
          const imageUrl = this.selectedOption.mainImageStatus.image;
          if (!optionImageUrls.has(imageUrl)) {
            optionImageUrls.add(imageUrl);
            this.optionImages.push({
              url: imageUrl,
              label: `현재 옵션-원본`,
              optionNumber: this.selectedOption.optionNumber
            });
          }
        }
      }

      // 기본적으로 처리된 이미지 탭을 먼저 보여주기
      if (this.processedImages.length > 0) {
        this.activeTab = 'processed';
      } else if (this.optionImages.length > 0) {
        this.activeTab = 'option';
      } else if (this.mainImages.length > 0) {
        this.activeTab = 'main';
      }
    },

    // 에디터에서 이미지 저장 이벤트 처리
    handleEditorSave(htmlContent) {
      console.log('에디터 저장 이벤트:', htmlContent.length);
      // HTML에서 이미지 URL 추출
      // const tempDiv = document.createElement('div');
      // tempDiv.innerHTML = htmlContent;
      // const imgElement = tempDiv.querySelector('img');

      // if (imgElement && imgElement.src) {
      //   this.editedImageUrl = imgElement.src;
      //   this.updateProcessedImage(imgElement.src);
      // } else {
      //   console.error('이미지 URL을 찾을 수 없습니다');
      // }
    },

    // applyEditorChanges 메서드 수정
    async applyEditorChanges() {
      try {
        // 편집기가 있는지 확인
        if (!this.$refs.imageEditor || !this.$refs.imageEditor.editor) {
          throw new Error('이미지 편집기를 찾을 수 없습니다');
        }

        // 편집기 내 선택 요소 해제
        this.$refs.imageEditor.editor.select(null);

        // 로딩 상태 시작
        this.isRemovingText = true;

        // 편집기의 iframe 요소 가져오기
        const frameEl = this.$refs.imageEditor.editor.Canvas.getFrameEl();
        const frameDoc = frameEl.contentDocument;

        // html2canvas 사용하여 이미지 캡처
        const canvas = await html2canvas(frameDoc.body, {
          allowTaint: true,
          useCORS: true,
          scale: 2, // 더 높은 해상도
          logging: false,
          windowWidth: frameEl.offsetWidth,
          windowHeight: frameEl.offsetHeight
        });

        // 이미지 데이터 URL 생성
        const imageData = canvas.toDataURL('image/png');

        // 파일 이름 생성
        const fileName = `${this.product.registrationId}_${this.selectedOption.optionNumber}_${Date.now()}.png`;

        // S3에 업로드
        const uploadedUrl = await mainImageService.uploadImage({
          imageData: imageData,
          filename: fileName,
          type: 'main'
        });

        if (uploadedUrl) {
          // 편집된 이미지 URL 저장
          this.editedImageUrl = uploadedUrl;

          // 처리된 이미지 업데이트
          this.updateProcessedImage(uploadedUrl);

          // 성공 메시지 표시
          this.$bvToast.toast('이미지가 저장되었습니다', {
            title: '완료',
            variant: 'success',
            autoHideDelay: 2000,
            toaster: 'b-toaster-bottom-right'
          });
        } else {
          throw new Error('이미지 업로드에 실패했습니다');
        }
      } catch (error) {
        console.error('에디터 변경사항 적용 중 오류:', error);
        this.$bvToast.toast(`이미지 저장 중 오류가 발생했습니다: ${error.message}`, {
          title: '오류',
          variant: 'danger',
          autoHideDelay: 3000,
          toaster: 'b-toaster-bottom-right'
        });
      } finally {
        // 로딩 상태 종료
        this.isRemovingText = false;
      }
    },
    // updateProcessedImage 메서드 수정
    updateProcessedImage(imageUrl) {
      // 이미 S3 URL인 경우 (http로 시작하면)
      if (imageUrl.startsWith('http')) {
        const imageData = {
          originalUrl: this.imageHistory.original || imageUrl,
          processedUrl: imageUrl,
          dimensions: `${this.resizeWidth}x${this.resizeHeight}`, // 기본 크기 사용
          filesize: '알 수 없음', // 파일 크기는 알 수 없음
          textRemoved: true
        };

        // Vuex 액션 호출
        if (this.selectedOption) {
          this.updateOptionImage({
            optionNumber: this.selectedOption.optionNumber,
            imageData
          }).then(() => {
            // 처리된 이미지 목록에 추가
            if (!this.processedImages.some(img => img.url === imageUrl)) {
              this.processedImages.push({
                url: imageUrl,
                type: 'main',
                originalUrl: this.imageHistory.original,
                textRemoved: true
              });
            }

            // 탭 변경
            this.activeTab = 'processed';
          });
        }
        return;
      }

      // 기존 data:image URL 처리 로직 (변경 없음)
      const img = new Image();
      img.onload = () => {
        const imageData = {
          originalUrl: this.imageHistory.original || imageUrl,
          processedUrl: imageUrl,
          dimensions: `${img.naturalWidth}x${img.naturalHeight}`,
          filesize: this.estimateFileSize(imageUrl),
          textRemoved: true
        };

        // Vuex 액션 호출
        if (this.selectedOption) {
          this.updateOptionImage({
            optionNumber: this.selectedOption.optionNumber,
            imageData
          }).then(() => {
            // 처리된 이미지 목록에 추가
            if (!this.processedImages.some(img => img.url === imageUrl)) {
              this.processedImages.push({
                url: imageUrl,
                type: 'main',
                originalUrl: this.imageHistory.original,
                textRemoved: true
              });
            }

            // 탭 변경
            this.activeTab = 'processed';
          });
        }
      };
      img.src = imageUrl;
    },

    // 이미지 파일 크기 추정
    estimateFileSize(dataUrl) {
      // 대략적인 파일 크기 계산 (Base64 문자열 길이 * 0.75)
      if (dataUrl && dataUrl.startsWith('data:')) {
        const base64Length = dataUrl.split(',')[1].length;
        const fileSizeBytes = Math.round((base64Length * 0.75));

        if (fileSizeBytes < 1024) {
          return `${fileSizeBytes} B`;
        } else if (fileSizeBytes < 1024 * 1024) {
          return `${Math.round(fileSizeBytes / 1024)} KB`;
        } else {
          return `${(fileSizeBytes / (1024 * 1024)).toFixed(1)} MB`;
        }
      }

      return '알 수 없음';
    },

    // 에디터 초기화
    resetEditor() {
      if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
        // 최신 원본 이미지 가져오기
        const imageToLoad = this.imageHistory.original || this.currentMainImage;
        if (imageToLoad) {
          const html = this.getImageHtmlFromUrl(imageToLoad);
          this.$refs.imageEditor.editor.setComponents(html);
          this.editedImageUrl = null;
        }
      }
    },

    // 이미지 업로드 관련 메서드
    showImageUpload() {
      this.$refs.imageUploadInput.click();
    },

    handleImageUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = async (e) => {
        const imageUrl = e.target.result;

        // 선택 상태 초기화
        this.selectedImageIndex = -1;
        this.selectedImageUrl = imageUrl;
        this.currentMainImage = imageUrl;
        this.imageHistory.original = imageUrl;

        // 에디터 업데이트
        if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
          const newHtml = this.getImageHtmlFromUrl(imageUrl);
          this.$refs.imageEditor.editor.setComponents(newHtml);
        }

        // 이미지를 S3에 업로드
        if (this.selectedOption) {
          try {
            // base64 데이터 추출
            const base64Data = imageUrl;

            // 파일 이름 생성
            const fileName = `${this.product.registrationId}_${this.selectedOption.optionNumber}_${Date.now()}.jpg`;

            // S3 업로드 (서비스 활용)
            const uploadedUrl = await mainImageService.uploadImage({
              imageData: base64Data,
              filename: fileName,
              type: 'main'
            });

            if (uploadedUrl) {
              // 업로드 성공하면 Vuex 액션 호출하여 이미지 업데이트
              await this.updateOptionImage({
                optionNumber: this.selectedOption.optionNumber,
                imageData: {
                  originalUrl: uploadedUrl,
                  processedUrl: uploadedUrl,
                  dimensions: `${this.resizeWidth}x${this.resizeHeight}`,
                  filesize: this.estimateFileSize(base64Data)
                }
              });

              this.$bvToast.toast('이미지가 업로드되었습니다', {
                title: '성공',
                variant: 'success',
                solid: true,
                toaster: 'b-toaster-bottom-right'
              });
            }
          } catch (error) {
            console.error('이미지 업로드 중 오류:', error);
            this.$bvToast.toast('이미지 업로드 중 오류가 발생했습니다', {
              title: '오류',
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-bottom-right'
            });
          }
        }
      };
      reader.readAsDataURL(file);
    },

    // 에디터 오류 처리
    handleEditorError() {
      console.error("이미지 편집기 로드 오류");
      // 대체 UI나 기능 활성화 가능
    },

    // 1688에서 이미지 불러오기
    fetchImagesFrom1688() {
      // Vuex 액션 호출 또는 서비스 메서드 호출
      mainImageService.fetchImagesFrom1688(this.product.구매링크)
        .then(images => {
          if (images && images.length > 0) {
            // 이미지 처리 로직
            this.$bvToast.toast('1688에서 이미지를 불러왔습니다', {
              title: '성공',
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-bottom-right'
            });
          } else {
            this.$bvToast.toast('가져올 이미지가 없습니다', {
              title: '알림',
              variant: 'info',
              solid: true,
              toaster: 'b-toaster-bottom-right'
            });
          }
        })
        .catch(error => {
          console.error('이미지 불러오기 오류:', error);
          this.$bvToast.toast('이미지를 불러오는 중 오류가 발생했습니다', {
            title: '오류',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-bottom-right'
          });
        });
    },
    handleKeyDown(e) {
      if (e.key === 'Escape' && this.isPreviewMode) {
        this.isPreviewMode = false;
      }
    },
  }
};
</script>

<style scoped>
.main-image-edit {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* 레이아웃 컨테이너 */
.image-editor-layout {
  display: flex;
  height: 100%;
  background-color: #f9f9f9;
}

/* 좌측 이미지 선택 패널 */
.image-selector-panel {
  width: 280px;
  background-color: #fff;
  border-right: 1px solid #eee;
  display: flex;
  flex-direction: column;
}

.panel-header {
  padding: 15px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel-title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

/* 이미지 탭 스타일 */
.image-tabs {
  border-bottom: 1px solid #eee;
}

.image-tabs .nav-tabs {
  border-bottom: none;
}

.image-tabs .nav-link {
  font-size: 12px;
  padding: 5px 10px;
  color: #777;
}

.image-tabs .nav-link.active {
  color: #3498db;
  font-weight: 600;
}

.image-gallery {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.image-item {
  position: relative;
  border: 2px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
  aspect-ratio: 1/1;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.image-item.selected {
  border-color: #3498db;
}

.image-overlay {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background: #3498db;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s;
}

.image-item.selected .image-overlay {
  opacity: 1;
}

/* 이미지 라벨 스타일 추가 */
.image-label {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 10px;
  padding: 3px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.empty-gallery {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #aaa;
  text-align: center;
  padding: 20px;
}

.image-options {
  padding: 15px;
  border-top: 1px solid #eee;
}

.options-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #555;
}

/* 우측 에디터 패널 */
.editor-main-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

.editor-header {
  padding: 10px 15px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
}

.editor-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  padding: 20px;
  min-height: 400px;
  position: relative;
}

.image-container {
  position: relative;
  width: min(100%, 500px);
  height: min(100%, 500px);
  aspect-ratio: 1/1;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* 로딩 상태 */
.loading-container {
  text-align: center;
  padding: 40px 0;
}

/* 로딩 오버레이 */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.loading-content {
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
}

.loading-content p {
  margin-top: 15px;
  color: #333;
}

/* 텍스트 제거 배지 스타일 */
.text-removed-badge {
  background-color: #28a745;
  color: white;
  padding: 0 3px;
  border-radius: 3px;
  font-size: 9px;
  display: inline-block;
}

/* 반응형 조정 */
@media (max-width: 768px) {
  .image-editor-layout {
    flex-direction: column;
  }

  .image-selector-panel {
    width: 100%;
    max-height: 200px;
    border-right: none;
    border-bottom: 1px solid #eee;
  }
}

/* 미리보기 모드 스타일 수정 */
.editor-header {
  padding: 10px 15px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  /* 추가: position 속성 */
}

.mode-toggle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  /* 스위치가 다른 요소 위에 표시되도록 */
}

.preview-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-container {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border: 2px solid white;
}

.no-image-placeholder {
  color: white;
  text-align: center;
}

.no-image-placeholder i {
  font-size: 48px;
  margin-bottom: 10px;
}

.close-preview-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.close-preview-btn:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.close-preview-btn:focus {
  outline: none;
}
</style>