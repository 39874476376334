<template>
  <div class="quotation-edit">
    <h4 class="mb-3">등록 세부정보</h4>

    <!-- 로딩 중일 때 표시 -->
    <div v-if="isProcessing" class="loading-container">
      <b-spinner variant="primary"></b-spinner>
      <p>{{ progressMessage || '등록 세부정보 작업 중...' }}</p>
    </div>

    <!-- 등록 세부정보 편집 폼 -->
    <div v-else class="quotation-form">
      <div class="card mb-4">
        <div class="card-header bg-light d-flex justify-content-between align-items-center">
          <span>쿠팡 등록 정보</span>
          <div>
            <b-button variant="success" size="sm" @click="saveQuotation">
              <i class="las la-save"></i> 저장
            </b-button>
          </div>
        </div>
        <div class="card-body">

          <div v-if="!hasCoupangDoc" class="alert alert-warning">
            <i class="las la-exclamation-triangle"></i> 쿠팡 문서 정보가 없습니다. 먼저 상품 정보를 불러오세요.
            <br>
            <small>상품 ID: {{ product ? product.registrationId : '없음' }}</small>
          </div>

          <!-- 쿠팡 문서가 있을 경우 필드 나열 -->
          <template v-else>
            <!-- 1. startPage 섹션 -->
            <div class="section mb-4">
              <h5 class="bg-primary text-white p-2 rounded">1. Start Page (시작 페이지)</h5>
              <div class="p-3 border rounded">
                <div class="row">
                  <div v-for="(value, field) in docData.startPage" :key="`startPage.${field}`" class="col-md-12">
                    <div class="form-group">
                      <label>{{ getFieldTitle('startPage', field) }} <span v-if="isRequiredField('startPage', field)"
                          class="text-danger">*</span></label>
                      <small v-if="getFieldDescription('startPage', field)" class="form-text text-muted mb-1">{{
                        getFieldDescription('startPage', field) }}</small>
                      <input type="text" class="form-control" v-model="docData.startPage[field]"
                        :readonly="field === 'categoryPath'">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 2. productPage 섹션 -->
            <div class="section mb-4">
              <h5 class="bg-info text-white p-2 rounded">2. Product Page (상품 페이지)</h5>
              <div class="p-3 border rounded">
                <!-- 2.1 기본 필드 -->
                <h6 class="border-bottom pb-2 mb-3">기본 정보</h6>
                <div class="row">
                  <div v-for="(value, field) in getDirectFields(docData.productPage)" :key="`productPage.${field}`"
                    class="col-md-12">
                    <div class="form-group">
                      <label>{{ getFieldTitle('productPage', field) }} <span
                          v-if="isRequiredField('productPage', field)" class="text-danger">*</span></label>
                      <small v-if="getFieldDescription('productPage', field)" class="form-text text-muted mb-1">{{
                        getFieldDescription('productPage', field) }}</small>

                      <!-- 필드 타입에 따른 입력 컴포넌트 -->
                      <select v-if="hasFieldOptions('productPage', field) || field === 'brand'" class="form-control"
                        v-model="docData.productPage[field]">
                        <!-- 현재 브랜드 값이 있으면 추가 -->
                        <option v-if="field === 'brand' && docData.productPage[field]"
                          :value="docData.productPage[field]">{{ docData.productPage[field] }}</option>
                        <!-- 기본 옵션 목록 -->
                        <option v-for="option in getFieldOptions('productPage', field)" :key="option" :value="option">{{
                          option }}</option>
                      </select>

                      <!-- 검색 태그 필드 - 특별 처리 -->
                      <template v-else-if="field === 'searchTags'">
                        <textarea class="form-control" v-model="docData.productPage[field]" rows="3"
                          :maxlength="150"></textarea>
                        <div class="d-flex justify-content-between mt-1">
                          <small class="text-muted">쉼표(,)로 구분하여 입력하세요. 최대 150자</small>
                          <small
                            :class="{ 'text-danger': searchTagsCount > 140, 'text-warning': searchTagsCount > 120 && searchTagsCount <= 140, 'text-muted': searchTagsCount <= 120 }">
                            {{ searchTagsCount }}/150자
                          </small>
                        </div>
                      </template>

                      <!-- 기타 필드는 일반 텍스트 입력 -->
                      <input v-else type="text" class="form-control" v-model="docData.productPage[field]">
                    </div>
                  </div>
                </div>

                <!-- 2.2 commonAttributes 필드 -->
                <div v-if="docData.productPage.commonAttributes" class="mt-4">
                  <h6 class="border-bottom pb-2 mb-3">가격 정보</h6>
                  <div class="row">
                    <div v-for="(value, field) in getPriceFields" :key="`commonAttributes.${field}`" class="col-md-12">
                      <div class="form-group">
                        <label>{{ getFieldTitle('productPage.commonAttributes', field) }} <span
                            v-if="isRequiredField('productPage.commonAttributes', field)"
                            class="text-danger">*</span></label>
                        <small v-if="getFieldDescription('productPage.commonAttributes', field)"
                          class="form-text text-muted mb-1">{{
                            getFieldDescription('productPage.commonAttributes',
                              field)
                          }}</small>

                        <input v-if="field.includes('Price') || field.includes('price')" type="number"
                          class="form-control" v-model.number="docData.productPage.commonAttributes[field]">
                        <input v-else type="text" class="form-control"
                          v-model="docData.productPage.commonAttributes[field]">
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 2.3 노출 속성 -->
                <div
                  v-if="docData.productPage.commonAttributes && docData.productPage.commonAttributes.exposedAttributes"
                  class="mt-4">
                  <h6 class="border-bottom pb-2 mb-3">노출 속성</h6>
                  <small class="form-text text-muted mb-2 d-block">상품 상세 페이지에 노출되는 상품의 주요 속성 정보입니다.</small>

                  <div v-for="(attr, index) in docData.productPage.commonAttributes.exposedAttributes"
                    :key="`exposed-${index}`" class="mb-2">
                    <div class="d-flex">
                      <input type="text" class="form-control mr-2" v-model="attr.attributeName" readonly
                        style="width: 40%" placeholder="속성명">

                      <input type="text" class="form-control" v-model="attr.attributeValue" placeholder="속성값">
                    </div>
                  </div>
                </div>

                <!-- 2.4 비노출 속성 -->
                <div v-if="docData.productPage.unexposedAttributes" class="mt-4">
                  <h6 class="border-bottom pb-2 mb-3">비노출 속성</h6>
                  <small class="form-text text-muted mb-2 d-block">상품 상세 페이지에 노출되지 않는 상품의 부가 정보입니다.</small>

                  <div v-for="(attr, index) in docData.productPage.unexposedAttributes" :key="`unexposed-${index}`"
                    class="mb-2">
                    <div class="d-flex">
                      <input type="text" class="form-control mr-2" v-model="attr.attributeName" readonly
                        style="width: 40%" placeholder="속성명">

                      <input type="text" class="form-control" v-model="attr.attributeValue" placeholder="속성값">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 3. imagePage 섹션 -->
            <div class="section mb-4">
              <h5 class="bg-success text-white p-2 rounded">3. Image Page (이미지 페이지)</h5>
              <div class="p-3 border rounded">
                <!-- 3.1 이미지 정보 -->
                <div v-if="docData.imagePage && docData.imagePage.images" class="mb-3">
                  <h6 class="border-bottom pb-2 mb-3">기본 이미지</h6>
                  <div class="row">
                    <div v-for="(value, field) in getDirectImageFields" :key="`imagePage.images.${field}`"
                      class="col-md-12">
                      <div class="form-group">
                        <label>{{ getFieldTitle('imagePage.images', field) }} <span
                            v-if="isRequiredField('imagePage.images', field)" class="text-danger">*</span></label>
                        <small v-if="getFieldDescription('imagePage.images', field)"
                          class="form-text text-muted mb-1">{{
                            getFieldDescription('imagePage.images', field) }}</small>
                        <input type="text" class="form-control" v-model="docData.imagePage.images[field]">
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 3.2 라벨 이미지 -->
                <div v-if="docData.imagePage && docData.imagePage.images && docData.imagePage.images.labelImages"
                  class="mt-4">
                  <h6 class="border-bottom pb-2 mb-3">라벨 이미지</h6>
                  <small class="form-text text-muted mb-2 d-block">제품 라벨, 사이즈 차트 등의 이미지 정보입니다.</small>

                  <div v-for="(label, index) in docData.imagePage.images.labelImages" :key="`label-${index}`"
                    class="mb-2">
                    <div class="d-flex">
                      <input type="text" class="form-control mr-2" v-model="label.labelImageType" readonly
                        style="width: 40%" placeholder="라벨 타입">

                      <input type="text" class="form-control" v-model="label.labelImageFiles" placeholder="이미지 파일">
                    </div>
                  </div>
                </div>

                <!-- 3.3 상세 정보 -->
                <div v-if="docData.imagePage && docData.imagePage.details" class="mt-4">
                  <h6 class="border-bottom pb-2 mb-3">상세 정보</h6>
                  <div class="row">
                    <div v-for="(value, field) in docData.imagePage.details" :key="`imagePage.details.${field}`"
                      class="col-md-12">
                      <div class="form-group">
                        <label>{{ getFieldTitle('imagePage.details', field) }} <span
                            v-if="isRequiredField('imagePage.details', field)" class="text-danger">*</span></label>
                        <small v-if="getFieldDescription('imagePage.details', field)"
                          class="form-text text-muted mb-1">{{
                            getFieldDescription('imagePage.details', field) }}</small>

                        <textarea v-if="field === 'htmlProductDetailContent'" class="form-control"
                          v-model="docData.imagePage.details[field]" rows="1"></textarea>
                        <input v-else type="text" class="form-control" v-model="docData.imagePage.details[field]">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 4. legalPage 섹션 -->
            <div class="section mb-4">
              <h5 class="bg-danger text-white p-2 rounded">4. Legal Page (법적 정보)</h5>
              <div class="p-3 border rounded">
                <!-- 4.1 기본 법적 정보 -->
                <h6 class="border-bottom pb-2 mb-3">기본 법적 정보</h6>
                <div class="row">
                  <div v-for="(value, field) in getDirectLegalFields" :key="`legalPage.${field}`" class="col-md-12">
                    <div class="form-group">
                      <label>{{ getFieldTitle('legalPage', field) }} <span v-if="isRequiredField('legalPage', field)"
                          class="text-danger">*</span></label>
                      <small v-if="getFieldDescription('legalPage', field)" class="form-text text-muted mb-1">{{
                        getFieldDescription('legalPage', field) }}</small>

                      <select v-if="hasFieldOptions('legalPage', field)" class="form-control"
                        v-model="docData.legalPage[field]">
                        <option v-for="option in getFieldOptions('legalPage', field)" :key="option" :value="option">{{
                          option }}</option>
                      </select>

                      <input v-else type="text" class="form-control" v-model="docData.legalPage[field]">
                    </div>
                  </div>
                </div>

                <!-- 4.2 인증 정보 -->
                <div v-if="docData.legalPage && docData.legalPage.certificates" class="mt-4">
                  <h6 class="border-bottom pb-2 mb-3">인증 정보</h6>
                  <small class="form-text text-muted mb-2 d-block">상품의 안전 인증 정보입니다.</small>

                  <div v-for="(cert, index) in docData.legalPage.certificates" :key="`cert-${index}`" class="mb-2">
                    <div class="d-flex">
                      <input type="text" class="form-control mr-2" v-model="cert.certificateName" readonly
                        style="width: 40%" placeholder="인증명">

                      <input type="text" class="form-control" v-model="cert.certificateValue" placeholder="인증 번호">
                    </div>
                  </div>
                </div>

                <!-- 4.3 상품 고시 정보 -->
                <div v-if="docData.legalPage && docData.legalPage.notices" class="mt-4">
                  <h6 class="border-bottom pb-2 mb-3">상품 고시 정보</h6>
                  <small class="form-text text-muted mb-2 d-block">전자상거래법에 따른 필수 표시 정보입니다.</small>

                  <div v-for="(notice, index) in docData.legalPage.notices" :key="`notice-${index}`" class="mb-2">
                    <div class="d-flex">
                      <input type="text" class="form-control mr-2" v-model="notice.noticeItemName" readonly
                        style="width: 40%" placeholder="항목">

                      <input type="text" class="form-control" v-model="notice.noticeItemValue" placeholder="값">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 5. logisticsPage 섹션 -->
            <div class="section mb-4">
              <h5 class="bg-dark text-white p-2 rounded">5. Logistics Page (물류 정보)</h5>
              <div class="p-3 border rounded">
                <div class="row">
                  <div v-for="(value, field) in docData.logisticsPage" :key="`logisticsPage.${field}`"
                    class="col-md-12">
                    <div class="form-group">
                      <label>{{ getFieldTitle('logisticsPage', field) }} <span
                          v-if="isRequiredField('logisticsPage', field)" class="text-danger">*</span></label>
                      <small v-if="getFieldDescription('logisticsPage', field)" class="form-text text-muted mb-1">{{
                        getFieldDescription('logisticsPage', field) }}</small>

                      <!-- 필드 타입에 따른 입력 컴포넌트 -->
                      <select v-if="hasFieldOptions('logisticsPage', field)" class="form-control"
                        v-model="docData.logisticsPage[field]">
                        <option v-for="option in getFieldOptions('logisticsPage', field)" :key="option" :value="option">
                          {{
                            option }}</option>
                      </select>

                      <input v-else-if="field === 'totalSKUsInBox' || field === 'daysToExpiration'" type="number"
                        class="form-control" v-model.number="docData.logisticsPage[field]">

                      <input v-else type="text" class="form-control" v-model="docData.logisticsPage[field]">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 하단 버튼 -->
            <div class="mt-4 text-center">
              <b-button variant="secondary" class="mr-2" @click="resetQuotation">
                <i class="las la-undo"></i> 초기화
              </b-button>
              <b-button variant="success" @click="saveQuotation">
                <i class="las la-save"></i> 저장
              </b-button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import quotationService from '../../services/works/quotationService';

export default {
  name: "QuotationEdit",
  data() {
    return {
      // 스키마 정보 저장
      schema: null,

      // 쿠팡 문서 데이터
      docData: {
        startPage: {
          productName: '',
          categoryPath: ''
        },
        productPage: {
          modelNumber: '',
          brand: '',
          commonAttributes: {
            exposedAttributes: [],
            purchasePrice: 0,
            coupangSalePrice: 0,
            msrp: 0,
            productBarcode: '바코드 없음(쿠팡 바코드 생성 요청)'
          },
          manufacturer: '',
          businessType: '제조사',
          taxationSchema: '과세',
          importType: '수입대상아님',
          searchTags: '',
          unexposedAttributes: []
        },
        imagePage: {
          images: {
            mainImage: '',
            additionalImage: '',
            labelImages: []
          },
          details: {
            detailedImage: '',
            htmlProductDetailContent: '',
            altText: ''
          }
        },
        legalPage: {
          kcMarkType: '해당사항없음',
          certificates: [],
          adCert: '',
          notices: []
        },
        logisticsPage: {
          totalSKUsInBox: this.$store.state.settings[0]?.defaultSettings?.totalSKUsInBox || 1,
          daysToExpiration: 0,
          specialHandlingReason: '해당사항없음',
          skuUnitBoxWeight: '1000',
          skuUnitBoxDimension: '300*300*50',
          fashionYear: new Date().getFullYear().toString(),
          fashionSeason: '사계절'
        },
        sourcingPage: {
          sourcingChannelType: '',
          sourcingChannelId: ''
        }
      }
    };
  },
  computed: {
    // Vuex 상태 매핑
    ...mapState({
      product: state => state.selectedRocketProduct,
      selectedOption: state => state.selectedOption,
      isProcessing: state => state.processing,
      progressMessage: state => state.progressMessage
    }),

    // 쿠팡 문서 존재 여부
    hasCoupangDoc() {
      return this.currentCoupangDoc !== null;
    },

    // 스키마 존재 여부
    hasSchema() {
      return this.product &&
        this.product.rocketRegInfo &&
        this.product.rocketRegInfo.schemaString;
    },

    // 현재 선택된 옵션의 coupangDoc
    currentCoupangDoc() {
      // 선택된 옵션의 coupangDoc
      if (this.selectedOption && this.selectedOption.coupangDoc) {
        return this.selectedOption.coupangDoc;
      }
      // 첫 번째 옵션의 coupangDoc
      else if (this.product && this.product.options && this.product.options.length > 0 &&
        this.product.options[0].coupangDoc) {
        return this.product.options[0].coupangDoc;
      }
      // 상품의 coupangDoc
      else if (this.product && this.product.coupangDoc) {
        return this.product.coupangDoc;
      }

      return null;
    },
    // productPage의 commonAttributes 중 가격 관련 필드만 필터링
    getPriceFields() {
      if (!this.docData.productPage || !this.docData.productPage.commonAttributes) return {};

      const result = {};
      const fields = this.getDirectFields(this.docData.productPage.commonAttributes);

      for (const field in fields) {
        if (field.includes('Price') || field.includes('price') || field === 'productBarcode') {
          result[field] = fields[field];
        }
      }

      return result;
    },

    // imagePage의 직접 이미지 필드 (labelImages 아닌 것)
    getDirectImageFields() {
      if (!this.docData.imagePage || !this.docData.imagePage.images) return {};

      const result = {};
      const fields = this.getDirectFields(this.docData.imagePage.images);

      for (const field in fields) {
        if (field !== 'labelImages') {
          result[field] = fields[field];
        }
      }

      return result;
    },

    // legalPage의 직접 필드 (certificates, notices, adCert 아닌 것)
    getDirectLegalFields() {
      if (!this.docData.legalPage) return {};

      const result = {};
      const fields = this.getDirectFields(this.docData.legalPage);

      for (const field in fields) {
        if (field !== 'certificates' && field !== 'notices' && field !== 'adCert') {
          result[field] = fields[field];
        }
      }

      return result;
    },
  },
  watch: {
    // 제품 정보가 변경되면 스키마 및 데이터 초기화
    product: {
      handler(newProduct) {
        if (newProduct) {
          this.initializeSchema();
          this.initializeData();
        }
      },
      immediate: true,
      deep: true
    },

    // 선택된 옵션이 변경되면 데이터 초기화
    selectedOption: {
      handler() {
        this.initializeData();
      },
      deep: true
    },

    'docData.productPage.searchTags': {
      handler(newVal) {
        this.searchTagsCount = newVal ? newVal.length : 0;
      },
      immediate: true
    }
  },
  mounted() {
    console.log(this.docData)
  },
  methods: {
    // Vuex 액션 매핑
    ...mapActions([
      'updateRocketProduct',
      'setProcessing'
    ]),

    // 직접 필드만 가져오기 (배열, 객체 아닌 필드)
    getDirectFields(obj) {
      if (!obj) return {};

      const result = {};
      for (const key in obj) {
        // 배열이나 객체가 아닌 필드만 포함
        if (obj[key] !== null &&
          typeof obj[key] !== 'object' ||
          (typeof obj[key] === 'object' && obj[key] === null)) {
          result[key] = obj[key];
        }
      }
      return result;
    },

    // 스키마 초기화
    initializeSchema() {
      try {
        if (!this.hasSchema) return;

        // 스키마 데이터 파싱
        const schemaString = this.product.rocketRegInfo.schemaString;
        console.log('스키마 문자열 길이:', schemaString ? schemaString.length : 0);

        this.schema = JSON.parse(schemaString);
        console.log('스키마 파싱 완료:', Object.keys(this.schema.properties));
      } catch (error) {
        console.error('스키마 초기화 중 오류:', error);
        this.schema = null;
      }
    },

    // 데이터 초기화
    initializeData() {
      try {
        // 제품 정보에서 초기 데이터 설정
        if (!this.product) return;

        // 선택된 옵션이 있는 경우 해당 옵션 사용
        const targetOption = this.selectedOption ||
          (this.product.options && this.product.options.length > 0 ?
            this.product.options[0] : null);

        console.log('초기화 중인 제품:', this.product.registrationId);
        console.log('선택된 옵션:', targetOption ? targetOption.optionNumber : 'none');
        console.log('현재 coupangDoc:', this.currentCoupangDoc ? 'available' : 'none');

        // 1. coupangDoc이 있는 경우 해당 데이터를 기반으로 초기화
        if (this.currentCoupangDoc && this.currentCoupangDoc.jsonDocument && this.currentCoupangDoc.jsonDocument[0]) {
          this.docData = JSON.parse(JSON.stringify(this.currentCoupangDoc.jsonDocument[0]));
          console.log('coupangDoc에서 데이터 로드됨');
        }
        // 2. coupangDoc이 없는 경우 quotationService를 사용하여 기본 데이터 생성
        else {
          const baseData = quotationService.createBaseQuotationData(this.product, targetOption);
          this.docData = this.convertToJsonDocument(baseData);
          console.log('기본 데이터 생성됨');
        }

        // 누락된 필드가 있는 경우 기본값 추가
        this.docData = this.ensureRequiredFields(this.docData);

        console.log('데이터 초기화 완료');
      } catch (error) {
        console.error('데이터 초기화 중 오류:', error);
        // 오류 발생 시 빈 구조 생성
        this.docData = this.getEmptyJsonDocument();
      }
    },

    // 필드 타이틀 가져오기
    getFieldTitle(section, field) {
      if (!this.schema) return this.getDefaultTitle(section, field);

      try {
        // 섹션 찾기
        const sectionSchema = this.schema.properties[section];
        if (!sectionSchema || !sectionSchema.properties) {
          return this.getDefaultTitle(section, field);
        }

        // 직접 필드인 경우
        if (sectionSchema.properties[field]) {
          return sectionSchema.properties[field].title || this.getDefaultTitle(section, field);
        }

        // 중첩 필드인 경우
        // section.field 형식 찾기 (예: productPage.commonAttributes)
        const nestedParts = field.split('.');
        if (nestedParts.length > 1) {
          let current = sectionSchema.properties;

          for (let i = 0; i < nestedParts.length; i++) {
            if (!current[nestedParts[i]] || !current[nestedParts[i]].properties) {
              return this.getDefaultTitle(section, field);
            }
            current = current[nestedParts[i]].properties;
          }

          return current.title || this.getDefaultTitle(section, field);
        }

        return this.getDefaultTitle(section, field);
      } catch (error) {
        console.error('필드 타이틀 가져오기 오류:', error);
        return this.getDefaultTitle(section, field);
      }
    },

    // 기본 타이틀 가져오기
    getDefaultTitle(section, field) {
      const defaultTitles = {
        'startPage.productName': '상품명',
        'startPage.categoryPath': '카테고리 경로',
        'productPage.modelNumber': '모델 번호',
        'productPage.brand': '브랜드',
        'productPage.manufacturer': '제조사',
        'productPage.businessType': '거래 유형',
        'productPage.taxationSchema': '과세 여부',
        'productPage.importType': '수입 유형',
        'productPage.searchTags': '검색 태그',
        'productPage.commonAttributes.purchasePrice': '공급가',
        'productPage.commonAttributes.coupangSalePrice': '판매가',
        'productPage.commonAttributes.msrp': '권장소비자가',
        'productPage.commonAttributes.productBarcode': '바코드',
        'legalPage.kcMarkType': 'KC 마크 타입',
        'logisticsPage.totalSKUsInBox': '박스당 입수량',
        'logisticsPage.daysToExpiration': '유통기한(일)',
        'logisticsPage.specialHandlingReason': '특별 취급 사유',
        'logisticsPage.skuUnitBoxWeight': '상품 박스 무게(g)',
        'logisticsPage.skuUnitBoxDimension': '상품 박스 크기(mm)',
        'logisticsPage.fashionSeason': '시즌',
        'imagePage.images.mainImage': '대표 이미지',
        'imagePage.images.sizeChartImage': '사이즈표 이미지',
        'imagePage.images.additionalImage': '추가 이미지',
        'imagePage.details.detailedImage': '상세 이미지',
        'imagePage.details.htmlProductDetailContent': 'HTML 상세 내용',
        'imagePage.details.altText': '대체 텍스트'
      };

      const key = `${section}.${field}`;
      return defaultTitles[key] || field;
    },

    // 필드 설명 가져오기
    getFieldDescription(section, field) {
      if (!this.schema) return '';

      try {
        // 섹션 찾기
        const sectionSchema = this.schema.properties[section];
        if (!sectionSchema || !sectionSchema.properties) {
          return '';
        }

        // 직접 필드인 경우
        if (sectionSchema.properties[field]) {
          return sectionSchema.properties[field].description || '';
        }

        // 중첩 필드인 경우
        // section.field 형식 찾기 (예: productPage.commonAttributes)
        const nestedParts = field.split('.');
        if (nestedParts.length > 1) {
          let current = sectionSchema.properties;

          for (let i = 0; i < nestedParts.length; i++) {
            if (!current[nestedParts[i]] || !current[nestedParts[i]].properties) {
              return '';
            }
            current = current[nestedParts[i]].properties;
          }

          return current.description || '';
        }

        return '';
      } catch (error) {
        console.error('필드 설명 가져오기 오류:', error);
        return '';
      }
    },

    // 필드에 옵션이 있는지 확인
    hasFieldOptions(section, field) {
      if (!this.schema) return false;

      try {
        // 섹션 찾기
        const sectionSchema = this.schema.properties[section];
        if (!sectionSchema || !sectionSchema.properties) {
          return false;
        }

        // 직접 필드인 경우
        if (sectionSchema.properties[field]) {
          return !!(sectionSchema.properties[field].enum ||
            sectionSchema.properties[field].dropdown);
        }

        return false;
      } catch (error) {
        console.error('필드 옵션 확인 오류:', error);
        return false;
      }
    },

    // 필드 옵션 가져오기
    getFieldOptions(section, field) {
      if (!this.schema) return this.getDefaultOptions(section, field);

      try {
        // 섹션 찾기
        const sectionSchema = this.schema.properties[section];
        if (!sectionSchema || !sectionSchema.properties) {
          return this.getDefaultOptions(section, field);
        }

        // 직접 필드인 경우
        if (sectionSchema.properties[field]) {
          return sectionSchema.properties[field].enum ||
            sectionSchema.properties[field].dropdown ||
            this.getDefaultOptions(section, field);
        }

        return this.getDefaultOptions(section, field);
      } catch (error) {
        console.error('필드 옵션 가져오기 오류:', error);
        return this.getDefaultOptions(section, field);
      }
    },

    // 기본 옵션 가져오기
    getDefaultOptions(section, field) {
      const defaultOptions = {
      };

      const key = `${section}.${field}`;
      return defaultOptions[key] || [];
    },

    // 필수 필드인지 확인
    isRequiredField(section, field) {
      if (!this.schema) return false;

      try {
        // 섹션 찾기
        const sectionSchema = this.schema.properties[section];
        if (!sectionSchema) {
          return false;
        }

        // required 배열이 있는지 확인
        if (!sectionSchema.required || !Array.isArray(sectionSchema.required)) {
          return false;
        }

        return sectionSchema.required.includes(field);
      } catch (error) {
        console.error('필수 필드 확인 오류:', error);
        return false;
      }
    },

    // baseData를 jsonDocument 형식으로 변환
    convertToJsonDocument(baseData) {
      // 상품 이름 설정
      let productName = '';
      if (this.product.seo && this.product.seo.productName) {
        productName = this.product.seo.productName;
      } else if (this.product.productName) {
        productName = this.product.productName;
      } else if (this.product.productNameTrans) {
        productName = this.product.productNameTrans;
      }

      // 선택된 옵션이 있으면 옵션명 추가
      if (this.selectedOption && this.selectedOption.korName) {
        productName = `${productName}, ${this.selectedOption.korName}`;
      }

      // 카테고리 경로 설정
      const categoryPath = this.product.rocketRegInfo?.categoryPath || '';

      // jsonDocument 구조
      return {
        startPage: {
          productName: productName,
          categoryPath: categoryPath
        },
        productPage: {
          modelNumber: baseData.modelNumber || productName,
          brand: baseData.brand || '',
          commonAttributes: {
            exposedAttributes: baseData.exposedAttributes || [],
            purchasePrice: baseData.purchasePrice || 0,
            coupangSalePrice: baseData.salePrice || 0,
            msrp: baseData.msrp || 0,
            productBarcode: baseData.barcode || '바코드 없음(쿠팡 바코드 생성 요청)'
          },
          manufacturer: baseData.manufacturer || '',
          businessType: baseData.businessType || '제조사',
          taxationSchema: baseData.taxationType || '과세',
          importType: baseData.importType || '수입대상아님',
          searchTags: this.product.seo?.keywords ? this.product.seo.keywords.join(', ') : '',
          unexposedAttributes: baseData.unexposedAttributes || []
        },
        imagePage: {
          images: {
            mainImage: this.getMainImageUrl(),
            additionalImage: this.getAdditionalImageUrls(),
            labelImages: [
              {
                labelImageType: '제품 한글 표시사항 라벨 또는 도안 이미지',
                labelImageFiles: this.product.koreanLabelImage || ''
              }
            ]
          },
          details: {
            detailedImage: this.getDetailImageUrls(),
            htmlProductDetailContent: this.product.detailHtml || '',
            altText: this.product.seo?.metaDescription || '상품 상세 설명'
          }
        },
        legalPage: {
          kcMarkType: baseData.kcMarkType || '해당사항없음',
          certificates: baseData.certificates || [],
          adCert: '',
          notices: baseData.productNotices ? baseData.productNotices.map(notice => ({
            noticeItemName: notice.name,
            noticeItemValue: notice.value
          })) : []
        },
        logisticsPage: {
          totalSKUsInBox: baseData.totalSkusInBox || this.$store.state.settings[0]?.defaultSettings?.totalSKUsInBox || 1,
          daysToExpiration: baseData.daysToExpiration || 0,
          specialHandlingReason: baseData.specialHandlingReason || '해당사항없음',
          skuUnitBoxWeight: baseData.skuUnitBoxWeight || '1000',
          skuUnitBoxDimension: baseData.skuUnitBoxDimension || '300*300*50',
          fashionYear: new Date().getFullYear().toString(),
          fashionSeason: baseData.fashionSeason || '사계절'
        },
        sourcingPage: {
          sourcingChannelType: '',
          sourcingChannelId: ''
        }
      };
    },

    // 필요한 필드가 모두 있는지 확인하고 누락된 경우 기본값 설정
    ensureRequiredFields(data) {
      // 기본 빈 구조
      const emptyDoc = this.getEmptyJsonDocument();

      // 각 섹션이 없는 경우 빈 섹션 추가
      for (const sectionKey in emptyDoc) {
        if (!data[sectionKey]) {
          data[sectionKey] = emptyDoc[sectionKey];
        } else {
          // 중첩 객체 처리
          for (const subKey in emptyDoc[sectionKey]) {
            if (!data[sectionKey][subKey]) {
              data[sectionKey][subKey] = emptyDoc[sectionKey][subKey];
            } else if (typeof emptyDoc[sectionKey][subKey] === 'object' && !Array.isArray(emptyDoc[sectionKey][subKey])) {
              // 중첩된 객체의 누락된 필드 처리
              for (const nestedKey in emptyDoc[sectionKey][subKey]) {
                if (!data[sectionKey][subKey][nestedKey]) {
                  data[sectionKey][subKey][nestedKey] = emptyDoc[sectionKey][subKey][nestedKey];
                }
              }
            }
          }
        }
      }

      return data;
    },

    // 빈 jsonDocument 구조 생성
    getEmptyJsonDocument() {
      return {
        startPage: {
          productName: '',
          categoryPath: ''
        },
        productPage: {
          modelNumber: '',
          brand: '',
          commonAttributes: {
            exposedAttributes: [],
            purchasePrice: 0,
            coupangSalePrice: 0,
            msrp: 0,
            productBarcode: '바코드 없음(쿠팡 바코드 생성 요청)'
          },
          manufacturer: '',
          businessType: '제조사',
          taxationSchema: '과세',
          importType: '수입대상아님',
          searchTags: '',
          unexposedAttributes: []
        },
        imagePage: {
          images: {
            mainImage: '',
            additionalImage: '',
            labelImages: []
          },
          details: {
            detailedImage: '',
            htmlProductDetailContent: '',
            altText: ''
          }
        },
        legalPage: {
          kcMarkType: '해당사항없음',
          certificates: [],
          adCert: '',
          notices: []
        },
        logisticsPage: {
          totalSKUsInBox: this.$store.state.settings[0]?.defaultSettings?.totalSKUsInBox || 1,
          daysToExpiration: 0,
          specialHandlingReason: '해당사항없음',
          skuUnitBoxWeight: '1000',
          skuUnitBoxDimension: '300*300*50',
          fashionYear: new Date().getFullYear().toString(),
          fashionSeason: '사계절'
        },
        sourcingPage: {
          sourcingChannelType: '',
          sourcingChannelId: ''
        }
      };
    },

    // 메인 이미지 URL 가져오기
    getMainImageUrl() {
      // 선택된 옵션의 이미지
      if (this.selectedOption && this.selectedOption.mainImageStatus) {
        return this.selectedOption.mainImageStatus.processedImage ||
          this.selectedOption.mainImageStatus.image || '';
      }

      // 첫 번째 옵션의 이미지
      if (this.product.options && this.product.options.length > 0) {
        const firstOption = this.product.options[0];
        if (firstOption.mainImageStatus) {
          return firstOption.mainImageStatus.processedImage ||
            firstOption.mainImageStatus.image || '';
        }
      }

      return '';
    },

    // 추가 이미지 URL 가져오기
    getAdditionalImageUrls() {
      if (this.product.additionalImages && this.product.additionalImages.length > 0) {
        return this.product.additionalImages.map(img =>
          typeof img === 'object' ? (img.processedUrl || img.url || "") : img
        ).join(',');
      }

      return '';
    },

    // 상세 이미지 URL 가져오기
    getDetailImageUrls() {
      if (this.product.detailImages && this.product.detailImages.length > 0) {
        return this.product.detailImages.map(img =>
          typeof img === 'object' ? (img.processedUrl || img.url || "") : img
        ).join(',');
      }

      return '';
    },

    // docData를 quotationService가 처리할 수 있는 형태로 변환
    convertDocToQuotationData() {
      return {
        // 기본 정보
        modelNumber: this.docData.productPage.modelNumber,
        brand: this.docData.productPage.brand,
        manufacturer: this.docData.productPage.manufacturer,
        businessType: this.docData.productPage.businessType,
        taxationType: this.docData.productPage.taxationSchema,
        importType: this.docData.productPage.importType,

        // 가격 정보
        purchasePrice: this.docData.productPage.commonAttributes.purchasePrice,
        salePrice: this.docData.productPage.commonAttributes.coupangSalePrice,
        msrp: this.docData.productPage.commonAttributes.msrp,
        barcode: this.docData.productPage.commonAttributes.productBarcode,

        // 속성 정보
        exposedAttributes: this.docData.productPage.commonAttributes.exposedAttributes,
        unexposedAttributes: this.docData.productPage.unexposedAttributes,

        // 법적 정보
        kcMarkType: this.docData.legalPage.kcMarkType,
        certificates: this.docData.legalPage.certificates.map(cert => ({
          name: cert.certificateName,
          value: cert.certificateValue
        })),
        productNotices: this.docData.legalPage.notices.map(notice => ({
          name: notice.noticeItemName,
          value: notice.noticeItemValue
        })),

        // 물류 정보
        totalSkusInBox: this.docData.logisticsPage.totalSKUsInBox,
        daysToExpiration: this.docData.logisticsPage.daysToExpiration,
        specialHandlingReason: this.docData.logisticsPage.specialHandlingReason,
        skuUnitBoxWeight: this.docData.logisticsPage.skuUnitBoxWeight,
        skuUnitBoxDimension: this.docData.logisticsPage.skuUnitBoxDimension,
        fashionSeason: this.docData.logisticsPage.fashionSeason
      };
    },

    // 견적서 저장
    async saveQuotation() {
      try {
        // 로딩 상태 시작
        this.setProcessing({
          isProcessing: true,
          message: '등록 세부정보 저장 중...'
        });

        // 선택된 옵션 결정
        const targetOption = this.selectedOption ||
          (this.product.options && this.product.options.length > 0 ?
            this.product.options[0] : null);

        console.log('저장 중:', this.product.registrationId);
        console.log('선택된 옵션:', targetOption ? targetOption.optionNumber : 'none');

        // docData를 quotationService가 처리할 수 있는 형태로 변환
        const quotationData = this.convertDocToQuotationData();

        // quotationService의 processManualQuotation 메서드 호출하여 데이터 처리
        await quotationService.processManualQuotation(this.product, quotationData, targetOption);

        // 로딩 상태 종료
        this.setProcessing({ isProcessing: false });

        // 성공 메시지 표시
        this.$bvToast.toast('등록 세부정보가 저장되었습니다.', {
          title: '성공',
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-bottom-right'
        });
      } catch (error) {
        console.error('등록 세부정보 저장 중 오류:', error);

        // 로딩 상태 종료
        this.setProcessing({
          isProcessing: false,
          message: '오류: ' + error.message
        });

        // 오류 메시지 표시
        this.$bvToast.toast('등록 세부정보 저장 중 오류가 발생했습니다: ' + error.message, {
          title: '오류',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-right'
        });
      }
    },

    // 견적서 초기화
    resetQuotation() {
      this.$bvModal.msgBoxConfirm('등록 세부정보를 초기화하시겠습니까?', {
        title: '확인',
        okVariant: 'danger',
        okTitle: '초기화',
        cancelTitle: '취소',
        hideHeaderClose: false,
        centered: true
      })
        .then(confirmed => {
          if (confirmed) {
            this.initializeData();

            this.$bvToast.toast('등록 세부정보가 초기화되었습니다.', {
              title: '알림',
              variant: 'info',
              solid: true,
              toaster: 'b-toaster-bottom-right'
            });
          }
        });
    }
  }
}
</script>
<style scoped>
.quotation-edit {
  padding: 15px;
  max-width: 1200px;
  margin: 0 auto;
}

.loading-container {
  text-align: center;
  padding: 40px 0;
}

.section {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.form-group label {
  font-weight: 500;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

input.form-control,
select.form-control,
textarea.form-control {
  font-size: 0.9rem;
  padding: 0.375rem 0.5rem;
}

input:read-only {
  background-color: #f9f9f9;
}

.form-text.text-muted {
  font-size: 0.8rem;
  line-height: 1.2;
}
</style>