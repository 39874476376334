<template>
  <div class="korean-label-edit">
    <h3 class="section-title">한글 표시사항 작업</h3>

    <!-- 로딩 중일 때 표시 -->
    <div v-if="isProcessing" class="loading-container">
      <b-spinner variant="primary"></b-spinner>
      <p>{{ progressMessage || '한글 표시사항 작업 중...' }}</p>
    </div>

    <!-- 작업 완료 후 표시 -->
    <div v-else-if="koreanLabelImage" class="completed-work-container">
      <h4>한글 표시사항 정보</h4>

      <div class="card mb-4">
        <div class="card-header bg-light d-flex justify-content-between align-items-center">
          <span>표시 정보 결과</span>
          <div>
            <b-button variant="outline-primary" size="sm" @click="downloadLabelImage">
              <i class="las la-download"></i> 이미지 다운로드
            </b-button>
            <b-button variant="outline-secondary" size="sm" @click="resetLabels" class="ml-2">
              <i class="las la-edit"></i> 재작성
            </b-button>
          </div>
        </div>
        <div class="card-body">
          <img :src="koreanLabelImage" alt="한글 표시사항" class="img-fluid">
        </div>
      </div>

      <!-- 다음 단계 버튼 -->
      <!-- <div class="navigation-buttons mt-4 text-center">
        <b-button variant="primary" @click="goToNextStep">
          다음 단계로 (가격 설정)
        </b-button>
      </div> -->
    </div>

    <!-- 초기 상태 (작업 전) -->
    <div v-else class="initial-state-container">
      <p class="text-center mb-4">
        제품에 필요한 한글 표시사항을 작성해야 합니다. AI를 활용하여 자동으로 생성하거나 직접 입력할 수 있습니다.
      </p>

      <div class="options-container">
        <div class="manual-input-container p-4">
          <p class="mb-4">필요한 한글 표시사항을 직접 입력할 수 있습니다.</p>

          <div class="form-group">
            <label>제품명</label>
            <input v-model="productName" type="text" class="form-control" placeholder="제품명을 입력하세요" />
          </div>

          <div class="form-group">
            <label>수입 및 판매원</label>
            <input v-model="manufacturer" type="text" class="form-control" placeholder="수입 및 판매원 정보를 입력하세요" />
          </div>

          <div class="form-group">
            <label>제조원</label>
            <input v-model="origin" type="text" class="form-control" placeholder="제조원 정보를 입력하세요" />
          </div>

          <div class="form-group">
            <label>제조국</label>
            <input v-model="madein" type="text" class="form-control" placeholder="제조국을 입력하세요" />
          </div>

          <div class="form-group">
            <label>내용량</label>
            <input v-model="content" type="text" class="form-control" placeholder="내용량을 입력하세요" />
          </div>

          <div class="form-group">
            <label>원료명 및 성분명(재질)</label>
            <input v-model="material" type="text" class="form-control" placeholder="원료명 및 성분명(재질)을 입력하세요" />
          </div>

          <div class="form-group">
            <label>상품 유형</label>
            <input v-model="type" type="text" class="form-control" placeholder="상품 유형을 입력하세요" />
          </div>

          <div class="form-group">
            <label>사용 시 주의사항</label>
            <input v-model="caution" type="text" class="form-control" placeholder="사용 시 주의사항을 입력하세요" />
          </div>

          <div class="form-group">
            <label>사용 기준</label>
            <input v-model="standard" type="text" class="form-control" placeholder="사용 기준을 입력하세요" />
          </div>

          <!-- 수정: 저장 버튼 추가 -->
          <div class="action-button-container text-center mt-4 d-flex justify-content-center">
            <b-button variant="success" class="mr-3" @click="saveManualLabels" :disabled="isProcessing">
              <i class="las la-save"></i> 정보 저장하기
            </b-button>
            <b-button variant="primary" @click="startKoreanLabelProcessing" :disabled="isProcessing">
              <i class="las la-language"></i> 한글 표시사항 생성 시작
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import koreanLabelService from '../../services/works/koreanLabelService';

export default {
  name: "KoreanLabelEdit",
  data() {
    return {
      // 로딩 상태
      loading: false,
      // 한글 표시사항
      productName: '',
      manufacturer: '',
      origin: '',
      madein: '',
      content: '',
      material: '',
      type: '',
      caution: '',
      standard: '',
    };
  },
  computed: {
    // Vuex 상태 매핑
    ...mapState({
      selectedProduct: state => state.selectedRocketProduct,
      defaultSettings: state => state.settings[0]?.defaultSettings,
      koreanLabelImage: state => state.selectedRocketProduct?.koreanLabelImage,
      isProcessing: state => state.processing,
      progressMessage: state => state.progressMessage,
    }),
  },
  watch: {
    // 선택된 상품의 koreanLabels 데이터 변경 감시
    'selectedProduct.koreanLabels': {
      handler(newLabels) {
        if (newLabels) {
          console.log('한글 라벨 데이터 변경 감지:', newLabels);

          // 변경된 필드만 업데이트 (기존 값과 다른 경우만)
          if (newLabels.content && this.content !== newLabels.content) {
            this.content = newLabels.content;
            console.log('내용량 업데이트:', this.content);
          }

          if (newLabels.material && this.material !== newLabels.material) {
            this.material = newLabels.material;
            console.log('원료명 업데이트:', this.material);
          }

          if (newLabels.type && this.type !== newLabels.type) {
            this.type = newLabels.type;
            console.log('상품유형 업데이트:', this.type);
          }

          // 기타 필드들도 필요시 업데이트
          if (newLabels.productName && this.productName !== newLabels.productName) {
            this.productName = newLabels.productName;
          }

          if (newLabels.manufacturer && this.manufacturer !== newLabels.manufacturer) {
            this.manufacturer = newLabels.manufacturer;
          }

          if (newLabels.origin && this.origin !== newLabels.origin) {
            this.origin = newLabels.origin;
          }

          if (newLabels.madein && this.madein !== newLabels.madein) {
            this.madein = newLabels.madein;
          }

          if (newLabels.caution && this.caution !== newLabels.caution) {
            this.caution = newLabels.caution;
          }

          if (newLabels.standard && this.standard !== newLabels.standard) {
            this.standard = newLabels.standard;
          }
        }
      },
      deep: true,
      immediate: true  // 컴포넌트 마운트 시에도 즉시 실행
    }
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    ...mapActions([
      'updateRocketProduct',
      'setProcessing',
      'requestKoreanLabelData',
    ]),
    ...mapMutations([
      'SET_SELECTED_ROCKET_PRODUCT',
    ]),
    initializeData() {
      if (this.selectedProduct) {
        // SEO 정보가 있으면 SEO된 상품명 사용, 없으면 기본 상품명 사용
        this.productName = this.selectedProduct.seo?.productName || this.selectedProduct.productName || '';
        this.manufacturer = this.defaultSettings?.manufacturer || '';
        this.origin = this.selectedProduct.brand || '';
        this.madein = '중국';
        this.caution = '용도 외에 사용금지. 파손및화기주의';
        this.standard = '14세이상';

        // 기존 한글 표시사항 정보가 있으면 로드
        if (this.selectedProduct.koreanLabels) {
          this.content = this.selectedProduct.koreanLabels.content || '';
          this.material = this.selectedProduct.koreanLabels.material || '';
          this.type = this.selectedProduct.koreanLabels.type || '';
        }
      }
    },

    // 수정: 수동으로 입력한 정보 저장
    async saveManualLabels() {
      try {
        // 로딩 상태 시작
        this.setProcessing({
          isProcessing: true,
          message: '한글 표시사항 저장 중...'
        });

        // 라벨 정보 객체 생성
        const labels = {
          productName: this.productName,
          manufacturer: this.manufacturer,
          origin: this.origin,
          madein: this.madein,
          content: this.content,
          material: this.material,
          type: this.type,
          caution: this.caution,
          standard: this.standard
        };

        // 상품 복제 (깊은 복사)
        const updatedProduct = JSON.parse(JSON.stringify(this.selectedProduct));

        // 한글 표시사항 정보 업데이트
        updatedProduct.koreanLabels = labels;

        // 이미지가 없으면 이미지 생성
        if (!updatedProduct.koreanLabelImage) {
          const imageBase64 = await koreanLabelService.generateLabelImage(labels);
          const imageUrl = await koreanLabelService.uploadLabelImage(updatedProduct, imageBase64);
          updatedProduct.koreanLabelImage = imageUrl;
        }

        // 상태 업데이트
        updatedProduct.koreanLabelStatus = 'completed';

        // 각 옵션의 coupangDoc 업데이트
        if (updatedProduct.options && updatedProduct.options.length > 0) {
          for (const option of updatedProduct.options) {
            if (option.coupangDoc && option.coupangDoc.jsonDocument &&
              option.coupangDoc.jsonDocument[0] &&
              option.coupangDoc.jsonDocument[0].imagePage &&
              option.coupangDoc.jsonDocument[0].imagePage.images &&
              option.coupangDoc.jsonDocument[0].imagePage.images.labelImages) {

              // 한글 표시사항 라벨 이미지 업데이트
              const labelImage = option.coupangDoc.jsonDocument[0].imagePage.images.labelImages.find(
                img => img.labelImageType === '제품 한글 표시사항 라벨 또는 도안 이미지'
              );

              if (labelImage) {
                labelImage.labelImageFiles = updatedProduct.koreanLabelImage;
              } else {
                // 없으면 새로 추가
                option.coupangDoc.jsonDocument[0].imagePage.images.labelImages.push({
                  labelImageType: '제품 한글 표시사항 라벨 또는 도안 이미지',
                  labelImageFiles: updatedProduct.koreanLabelImage
                });
              }
            }
          }
        }

        // Vuex 상태 업데이트
        await this.updateRocketProduct(updatedProduct);

        this.setProcessing({ isProcessing: false });
        alert('한글 표시사항이 저장되었습니다.');

      } catch (error) {
        console.error('한글 표시사항 저장 중 오류:', error);
        this.setProcessing({ isProcessing: false });
        alert('한글 표시사항 저장 중 오류가 발생했습니다.');
      }
    },

    // 한글 표시사항 작업 시작
    async startKoreanLabelProcessing() {
      try {
        // 로딩 상태 시작
        this.setProcessing({
          isProcessing: true,
          message: '한글 표시사항 생성 요청 중...'
        });

        // Vuex 액션을 직접 호출
        await this.requestKoreanLabelData({
          product: this.selectedProduct,
        });
      } catch (error) {
        console.error('한글 표시사항 생성 중 오류:', error);
        this.setProcessing({ isProcessing: false });
        alert('한글 표시사항 생성 중 오류가 발생했습니다.');
      }
    },
    downloadLabelImage() {
      const link = document.createElement('a');
      link.href = this.koreanLabelImage;
      link.download = 'korean_label.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 이미지 초기화
    resetLabels() {
      if (confirm('이미지를 재작성 하시겠습니까?')) {
        const product = JSON.parse(JSON.stringify(this.selectedProduct));
        product.koreanLabelImage = null
        this.SET_SELECTED_ROCKET_PRODUCT(product);
      }
    },
    // 다음 단계로 이동
    // goToNextStep() {
    //   this.$emit('next');
    // }
  }
}
</script>