<template>
    <b-modal v-model="show" :title="getProductTitle()" modal-class="reg-detail-modal" size="xl" hide-footer
        no-enforce-focus>
        <div v-if="selectedProduct">
            <CDataTable :headers="optionHeaders" :items="selectedProduct.options" :items-per-page="10"
                :pagination="true" hover sorter itemKey="id">

                <!-- 옵션명 -->
                <template v-slot:optionName="{ item }">
                    <div>
                        <!-- SEO 설정이 완료되고 korName이 있는 경우 한국어 옵션명 표시 -->
                        <div v-if="isSeoCompleted && item.korName" class="font-weight-bold">
                            {{ item.korName }}
                        </div>
                        <!-- 그렇지 않으면 기존 방식으로 옵션 정보 표시 -->
                        <div v-else>
                            <div class="d-flex flex-column">
                                <div v-if="item.option1">
                                    <span class="font-weight-medium">옵션1: </span>
                                    <span>{{ item.option1.nameTrans || item.option1.name }}</span>
                                </div>
                                <div v-if="item.option2">
                                    <span class="font-weight-medium">옵션2: </span>
                                    <span>{{ item.option2.nameTrans || item.option2.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <!-- 옵션번호 -->
                <template v-slot:optionNumber="{ item }">
                    <div class="text-center">
                        {{ item.optionNumber }}
                    </div>
                </template>

                <!-- 대표이미지(옵션이미지) 상태 -->
                <template v-slot:mainImageStatus="{ item }">
                    <div class="text-center">
                        <div class="mb-2 option-image-container">
                            <div v-if="item.mainImageStatus && (item.mainImageStatus.processedImage || item.mainImageStatus.image)"
                                class="option-image-preview">
                                <img :src="item.mainImageStatus.processedImage || item.mainImageStatus.image"
                                    @error="handleImageError" @load="handleImageLoad" class="img-fluid"
                                    :alt="`옵션 ${item.optionNumber} 이미지`">
                            </div>
                            <div v-else class="no-image-placeholder">
                                <i class="ri-image-line"></i>
                            </div>
                        </div>
                        <b-badge
                            :variant="getStatusVariant(item.mainImageStatus ? item.mainImageStatus.status : 'pending')"
                            class="badge-pill" role="button" @click="showWorkDetails(item, 'mainImage')">
                            {{ getStatusText(item.mainImageStatus ? item.mainImageStatus.status : 'pending') }}
                        </b-badge>
                    </div>
                </template>

                <!-- 추가이미지(메인이미지들) 상태 -->
                <template v-slot:additionalImageStatus="{ item }">
                    <div class="text-center">
                        <div class="mb-2 d-flex justify-content-center gap-1">
                            <img v-for="(img, idx) in item.additionalImageStatus.images.slice(0, 3)" :key="idx"
                                :src="img" class="rounded" style="width: 30px; height: 30px; object-fit: cover;">
                            <span v-if="item.additionalImageStatus.images.length > 3">
                                +{{ item.additionalImageStatus.images.length - 3 }}
                            </span>
                        </div>
                        <b-badge :variant="getStatusVariant(item.additionalImageStatus.status)" class="badge-pill"
                            role="button" @click="showWorkDetails(item, 'additionalImage')">
                            {{ getStatusText(item.additionalImageStatus.status) }}
                        </b-badge>
                    </div>
                </template>

                <!-- 상세이미지 상태 -->
                <template v-slot:detailImageStatus="{ item }">
                    <div class="text-center">
                        <div class="mb-2">
                            <small>{{ item.detailImageStatus.images.length }}개 이미지</small>
                        </div>
                        <b-badge :variant="getStatusVariant(item.detailImageStatus.status)" class="badge-pill"
                            role="button" @click="showWorkDetails(item, 'detailImage')">
                            {{ getStatusText(item.detailImageStatus.status) }}
                        </b-badge>
                    </div>
                </template>

                <template v-slot:sizeChartStatus="{ item }">
                    <div class="text-center">
                        <b-badge
                            :variant="getStatusVariant(isSizeChartRequired(selectedProduct) ? item.sizeChartStatus : 'disabled')"
                            class="badge-pill" role="button" @click="showWorkDetails(item, 'sizeChart')">
                            {{ getStatusText(isSizeChartRequired(selectedProduct) ? item.sizeChartStatus : 'disabled')
                            }}
                        </b-badge>
                    </div>
                </template>

                <!-- 한글표시사항 상태 -->
                <template v-slot:koreanLabelStatus="{ item }">
                    <div class="text-center">
                        <b-badge :variant="getStatusVariant(item.koreanLabelStatus)" class="badge-pill" role="button"
                            @click="showWorkDetails(item, 'koreanLabel')">
                            {{ getStatusText(item.koreanLabelStatus) }}
                        </b-badge>
                    </div>
                </template>

                <!-- 가격설정 상태 -->
                <template v-slot:pricingStatus="{ item }">
                    <div class="text-center">
                        <b-badge :variant="getStatusVariant(item.pricingStatus)" class="badge-pill" role="button"
                            @click="showWorkDetails(item, 'pricing')">
                            {{ getStatusText(item.pricingStatus) }}
                        </b-badge>
                    </div>
                </template>

                <!-- SEO설정 상태 -->
                <template v-slot:seoStatus="{ item }">
                    <div class="text-center">
                        <b-badge :variant="getStatusVariant(item.seoStatus)" class="badge-pill" role="button"
                            @click="showWorkDetails(item, 'seo')">
                            {{ getStatusText(item.seoStatus) }}
                        </b-badge>
                    </div>
                </template>

                <!-- 견적서 상태 -->
                <template v-slot:quotationStatus="{ item }">
                    <div class="text-center">
                        <b-badge :variant="getStatusVariant(item.quotationStatus)" class="badge-pill" role="button"
                            @click="showWorkDetails(item, 'quotation')">
                            {{ getStatusText(item.quotationStatus) }}
                        </b-badge>
                    </div>
                </template>

                <!-- 재고/가격 -->
                <template v-slot:stockAndPrice="{ item }">
                    <div class="text-center">
                        <div :class="{ 'text-danger': item.stock < 50 }">
                            재고: {{ item.stock }}개
                        </div>
                        <div class="mt-1">
                            <div>¥{{ item.price }} (CNY)</div>
                            <div>{{ formatNumber(item.supplyPrice) }}원 (공급가)</div>
                            <div>{{ formatNumber(item.sellingPrice) }}원 (판매가)</div>
                            <div v-if="item.bundleQuantity > 1" class="mt-1 text-primary">
                                {{ item.bundleQuantity }}개 번들구성
                            </div>
                        </div>
                    </div>
                </template>

                <!-- 관리 버튼 -->
                <template v-slot:actions="{ item }">
                    <div class="d-flex justify-content-center">
                        <b-button size="sm" variant="outline-danger" @click="deleteOption(selectedProduct, item)"
                            v-b-tooltip.hover title="옵션 삭제">
                            <i class="las la-trash-alt"></i>
                        </b-button>
                    </div>
                </template>
            </CDataTable>
        </div>
    </b-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import CDataTable from '@/components/common/CDataTable';

export default {
    name: 'RocketOptionsModal',
    components: {
        CDataTable
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            optionHeaders: [
                {
                    text: '옵션번호',
                    value: 'optionNumber',
                    align: 'center',
                    sortable: true,
                    width: 120,
                    isSlot: true
                },
                {
                    text: '옵션명',
                    value: 'optionName',
                    align: 'left',
                    sortable: true,
                    width: 200, // 두 컬럼을 합쳤으므로 너비 증가
                    isSlot: true
                },
                {
                    text: 'SEO설정',
                    value: 'seoStatus',
                    align: 'center',
                    sortable: true,
                    width: 100,
                    isSlot: true
                },
                {
                    text: '가격설정',
                    value: 'pricingStatus',
                    align: 'center',
                    sortable: true,
                    width: 100,
                    isSlot: true
                },
                {
                    text: '대표이미지',
                    value: 'mainImageStatus',
                    align: 'center',
                    sortable: true,
                    width: 100,
                    isSlot: true
                },
                {
                    text: '추가이미지',
                    value: 'additionalImageStatus',
                    align: 'center',
                    sortable: true,
                    width: 100,
                    isSlot: true
                },
                {
                    text: '상세이미지',
                    value: 'detailImageStatus',
                    align: 'center',
                    sortable: true,
                    width: 100,
                    isSlot: true
                },
                {
                    text: '사이즈표',
                    value: 'sizeChartStatus',
                    align: 'center',
                    sortable: true,
                    width: 100,
                    isSlot: true
                },
                {
                    text: '한글표시사항',
                    value: 'koreanLabelStatus',
                    align: 'center',
                    sortable: true,
                    width: 100,
                    isSlot: true
                },
                {
                    text: '견적서',
                    value: 'quotationStatus',
                    align: 'center',
                    sortable: true,
                    width: 100,
                    isSlot: true
                },
                {
                    text: '재고/가격',
                    value: 'stockAndPrice',
                    align: 'center',
                    sortable: true,
                    width: 120,
                    isSlot: true
                },
                {
                    text: '관리',
                    value: 'actions',
                    align: 'center',
                    sortable: false,
                    width: 120,
                    isSlot: true
                }
            ]
        };
    },
    computed: {
        ...mapState({
            selectedProduct: state => state.selectedRocketProduct
        }),

        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        // SEO 설정 완료 여부 확인
        isSeoCompleted() {
            return this.selectedProduct &&
                this.selectedProduct.seoStatus === 'completed';
        }
    },
    methods: {
        ...mapMutations([
            'SET_SELECTED_OPTION',
            'SET_SELECTED_WORK_TYPE'
        ]),
        isSizeChartRequired(product) {
            try {
                if (product && product.rocketRegInfo && product.rocketRegInfo.schemaString) {
                    const schema = JSON.parse(product.rocketRegInfo.schemaString);

                    if (schema.properties &&
                        schema.properties.imagePage &&
                        schema.properties.imagePage.properties &&
                        schema.properties.imagePage.properties.images &&
                        schema.properties.imagePage.properties.images.properties &&
                        schema.properties.imagePage.properties.images.required) {

                        return schema.properties.imagePage.properties.images.required.includes('sizeChartImage');
                    }
                }
            } catch (error) {
                console.error('스키마 파싱 오류:', error);
            }
            return false;
        },

        // 상태 표시 관련 메서드
        getStatusVariant(status) {
            switch (status) {
                case 'completed':
                    return 'success';
                case 'partially_completed':
                    return 'info';
                case 'processing':
                    return 'primary';
                case 'pending':
                    return 'secondary';
                case 'error':
                    return 'danger';
                case 'disabled':
                    return 'light';
                default:
                    return 'info';
            }
        },

        getStatusText(status) {
            const texts = {
                completed: '완료',
                partially_completed: '일부완료',
                processing: '진행중',
                pending: '대기',
                error: '오류',
                disabled: '미사용'
            };
            return texts[status] || '대기';
        },

        // 작업 상세 보기 및 위자드 열기
        showWorkDetails(option, workType) {
            // 선택된 옵션과 작업 유형 저장
            this.SET_SELECTED_OPTION(option);
            this.SET_SELECTED_WORK_TYPE(workType);
            console.log('option클릭', option)
            console.log(this.selectedProduct)
            // 작업 위자드 모달 열기 이벤트 발생
            // this.$nextTick(() => {
            this.$emit('open-wizard');
            // });
        },

        // 옵션 삭제
        deleteOption(product, option) {
            if (!option || !product) {
                console.error('삭제할 옵션 또는 상품 정보가 없습니다.');
                return;
            }

            // 옵션에 쿠팡 문서 ID가 있는지 확인
            const hasCoupangDoc = option.coupangDoc && option.coupangDoc.docId;

            if (confirm(`이 옵션${hasCoupangDoc ? '과 관련 쿠팡 문서' : ''}를 삭제하시겠습니까?`)) {
                // 쿠팡 문서가 있는 경우 확장 프로그램에 삭제 요청
                if (hasCoupangDoc) {
                    window.postMessage({
                        type: 'DELETE_ROCKET_REG_DOC',
                        id: `delete_option_${option.optionNumber}_${Date.now()}`,
                        data: {
                            docId: option.coupangDoc.docId,
                            suppliercode: this.$store.state.user.suppliercode
                        }
                    }, '*');

                    console.log(`옵션 ${option.optionNumber}의 쿠팡 문서 삭제 요청 (docId: ${option.coupangDoc.docId})`);
                }

                // 옵션 목록에서 삭제
                const index = product.options.findIndex(o => o.id === option.id);
                if (index !== -1) {
                    const updatedOptions = [...product.options];
                    updatedOptions.splice(index, 1);

                    // 상품 업데이트
                    const updatedProduct = {
                        ...product,
                        options: updatedOptions
                    };

                    // 상품 업데이트 처리 이벤트 발생
                    this.$emit('option-deleted', updatedProduct);

                    // 삭제 완료 메시지
                    this.$bvToast.toast(`옵션 ${option.optionNumber}이(가) 삭제되었습니다.`, {
                        title: '삭제 완료',
                        variant: 'success',
                        solid: true,
                        toaster: 'b-toaster-bottom-right'
                    });
                }
            }
        },

        // 이미지 관련 메서드
        handleImageError() {
            console.warn('이미지 로드 실패');
        },

        handleImageLoad() {
            // 이미지 로드 성공 시 필요한 처리
        },

        // 숫자 포맷팅
        formatNumber(value) {
            return new Intl.NumberFormat().format(value);
        },
        getProductTitle() {
            if (this.selectedProduct) {
                // SEO 설정이 완료된 경우 SEO 상품명 사용
                if (this.selectedProduct.seoStatus === 'completed' && this.selectedProduct.seo && this.selectedProduct.seo.productName) {
                    return this.selectedProduct.seo.productName;
                }
                // 아니면 기존 상품명 사용
                return this.selectedProduct.productName || this.selectedProduct.productNameTrans;
            }
            return '옵션 정보';
        }
    }
};
</script>

<style scoped>
.option-image-preview {
    position: relative;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.option-image-preview:hover {
    transform: scale(1.1);
    z-index: 1;
}

.no-image-placeholder {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    border: 1px dashed #dee2e6;
    border-radius: 4px;
    margin: 0 auto;
}

.no-image-placeholder i {
    font-size: 24px;
    color: #adb5bd;
}
</style>