<template>
  <div class="additional-image-edit">
    <!-- 로딩 중일 때 표시 -->
    <div v-if="loading" class="loading-container">
      <b-spinner variant="primary"></b-spinner>
      <p>{{ progressMessage || '추가 이미지 작업 중...' }}</p>
    </div>
    <!-- 미리보기 모드 UI 수정 -->
    <div v-if="isPreviewMode" class="preview-overlay">
      <div class="preview-scroll-container">
        <div v-if="processedImages.length > 0" class="images-list">
          <div v-for="(image, index) in processedImages" :key="`preview-${index}`" class="preview-image-item">
            <div class="image-number">{{ index + 1 }}</div>
            <img :src="image.url" :alt="`추가 이미지 ${index + 1}`" class="preview-image">
          </div>
        </div>
        <div v-else class="no-images-placeholder">
          <i class="las la-images"></i>
          <p>선택된 추가 이미지가 없습니다</p>
        </div>
      </div>
      <!-- 닫기 버튼 -->
      <button class="close-preview-btn" @click="isPreviewMode = false">
        <i class="las la-times"></i>
      </button>
    </div>
    <!-- 이미지 편집 화면 -->
    <div v-else-if="!isPreviewMode" class="image-editor-layout">
      <!-- 좌측: 이미지 선택 패널 -->
      <div class="image-selector-panel">
        <div class="panel-header">
          <h5 class="panel-title">추가 이미지 선택</h5>
          <b-button variant="outline-primary" size="sm" @click="showImageUpload" title="이미지 업로드">
            <i class="las la-upload"></i>
          </b-button>
        </div>

        <!-- 이미지 탭 -->
        <div class="image-tabs">
          <b-nav tabs>
            <b-nav-item :active="activeTab === 'main'" @click="activeTab = 'main'">메인이미지</b-nav-item>
            <b-nav-item :active="activeTab === 'processed'" @click="activeTab = 'processed'">작업이미지</b-nav-item>
            <b-nav-item :active="activeTab === 'upload'" @click="activeTab = 'upload'">업로드</b-nav-item>
          </b-nav>
        </div>

        <!-- 이미지 목록 -->
        <div class="image-gallery">
          <!-- 메인 이미지 탭 -->
          <div v-if="activeTab === 'main'" class="image-grid">
            <div v-if="availableMainImages.length > 0">
              <div v-for="(image, index) in availableMainImages" :key="`main-${index}`" class="image-item"
                :class="{ selected: selectedImageType === 'main' && selectedImageIndex === index }"
                @click="selectImage('main', index, image)">
                <img :src="image" alt="메인 이미지" class="img-fluid">
                <div class="image-overlay">
                  <i class="las la-check"></i>
                </div>
              </div>
            </div>
            <div v-else class="empty-gallery">
              <i class="las la-images"></i>
              <p>처리할 추가 이미지가 없습니다</p>
            </div>
          </div>

          <!-- 작업 완료된 이미지 탭 -->
          <div v-if="activeTab === 'processed'" class="image-grid">
            <div v-if="processedImages.length > 0">
              <div v-for="(image, index) in processedImages" :key="`processed-${index}`" class="image-item"
                :class="{ selected: selectedImageType === 'processed' && selectedImageIndex === index }"
                @click="selectProcessedImage(index, image)">
                <img :src="image.url" alt="작업된 이미지" class="img-fluid">
                <div class="image-overlay">
                  <i class="las la-check"></i>
                </div>
                <div class="image-label">
                  <span class="text-removed-badge" v-if="image.textRemoved">텍스트 제거됨</span>
                  <span v-else>작업됨</span>
                </div>
              </div>
            </div>
            <div v-else class="empty-gallery">
              <i class="las la-images"></i>
              <p>작업이 완료된 이미지가 없습니다</p>
              <p class="small text-muted">이미지를 선택한 후 '글자 삭제' 버튼을 눌러 작업을 진행해보세요</p>
            </div>
          </div>

          <!-- 업로드 탭 -->
          <div v-if="activeTab === 'upload'" class="upload-container">
            <div class="upload-area">
              <b-form-file v-model="uploadFiles" :state="Boolean(uploadFiles)" placeholder="이미지 파일을 선택하세요"
                drop-placeholder="파일을 여기에 놓으세요" accept="image/*" multiple class="mb-3"></b-form-file>

              <b-button variant="primary" size="sm" @click="handleUploadFiles"
                :disabled="!uploadFiles || uploadFiles.length === 0">
                업로드 및 추가
              </b-button>
            </div>

            <div v-if="uploadFiles && uploadFiles.length > 0" class="file-preview">
              <h6>선택된 파일 ({{ uploadFiles.length }}개)</h6>
              <ul class="file-list">
                <li v-for="(file, index) in uploadFiles" :key="`file-${index}`">
                  {{ file.name }} ({{ formatFileSize(file.size) }})
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- 이미지 편집 옵션 -->
        <div class="image-options">
          <div class="text-remove-option">
            <b-button variant="danger" size="sm" class="w-100 mb-3" @click="removeTextFromImage"
              :disabled="!selectedImageUrl">
              <i class="las la-eraser"></i> 글자 삭제
            </b-button>
          </div>

          <div class="text-process-all-option">
            <b-button variant="success" size="sm" class="w-100 mb-3" @click="processAllImages">
              <i class="las la-magic"></i> 모든 이미지 한번에 처리
            </b-button>
          </div>

          <h6 class="options-title">이미지 크기</h6>
          <div class="dimension-controls">
            <b-input-group size="sm">
              <b-form-input v-model.number="resizeWidth" type="number" min="100" max="2000"
                class="text-center"></b-form-input>
              <b-input-group-text>×</b-input-group-text>
              <b-form-input v-model.number="resizeHeight" type="number" min="100" max="2000"
                class="text-center"></b-form-input>
              <b-input-group-append>
                <b-button variant="outline-primary" @click="applyResize">적용</b-button>
              </b-input-group-append>
            </b-input-group>
          </div>

          <div class="save-option mt-3">
            <b-button variant="primary" size="sm" class="w-100" @click="saveAdditionalImages">
              <i class="las la-save"></i> 추가 이미지 저장
            </b-button>
          </div>
        </div>
      </div>

      <!-- 우측: 이미지 편집기 -->
      <div class="editor-main-panel">
        <div class="editor-header">
          <div class="mode-toggle">
            <b-button-group>
              <b-button :variant="isPreviewMode ? 'outline-primary' : 'primary'" @click="isPreviewMode = false">편집
                모드</b-button>
              <b-button :variant="isPreviewMode ? 'primary' : 'outline-primary'" @click="isPreviewMode = true">미리보기
                모드</b-button>
            </b-button-group>
          </div>
          <div class="header-left">
            <!-- 왼쪽 영역 비워두기 - 균형을 위해 -->
          </div>
          <div class="editor-actions">
            <b-button variant="outline-secondary" size="sm" @click="resetEditor" title="초기화" v-if="!isPreviewMode">
              <i class="las la-undo"></i>
            </b-button>
            <b-button variant="outline-success" size="sm" @click="applyEditorChanges" title="적용" v-if="!isPreviewMode">
              <i class="las la-save"></i> 저장
            </b-button>
          </div>
        </div>

        <!-- 이미지 표시 영역 -->
        <div class="editor-wrapper">
          <div class="image-container">
            <ImageEditor ref="imageEditor" :html="getImageHtml()" :hideBlockManager="true" @save="handleEditorSave"
              @error="handleEditorError" />
          </div>

          <!-- 로딩 오버레이 -->
          <div v-if="isRemovingText" class="loading-overlay">
            <div class="loading-content">
              <b-spinner variant="primary" label="글자 삭제 중..."></b-spinner>
              <p>AI가 이미지에서 글자를 제거 중입니다...</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 이미지 업로드를 위한 숨겨진 input 추가 -->
    <input ref="imageUploadInput" type="file" accept="image/*" multiple class="d-none" @change="handleImageUpload">
  </div>
</template>

<script>
import ImageEditor from './ImageEditor.vue'
import { mapState, mapActions } from 'vuex'
import imageService from '../../services/imageService'
import additionalImageService from '../../services/works/additionalImageService'
import html2canvas from 'html2canvas'

export default {
  name: "AdditionalImageEdit",
  components: {
    ImageEditor
  },
  props: {
    wizardData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      activeTab: 'main',
      selectedImageType: '',
      selectedImageIndex: -1,
      selectedImageUrl: null,
      currentAdditionalImage: null,
      resizeWidth: 800,
      resizeHeight: 800,
      processedImages: [],
      uploadFiles: null,
      isRemovingText: false,
      editedImageUrl: null,
      imageHistory: {
        original: null,
        processed: null
      },
      isPreviewMode: false, // 미리보기 모드 상태 관리
      carouselInterval: 5000, // 캐러셀 자동 전환 간격 (밀리초)
    };
  },
  computed: {
    // Vuex 상태 매핑
    ...mapState({
      product: state => state.selectedRocketProduct,
      processing: state => state.processing,
      progressMessage: state => state.progressMessage
    }),

    // 로딩 상태
    loading() {
      return this.processing;
    },

    // 사용 가능한 메인 이미지 (대표 이미지 제외)
    availableMainImages() {
      if (!this.product || !this.product.mainImages) return [];

      // 첫 번째 옵션의 대표 이미지 가져오기
      let usedMainImage = null;
      if (this.product.options && this.product.options.length > 0 &&
        this.product.options[0].mainImageStatus &&
        this.product.options[0].mainImageStatus.image) {
        usedMainImage = this.product.options[0].mainImageStatus.image;
      }

      // 대표 이미지로 사용된 이미지 제외
      return this.product.mainImages.filter(img => img !== usedMainImage);
    },

    // 현재 작업 중인 이미지 URL
    currentImageUrl() {
      return this.currentAdditionalImage || this.selectedImageUrl || '';
    }
  },
  mounted() {
    console.log('AdditionalImageEdit 마운트됨, 선택된 제품:', this.product);

    // 이미 처리된 이미지가 있으면 로드
    if (this.product && this.product.additionalImages && this.product.additionalImages.length > 0) {
      this.processedImages = [...this.product.additionalImages];
      this.activeTab = 'processed';
    }
    // 키보드 이벤트 리스너 추가
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    // 컴포넌트 제거 시 이벤트 리스너 제거
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    // Vuex 액션 매핑
    ...mapActions([
      'updateRocketProduct',
      'setProcessing'
    ]),

    // 이미지 HTML 생성
    getImageHtml() {
      // 이미지 URL 결정 로직 단순화: 현재 작업 중인 이미지 우선
      const imageUrl = this.currentImageUrl;

      if (!imageUrl) {
        return '<p>이미지를 선택해주세요</p>';
      }

      return this.getImageHtmlFromUrl(imageUrl);
    },

    // 이미지 URL로부터 HTML 생성
    getImageHtmlFromUrl(imageUrl) {
      return `
        <div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; overflow: hidden;">
          <img src="${imageUrl}" alt="상품 이미지" style="max-width: 100%; max-height: 100%; object-fit: contain;" />
        </div>
      `;
    },

    // 이미지 선택 처리
    selectImage(type, index, imageUrl) {
      console.log(`이미지 선택: ${type} 이미지, 인덱스: ${index}`);

      if (!imageUrl) return;

      // 선택 상태 업데이트
      this.selectedImageType = type;
      this.selectedImageIndex = index;
      this.selectedImageUrl = imageUrl;
      this.currentAdditionalImage = imageUrl;
      this.imageHistory.original = imageUrl;

      // 에디터 업데이트
      if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
        const newHtml = this.getImageHtmlFromUrl(imageUrl);
        this.$refs.imageEditor.editor.setComponents(newHtml);
      }
    },

    // 처리된 이미지 선택
    selectProcessedImage(index, image) {
      console.log("처리된 이미지 선택:", index, image);

      // 이미지가 유효한지 확인
      if (!image || !image.url) {
        console.error("유효하지 않은 이미지 정보입니다.");
        return;
      }

      this.selectedImageType = 'processed';
      this.selectedImageIndex = index;
      this.selectedImageUrl = image.url;
      this.currentAdditionalImage = image.url;
      this.imageHistory.original = image.originalUrl || image.url;

      // 에디터 업데이트
      if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
        const newHtml = this.getImageHtmlFromUrl(image.url);
        this.$refs.imageEditor.editor.setComponents(newHtml);
      }
    },

    // 텍스트 제거 처리
    async removeTextFromImage() {
      console.log("글자 삭제 시작");

      // 현재 이미지가 없으면 중단
      if (!this.currentImageUrl) {
        this.$bvToast.toast('이미지를 먼저 선택해주세요', {
          title: '알림',
          variant: 'warning',
          autoHideDelay: 2000,
          toaster: 'b-toaster-bottom-right'
        });
        return;
      }

      // 로딩 시작
      this.isRemovingText = true;

      try {
        // 이미지 텍스트 제거 API 호출
        const processedImageUrl = await imageService.removeTextFromImage(this.currentImageUrl);

        if (processedImageUrl) {
          // 이미지 다운로드
          const imageBlob = await imageService.downloadImage(processedImageUrl);

          // Blob을 Base64로 변환
          const base64Data = await imageService.blobToBase64(imageBlob);

          // 파일명 생성
          const fileName = `${this.product.registrationId}_additional_${Date.now()}.jpg`;

          // S3에 업로드
          const uploadedUrl = await imageService.uploadImageToS3(base64Data, fileName, 'additional');

          if (uploadedUrl) {
            // 현재 이미지 업데이트
            this.currentAdditionalImage = uploadedUrl;

            // 에디터 업데이트
            if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
              const newHtml = this.getImageHtmlFromUrl(uploadedUrl);
              this.$refs.imageEditor.editor.setComponents(newHtml);
            }

            // 처리된 이미지 목록에 추가
            const existingIndex = this.processedImages.findIndex(img => img.originalUrl === this.imageHistory.original);

            if (existingIndex >= 0) {
              // 기존 항목 업데이트
              this.processedImages[existingIndex] = {
                originalUrl: this.imageHistory.original,
                url: uploadedUrl,
                dimensions: `${this.resizeWidth}x${this.resizeHeight}`,
                filesize: imageService.estimateFileSize(base64Data),
                textRemoved: true
              };
            } else {
              // 새 항목 추가
              this.processedImages.push({
                originalUrl: this.imageHistory.original,
                url: uploadedUrl,
                dimensions: `${this.resizeWidth}x${this.resizeHeight}`,
                filesize: imageService.estimateFileSize(base64Data),
                textRemoved: true
              });
            }

            // 작업 완료 후 processed 탭으로 변경
            this.activeTab = 'processed';

            // 처리 결과 메시지
            this.$bvToast.toast('이미지에서 텍스트가 제거되었습니다.', {
              title: '처리 완료',
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-bottom-right'
            });
          } else {
            throw new Error('이미지 업로드 실패');
          }
        } else {
          throw new Error('이미지 처리 결과가 없습니다.');
        }
      } catch (error) {
        console.error('글자 삭제 오류:', error);
        this.$bvToast.toast(`글자 삭제 중 오류가 발생했습니다: ${error.message}`, {
          title: '오류',
          variant: 'danger',
          autoHideDelay: 4000,
          toaster: 'b-toaster-bottom-right'
        });
      } finally {
        // 로딩 종료
        this.isRemovingText = false;
      }
    },

    // 모든 이미지 한번에 처리
    async processAllImages() {
      if (this.availableMainImages.length === 0) {
        this.$bvToast.toast('처리할 이미지가 없습니다.', {
          title: '알림',
          variant: 'warning',
          autoHideDelay: 2000,
          toaster: 'b-toaster-bottom-right'
        });
        return;
      }

      try {
        // 로딩 시작
        this.setProcessing({
          isProcessing: true,
          message: '모든 추가 이미지 처리 중...'
        });

        // 처리 서비스 호출
        const result = await additionalImageService.process(this.product);

        if (result && result.status === 'completed' && result.additionalImages.length > 0) {
          // 처리된 이미지 저장
          this.processedImages = [...result.additionalImages];

          // 탭 변경
          this.activeTab = 'processed';

          // 성공 메시지
          this.$bvToast.toast(`${result.additionalImages.length}개 이미지가 성공적으로 처리되었습니다.`, {
            title: '처리 완료',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-bottom-right'
          });
        } else {
          throw new Error('이미지 처리 실패');
        }
      } catch (error) {
        console.error('일괄 처리 오류:', error);
        this.$bvToast.toast(`이미지 처리 중 오류가 발생했습니다: ${error.message}`, {
          title: '오류',
          variant: 'danger',
          autoHideDelay: 4000,
          toaster: 'b-toaster-bottom-right'
        });
      } finally {
        // 로딩 종료
        this.setProcessing({ isProcessing: false });
      }
    },

    // 추가 이미지 저장
    async saveAdditionalImages() {
      if (this.processedImages.length === 0) {
        this.$bvToast.toast('저장할 이미지가 없습니다.', {
          title: '알림',
          variant: 'warning',
          autoHideDelay: 2000,
          toaster: 'b-toaster-bottom-right'
        });
        return;
      }

      try {
        // 상품 정보 업데이트
        await this.updateRocketProduct({
          ...this.product,
          additionalImageStatus: 'completed',
          additionalImages: [...this.processedImages]
        });

        // 성공 메시지
        this.$bvToast.toast('추가 이미지가 저장되었습니다.', {
          title: '저장 완료',
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-bottom-right'
        });

        // 이벤트 발행 (부모 컴포넌트에 작업 완료 알림)
        this.$emit('complete');
      } catch (error) {
        console.error('저장 오류:', error);
        this.$bvToast.toast(`저장 중 오류가 발생했습니다: ${error.message}`, {
          title: '오류',
          variant: 'danger',
          autoHideDelay: 4000,
          toaster: 'b-toaster-bottom-right'
        });
      }
    },

    // applyEditorChanges 메서드 구현
    async applyEditorChanges() {
      try {
        // 편집기가 있는지 확인
        if (!this.$refs.imageEditor || !this.$refs.imageEditor.editor) {
          throw new Error('이미지 편집기를 찾을 수 없습니다');
        }

        // 편집기 내 선택 요소 해제
        this.$refs.imageEditor.editor.select(null);

        // 로딩 상태 시작
        this.isRemovingText = true;

        // 편집기의 iframe 요소 가져오기
        const frameEl = this.$refs.imageEditor.editor.Canvas.getFrameEl();
        const frameDoc = frameEl.contentDocument;

        // html2canvas 사용하여 이미지 캡처
        const canvas = await html2canvas(frameDoc.body, {
          allowTaint: true,
          useCORS: true,
          scale: 2, // 더 높은 해상도
          logging: false,
          windowWidth: frameEl.offsetWidth,
          windowHeight: frameEl.offsetHeight
        });

        // 이미지 데이터 URL 생성
        const imageData = canvas.toDataURL('image/png');

        // 파일 이름 생성
        const fileName = `${this.product.registrationId}_additional_${Date.now()}.png`;

        // S3에 업로드
        const uploadedUrl = await imageService.uploadImageToS3(imageData, fileName, 'additional');

        if (uploadedUrl) {
          // 편집된 이미지 URL 저장
          this.editedImageUrl = uploadedUrl;

          // 처리된 이미지 목록에 추가
          const existingIndex = this.processedImages.findIndex(img => img.originalUrl === this.imageHistory.original);

          if (existingIndex >= 0) {
            // 기존 항목 업데이트
            this.processedImages[existingIndex] = {
              originalUrl: this.imageHistory.original,
              url: uploadedUrl,
              dimensions: `${this.resizeWidth}x${this.resizeHeight}`,
              filesize: imageService.estimateFileSize(imageData),
              textRemoved: true
            };
          } else {
            // 새 항목 추가
            this.processedImages.push({
              originalUrl: this.imageHistory.original,
              url: uploadedUrl,
              dimensions: `${this.resizeWidth}x${this.resizeHeight}`,
              filesize: imageService.estimateFileSize(imageData),
              textRemoved: true
            });
          }

          // 탭 변경
          this.activeTab = 'processed';

          // 성공 메시지 표시
          this.$bvToast.toast('이미지가 저장되었습니다', {
            title: '완료',
            variant: 'success',
            autoHideDelay: 2000,
            toaster: 'b-toaster-bottom-right'
          });
        } else {
          throw new Error('이미지 업로드에 실패했습니다');
        }
      } catch (error) {
        console.error('에디터 변경사항 적용 중 오류:', error);
        this.$bvToast.toast(`이미지 저장 중 오류가 발생했습니다: ${error.message}`, {
          title: '오류',
          variant: 'danger',
          autoHideDelay: 3000,
          toaster: 'b-toaster-bottom-right'
        });
      } finally {
        // 로딩 상태 종료
        this.isRemovingText = false;
      }
    },

    // 이미지 크기 변경 적용
    applyResize() {
      // 현재 이미지 가져오기
      const imageToResize = this.currentImageUrl;
      if (!imageToResize) return;

      const img = new Image();
      img.crossOrigin = 'Anonymous';

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = this.resizeWidth;
        canvas.height = this.resizeHeight;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, this.resizeWidth, this.resizeHeight);

        const resizedImageUrl = canvas.toDataURL('image/jpeg', 0.9);

        // 현재 이미지 업데이트
        this.currentAdditionalImage = resizedImageUrl;

        // 에디터 업데이트
        if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
          const newHtml = this.getImageHtmlFromUrl(resizedImageUrl);
          this.$refs.imageEditor.editor.setComponents(newHtml);
        }
      };

      img.src = imageToResize;
    },

    // 에디터 초기화
    resetEditor() {
      if (this.$refs.imageEditor && this.$refs.imageEditor.editor) {
        // 최신 원본 이미지 가져오기
        const imageToLoad = this.imageHistory.original || this.currentImageUrl;
        if (imageToLoad) {
          const html = this.getImageHtmlFromUrl(imageToLoad);
          this.$refs.imageEditor.editor.setComponents(html);
          this.editedImageUrl = null;
        }
      }
    },

    // 에디터에서 이미지 저장 이벤트 처리
    handleEditorSave(htmlContent) {
      // HTML에서 이미지 URL 추출
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = htmlContent;
      const imgElement = tempDiv.querySelector('img');

      if (imgElement && imgElement.src) {
        this.editedImageUrl = imgElement.src;
        this.updateProcessedImage(imgElement.src);
      } else {
        console.error('이미지 URL을 찾을 수 없습니다');
      }
    },

    // 처리된 이미지 업데이트
    updateProcessedImage(imageUrl) {
      // 이미 S3 URL인 경우 (http로 시작하면)
      if (imageUrl.startsWith('http')) {
        const existingIndex = this.processedImages.findIndex(img => img.originalUrl === this.imageHistory.original);

        const imageData = {
          originalUrl: this.imageHistory.original || imageUrl,
          url: imageUrl,
          dimensions: `${this.resizeWidth}x${this.resizeHeight}`,
          filesize: '알 수 없음',
          textRemoved: true
        };

        if (existingIndex >= 0) {
          // 기존 항목 업데이트
          this.processedImages[existingIndex] = imageData;
        } else {
          // 새 항목 추가
          this.processedImages.push(imageData);
        }

        // 탭 변경
        this.activeTab = 'processed';
        return;
      }

      // 기존 data:image URL 처리 로직
      const img = new Image();
      img.onload = () => {
        const imageData = {
          originalUrl: this.imageHistory.original || imageUrl,
          url: imageUrl,
          dimensions: `${img.naturalWidth}x${img.naturalHeight}`,
          filesize: imageService.estimateFileSize(imageUrl),
          textRemoved: true
        };

        const existingIndex = this.processedImages.findIndex(img => img.originalUrl === this.imageHistory.original);

        if (existingIndex >= 0) {
          // 기존 항목 업데이트
          this.processedImages[existingIndex] = imageData;
        } else {
          // 새 항목 추가
          this.processedImages.push(imageData);
        }

        // 탭 변경
        this.activeTab = 'processed';
      };
      img.src = imageUrl;
    },

    // 이미지 파일 크기 추정
    estimateFileSize(dataUrl) {
      // 대략적인 파일 크기 계산 (Base64 문자열 길이 * 0.75)
      if (dataUrl && dataUrl.startsWith('data:')) {
        const base64Length = dataUrl.split(',')[1].length;
        const fileSizeBytes = Math.round((base64Length * 0.75));

        if (fileSizeBytes < 1024) {
          return `${fileSizeBytes} B`;
        } else if (fileSizeBytes < 1024 * 1024) {
          return `${Math.round(fileSizeBytes / 1024)} KB`;
        } else {
          return `${(fileSizeBytes / (1024 * 1024)).toFixed(1)} MB`;
        }
      }

      return '알 수 없음';
    },

    // 이미지 업로드 관련 메서드
    showImageUpload() {
      this.$refs.imageUploadInput.click();
    },

    // 파일 크기 포맷팅
    formatFileSize(size) {
      if (size < 1024) {
        return size + ' B';
      } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(1) + ' KB';
      } else {
        return (size / (1024 * 1024)).toFixed(1) + ' MB';
      }
    },

    // 파일 업로드 처리
    async handleImageUpload(event) {
      const files = event.target.files;
      if (!files || files.length === 0) return;

      this.uploadFiles = files;
      this.activeTab = 'upload';
    },

    // 업로드 파일 처리
    async handleUploadFiles() {
      if (!this.uploadFiles || this.uploadFiles.length === 0) return;

      try {
        // 로딩 시작
        this.setProcessing({
          isProcessing: true,
          message: '파일 업로드 중...'
        });

        // 업로드 처리
        const uploadedImages = await additionalImageService.uploadFiles(
          this.uploadFiles,
          this.product.registrationId
        );

        if (uploadedImages && uploadedImages.length > 0) {
          // 처리된 이미지에 추가
          this.processedImages = [...this.processedImages, ...uploadedImages];

          // 업로드 파일 초기화
          this.uploadFiles = null;

          // 탭 변경
          this.activeTab = 'processed';

          // 성공 메시지
          this.$bvToast.toast(`${uploadedImages.length}개 이미지가 업로드되었습니다.`, {
            title: '업로드 완료',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-bottom-right'
          });
        } else {
          throw new Error('파일 업로드 실패');
        }
      } catch (error) {
        console.error('업로드 오류:', error);
        this.$bvToast.toast(`업로드 중 오류가 발생했습니다: ${error.message}`, {
          title: '오류',
          variant: 'danger',
          autoHideDelay: 4000,
          toaster: 'b-toaster-bottom-right'
        });
      } finally {
        // 로딩 종료
        this.setProcessing({ isProcessing: false });
      }
    },

    // 에디터 오류 처리
    handleEditorError() {
      console.error("이미지 편집기 로드 오류");

      this.$bvToast.toast('이미지 편집기 로드 중 오류가 발생했습니다', {
        title: '오류',
        variant: 'danger',
        autoHideDelay: 3000,
        toaster: 'b-toaster-bottom-right'
      });
    },

    handleKeyDown(e) {
      if (e.key === 'Escape' && this.isPreviewMode) {
        this.isPreviewMode = false;
      }
    },
  }
};
</script>
<style scoped>
.additional-image-edit {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* 레이아웃 컨테이너 */
.image-editor-layout {
  display: flex;
  height: 100%;
  background-color: #f9f9f9;
}

/* 좌측 이미지 선택 패널 */
.image-selector-panel {
  width: 280px;
  background-color: #fff;
  border-right: 1px solid #eee;
  display: flex;
  flex-direction: column;
}

.panel-header {
  padding: 15px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel-title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

/* 이미지 탭 스타일 */
.image-tabs {
  border-bottom: 1px solid #eee;
}

.image-tabs .nav-tabs {
  border-bottom: none;
}

.image-tabs .nav-link {
  font-size: 12px;
  padding: 5px 10px;
  color: #777;
}

.image-tabs .nav-link.active {
  color: #3498db;
  font-weight: 600;
}

.image-gallery {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.image-item {
  position: relative;
  border: 2px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
  aspect-ratio: 1/1;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.image-item.selected {
  border-color: #3498db;
}

.image-overlay {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background: #3498db;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s;
}

.image-item.selected .image-overlay {
  opacity: 1;
}

/* 이미지 라벨 스타일 */
.image-label {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 10px;
  padding: 3px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.empty-gallery {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #aaa;
  text-align: center;
  padding: 20px;
}

.image-options {
  padding: 15px;
  border-top: 1px solid #eee;
}

.options-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #555;
}

/* 우측 에디터 패널 */
.editor-main-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

.editor-header {
  padding: 10px 15px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  /* 변경: space-between으로 설정 */
  align-items: center;
  position: relative;
}

.header-left {
  width: 100px;
  /* 균형을 맞추기 위한 왼쪽 여백 */
}

.editor-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  padding: 20px;
  min-height: 400px;
  position: relative;
}

.image-container {
  position: relative;
  width: min(100%, 500px);
  height: min(100%, 500px);
  aspect-ratio: 1/1;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* 로딩 상태 */
.loading-container {
  text-align: center;
  padding: 40px 0;
}

/* 로딩 오버레이 */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.loading-content {
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
}

.loading-content p {
  margin-top: 15px;
  color: #333;
}

/* 텍스트 제거 배지 스타일 */
.text-removed-badge {
  background-color: #28a745;
  color: white;
  padding: 0 3px;
  border-radius: 3px;
  font-size: 9px;
  display: inline-block;
}

/* 업로드 컨테이너 */
.upload-container {
  padding: 10px;
}

.upload-area {
  background-color: #f8f9fa;
  border: 2px dashed #ddd;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 15px;
}

.file-preview {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 6px;
}

.file-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
  font-size: 12px;
}

.file-list li {
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

/* 결과 요약 */
.result-summary {
  background-color: #f8f9fa;
  border-top: 1px solid #eee;
  padding: 15px;
  text-align: center;
}

.result-summary h5 {
  margin-bottom: 10px;
}

.result-summary .actions {
  margin-top: 15px;
}

/* 반응형 조정 */
@media (max-width: 768px) {
  .image-editor-layout {
    flex-direction: column;
  }

  .image-selector-panel {
    width: 100%;
    max-height: 200px;
    border-right: none;
    border-bottom: 1px solid #eee;
  }
}

/* 미리보기 모드 스타일 수정 */
.editor-header {
  padding: 10px 15px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header-left {
  width: 100px;
  /* 균형을 맞추기 위한 왼쪽 여백 */
}

.editor-actions {
  width: 100px;
  /* 균형을 맞추기 위한 오른쪽 영역 */
  display: flex;
  justify-content: flex-end;
}

.mode-toggle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-scroll-container {
  width: 80%;
  height: 90%;
  overflow-y: auto;
  padding: 20px;
}

.images-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.preview-image-item {
  position: relative;
  background-color: #000;
  display: flex;
  justify-content: center;
  padding: 20px;
  border: 1px solid #333;
}

.image-number {
  position: absolute;
  top: -15px;
  left: 10px;
  background-color: white;
  color: #333;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid #ddd;
}

.preview-image {
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
}

.no-images-placeholder {
  color: white;
  text-align: center;
  padding: 40px;
}

.no-images-placeholder i {
  font-size: 48px;
  margin-bottom: 15px;
}

.close-preview-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.close-preview-btn:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.close-preview-btn:focus {
  outline: none;
}
</style>
