<template>
    <div>
        <b-collapse id="settings-collapse" v-model="showSettings">
            <div class="card">
                <div class="card-header">
                    <h5 class="mb-0">기본 등록 정보 설정</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <!-- 왼쪽 컬럼: 기본 등록 정보 -->
                        <div class="col-md-6">
                            <div class="card mb-3">
                                <div class="card-header bg-light">
                                    <h6 class="mb-0">기본 등록 정보</h6>
                                </div>
                                <div class="card-body">
                                    <b-form-group label="브랜드명" label-for="brand-input">
                                        <b-form-input id="brand-input" v-model="settingsData.brand"
                                            placeholder="브랜드명을 입력해주세요" trim></b-form-input>
                                    </b-form-group>

                                    <b-form-group label="제조사" label-for="manufacturer-input">
                                        <b-form-input id="manufacturer-input" v-model="settingsData.manufacturer"
                                            placeholder="제조사를 입력해주세요" trim></b-form-input>
                                    </b-form-group>

                                    <b-form-group label="거래타입" label-for="transaction-type-select">
                                        <b-form-select id="transaction-type-select"
                                            v-model="settingsData.transactionType"
                                            :options="transactionTypeOptions"></b-form-select>
                                    </b-form-group>

                                    <b-form-group label="수입여부" label-for="import-status-select">
                                        <b-form-select id="import-status-select" v-model="settingsData.importStatus"
                                            :options="importStatusOptions"></b-form-select>
                                    </b-form-group>

                                    <b-form-group label="A/S 책임자와 전화번호" label-for="as-info-input">
                                        <b-form-input id="as-info-input" v-model="settingsData.asInfo"
                                            placeholder="예: 홍길동 / 010-1234-5678" trim></b-form-input>
                                    </b-form-group>
                                    
                                    <b-form-group label="박스 내 SKU 수량" label-for="total-skus-in-box">
                                        <b-form-input 
                                            id="total-skus-in-box" 
                                            v-model.number="settingsData.totalSKUsInBox" 
                                            type="number" 
                                            min="1" 
                                            max="9999"
                                            placeholder="1개의 박스에 담긴 SKU 수량">
                                        </b-form-input>
                                        <small class="form-text text-muted">
                                            1개의 박스에 담긴 SKU 수량을 입력해주세요. 기본값은 1입니다.
                                        </small>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>

                        <!-- 오른쪽 컬럼: 가격설정 방법 -->
                        <div class="col-md-6">
                            <div class="card mb-3">
                                <div class="card-header bg-light">
                                    <h6 class="mb-0">가격설정 방법</h6>
                                </div>
                                <div class="card-body">
                                    <!-- 비율 시각화 -->
                                    <div class="row mb-2 mt-2">
                                        <div class="col-md-4 text-center">상품 원가</div>
                                        <div class="col-md-4 text-center">공급 마진</div>
                                        <div class="col-md-4 text-center">쿠팡 마진</div>
                                    </div>
                                    <div class="progress mb-3" style="height: 30px; border-radius: 5px;">
                                        <div class="progress-bar bg-warning" role="progressbar"
                                            :style="{ width: costRatio + '%' }" :aria-valuenow="costRatio"
                                            aria-valuemin="0" aria-valuemax="100">
                                            {{ costRatio.toFixed(0) }}%
                                        </div>
                                        <div class="progress-bar bg-success" role="progressbar"
                                            :style="{ width: marginRatioPercent + '%' }"
                                            :aria-valuenow="marginRatioPercent" aria-valuemin="0" aria-valuemax="100">
                                            {{ marginRatioPercent.toFixed(0) }}%
                                        </div>
                                        <div class="progress-bar bg-primary" role="progressbar"
                                            :style="{ width: coupangRatioPercent + '%' }"
                                            :aria-valuenow="coupangRatioPercent" aria-valuemin="0" aria-valuemax="100">
                                            {{ coupangRatioPercent.toFixed(0) }}%
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-around">
                                        <!-- 환율 및 반올림 단위 -->
                                        <div class="d-flex flex-column align-items-center text-center">
                                            <b-form-group label="적용환율 (CNY → KRW)" label-for="exchange-rate-input">
                                                <div class="d-flex align-items-center">
                                                    <span>x </span>
                                                    <b-form-input id="exchange-rate-input"
                                                        v-model.number="settingsData.exchangeRate" type="number"
                                                        min="150" max="350" class="ml-2"></b-form-input>
                                                </div>
                                            </b-form-group>

                                            <b-form-group label="가격 반올림 단위" label-for="round-unit-select">
                                                <b-form-select id="round-unit-select" v-model="settingsData.roundUnit"
                                                    :options="roundUnitOptions">
                                                </b-form-select>
                                            </b-form-group>
                                        </div>

                                        <!-- 공급 마진율 -->
                                        <b-form-group label="공급 마진율 (%)" description="공급가 = 매입가 ÷ (1 - 공급마진율/100)">
                                            <div class="d-flex align-items-center mb-2">
                                                <b-form-input v-model.number="settingsData.marginRate" type="number"
                                                    min="0" max="95" class="mr-2"
                                                    @input="updateRatioDisplay"></b-form-input>
                                                <span>%</span>
                                            </div>
                                            <input type="range" class="custom-range mt-2"
                                                v-model.number="settingsData.marginRate" min="0" max="90" step="5"
                                                @input="updateRatioDisplay">
                                        </b-form-group>

                                        <!-- 쿠팡 마진율 -->
                                        <b-form-group label="쿠팡 마진율 (%)" description="판매가 = 공급가 ÷ (1 - 쿠팡마진율/100)">
                                            <div class="d-flex align-items-center mb-2">
                                                <b-form-input v-model.number="settingsData.coupangMarginRate"
                                                    type="number" min="0" max="95" class="mr-2"
                                                    @input="updateRatioDisplay"></b-form-input>
                                                <span>%</span>
                                            </div>
                                            <input type="range" class="custom-range mt-2"
                                                v-model.number="settingsData.coupangMarginRate" min="0" max="90"
                                                step="5" @input="updateRatioDisplay">
                                        </b-form-group>
                                    </div>
                                    <!-- 번들링 설정 추가 -->
                                    <div class="mt-4">
                                        <div class="d-flex align-items-center mb-2">
                                            <b-form-checkbox switch v-model="settingsData.enableBundling" class="mr-2">
                                                번들링 활성화
                                            </b-form-checkbox>
                                            <small class="text-muted">낮은 마진 상품을 자동으로 묶어서 판매합니다</small>
                                        </div>

                                        <div v-if="settingsData.enableBundling" class="mt-3">
                                            <b-form-group label="번들링 기준" v-slot="{ ariaDescribedby }">
                                                <b-form-radio-group v-model="settingsData.bundlingType"
                                                    :options="bundlingTypeOptions"
                                                    :aria-describedby="ariaDescribedby"></b-form-radio-group>
                                            </b-form-group>

                                            <div v-if="settingsData.bundlingType === 'marginAmount'" class="mt-2">
                                                <b-form-group label="최소 공급 마진액 (원)">
                                                    <b-form-input v-model.number="settingsData.minMarginAmount"
                                                        type="number" min="1000" step="1000"></b-form-input>
                                                    <small class="form-text text-muted">이 금액보다 마진이 낮으면 2p, 4p,
                                                        6p... 단위로
                                                        번들링합니다</small>
                                                </b-form-group>
                                            </div>

                                            <div v-else class="mt-2">
                                                <b-form-group label="최소 쿠팡 마진액 (원)">
                                                    <b-form-input v-model.number="settingsData.minCoupangMarginAmount"
                                                        type="number" min="1000" step="1000"></b-form-input>
                                                    <small class="form-text text-muted">이 금액보다 마진이 낮으면 2p, 4p,
                                                        6p... 단위로
                                                        자동번들링 됩니다</small>
                                                </b-form-group>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 이미지 작업 설정 영역 -->
                        <div class="col-md-6">
                            <div class="card mb-3">
                                <div class="card-header bg-light">
                                    <h6 class="mb-0">이미지 작업 설정</h6>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <!-- 상단 이미지 설정 -->
                                            <div class="form-group">
                                                <div class="d-flex align-items-center mb-2">
                                                    <b-form-checkbox switch
                                                        v-model="settingsData.imageSettings.useTopImage" class="mr-2">
                                                        상단 이미지 사용
                                                    </b-form-checkbox>
                                                    <small class="text-muted">브랜드 이미지, 이벤트 이미지 등</small>
                                                </div>
                                                <div v-if="settingsData.imageSettings.useTopImage" class="mt-2">
                                                    <!-- 이미지가 없을 때 보여줄 드롭 영역 -->
                                                    <div v-if="!settingsData.imageSettings.topImageUrl && !topImageFile"
                                                        class="image-drop-area" @dragover.prevent
                                                        @drop.prevent="handleTopImageDrop" @click="triggerTopImageInput"
                                                        @paste="handleTopImagePaste">
                                                        <div class="text-center">
                                                            <i class="las la-plus-circle" style="font-size: 24px;"></i>
                                                            <p class="mb-0">이미지를 여기에 끌어다 놓거나, 클릭하여 선택하세요</p>
                                                            <p class="text-muted small">또는 Ctrl+V로 클립보드 이미지를
                                                                붙여넣으세요</p>
                                                        </div>
                                                    </div>

                                                    <!-- 이미지가 있을 때 보여줄 미리보기 -->
                                                    <div v-else class="position-relative">
                                                        <img :src="topImagePreviewUrl || settingsData.imageSettings.topImageUrl"
                                                            class="img-thumbnail" style="max-height: 150px;">
                                                        <button
                                                            class="btn btn-sm btn-danger position-absolute delete-image-btn"
                                                            @click.prevent="removeTopImage">
                                                            <i class="las la-trash"></i>
                                                        </button>
                                                    </div>

                                                    <!-- 숨겨진 파일 입력 -->
                                                    <input ref="topImageInput" type="file" class="d-none"
                                                        accept="image/*" @change="handleTopImageSelect">
                                                </div>
                                            </div>

                                            <!-- 하단 이미지 설정 -->
                                            <div class="form-group">
                                                <div class="d-flex align-items-center mb-2">
                                                    <b-form-checkbox switch
                                                        v-model="settingsData.imageSettings.useBottomImage"
                                                        class="mr-2">
                                                        하단 이미지 사용
                                                    </b-form-checkbox>
                                                    <small class="text-muted">배송, 보상, 반품 유의사항 등</small>
                                                </div>
                                                <div v-if="settingsData.imageSettings.useBottomImage" class="mt-2">
                                                    <!-- 이미지가 없을 때 보여줄 드롭 영역 -->
                                                    <div v-if="!settingsData.imageSettings.bottomImageUrl && !bottomImageFile"
                                                        class="image-drop-area" @dragover.prevent
                                                        @drop.prevent="handleBottomImageDrop"
                                                        @click="triggerBottomImageInput"
                                                        @paste="handleBottomImagePaste">
                                                        <div class="text-center">
                                                            <i class="las la-plus-circle" style="font-size: 24px;"></i>
                                                            <p class="mb-0">이미지를 여기에 끌어다 놓거나, 클릭하여 선택하세요</p>
                                                            <p class="text-muted small">또는 Ctrl+V로 클립보드 이미지를
                                                                붙여넣으세요</p>
                                                        </div>
                                                    </div>

                                                    <!-- 이미지가 있을 때 보여줄 미리보기 -->
                                                    <div v-else class="position-relative">
                                                        <img :src="bottomImagePreviewUrl || settingsData.imageSettings.bottomImageUrl"
                                                            class="img-thumbnail" style="max-height: 150px;">
                                                        <button
                                                            class="btn btn-sm btn-danger position-absolute delete-image-btn"
                                                            @click.prevent="removeBottomImage">
                                                            <i class="las la-trash"></i>
                                                        </button>
                                                    </div>

                                                    <!-- 숨겨진 파일 입력 -->
                                                    <input ref="bottomImageInput" type="file" class="d-none"
                                                        accept="image/*" @change="handleBottomImageSelect">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <!-- 이미지 번역 설정 -->
                                            <!-- <div class="form-group">
                                                <div class="d-flex align-items-center mb-2">
                                                    <b-form-checkbox switch
                                                        v-model="settingsData.imageSettings.translateImages"
                                                        class="mr-2">
                                                        이미지 번역
                                                    </b-form-checkbox>
                                                    <small class="text-muted">중국어 포함 이미지를 한글로 자동 번역</small>
                                                </div>
                                            </div> -->

                                            <!-- 카피라이트 설정 -->
                                            <!-- <div class="form-group mt-3"
                                                v-if="settingsData.imageSettings.translateImages">
                                                <div class="d-flex align-items-center mb-2">
                                                    <b-form-checkbox switch
                                                        v-model="settingsData.imageSettings.addCopyright" class="mr-2">
                                                        카피라이트 추가
                                                    </b-form-checkbox>
                                                    <small class="text-muted">번역된 이미지에 자동으로 AI카피라이트 추가</small>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- AI 설정 영역 -->
                        <div class="col-md-6">
                            <div class="card mb-3">
                                <div class="card-header bg-light">
                                    <h6 class="mb-0">AI 설정</h6>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <!-- Gemini API 설정 -->
                                            <div class="form-group">
                                                <label for="gemini-api-key" class="font-weight-bold">Gemini API
                                                    Key</label>
                                                <div class="d-flex align-items-center">
                                                    <b-form-input id="gemini-api-key" v-model="geminiAPIKey"
                                                        placeholder="Gemini API Key를 입력해주세요" trim>
                                                    </b-form-input>
                                                    <b-button variant="primary" size="sm" class="ml-2"
                                                        @click="openGeminiHelp" v-b-tooltip.hover
                                                        title="Gemini API Key 발급 받기">
                                                        <i class="las la-key mr-1"></i> API Key 발급
                                                    </b-button>
                                                </div>
                                                <div class="alert alert-info mt-2 p-2 small">
                                                    <i class="las la-info-circle mr-1"></i>
                                                    <strong>API Key 발급 방법:</strong>
                                                    <a href="https://aistudio.google.com/app/apikey" target="_blank"
                                                        class="alert-link">Google AI
                                                        Studio</a>에서
                                                    회원가입 후 API Key를 발급받을 수 있습니다. 이 API Key는 이미지작업, 등록정보를 자동생성하는
                                                    기능에 사용됩니다.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 저장 버튼 -->
                    <div class="d-flex justify-content-end mt-4">
                        <b-button variant="primary" @click="saveSettings">
                            <i class="las la-save mr-1"></i> 설정 저장
                        </b-button>
                    </div>
                </div>
            </div>
        </b-collapse>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';

export default {
    name: "RocketSettingsPanel",
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            settingsData: {
                brand: '',
                manufacturer: '',
                transactionType: '기타 도소매업자',
                importStatus: '수입상품',
                asInfo: '',
                
                exchangeRate: 350,
                marginRate: 40,
                coupangMarginRate: 40,
                roundUnit: 100,
                enableBundling: false,
                bundlingType: 'marginAmount',
                minMarginAmount: 3000,
                minCoupangMarginAmount: 3000,
                // 한글표시사항 관련 추가 필드
                washingMethod: '',         // 세탁방법
                handlingPrecautions: '',    // 취급시 주의사항
                manufactureDate: '',        // 제조연월
                // 물류 정보 관련 추가 필드
                totalSKUsInBox: 1,          // 박스 내 SKU 수량
                daysToExpiration: 0,        // 유통기한
                specialHandlingReason: '해당사항없음', // 취급주의 사유
                imageSettings: {
                    useTopImage: false,
                    topImageUrl: '',
                    useBottomImage: false,
                    bottomImageUrl: '',
                    translateImages: true,
                    addCopyright: false
                }
            },
            geminiAPIKey: '',
            transactionTypeOptions: [
                { value: '제조사', text: '제조사' },
                { value: '공식총판사', text: '공식총판사' },
                { value: '공식대리점', text: '공식대리점' },
                { value: '기타 도소매업자', text: '기타 도소매업자' }
            ],
            importStatusOptions: [
                { value: '수입대상아님', text: '수입대상아님' },
                { value: '수입상품', text: '수입상품' },
                { value: '병행수입상품', text: '병행수입상품' }
            ],
            roundUnitOptions: [
                { value: 1, text: '1원 단위' },
                { value: 10, text: '10원 단위' },
                { value: 100, text: '100원 단위' },
                { value: 1000, text: '1,000원 단위' }
            ],
            bundlingTypeOptions: [
                { text: '최소 공급 마진액 기준', value: 'marginAmount' },
                { text: '최소 쿠팡 마진액 기준', value: 'coupangMarginAmount' }
            ],
            topImageFile: null,
            bottomImageFile: null,
            topImagePreviewUrl: null,
            bottomImagePreviewUrl: null,
            imagesToUpload: {
                top: null,
                bottom: null
            },
            imageUploading: false
        };
    },
    computed: {
        ...mapState({
            storeSettings: state => state.settings[0]?.defaultSettings || {}
        }),

        showSettings: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },

        costRatio() {
            const marginRate = this.settingsData.marginRate;
            const coupangRate = this.settingsData.coupangMarginRate;
            return Math.max(1, 100 - marginRate - coupangRate);
        },

        marginRatioPercent() {
            return this.settingsData.marginRate;
        },

        coupangRatioPercent() {
            return this.settingsData.coupangMarginRate;
        }
    },
    created() {
        this.initializeSettings();
    },
    methods: {
        initializeSettings() {
            // 스토어에서 설정 로드
            if (this.storeSettings && Object.keys(this.storeSettings).length > 0) {
                // 깊은 복사를 통해 기존 구조 유지하면서 storeSettings의 값으로 초기화
                this.settingsData = JSON.parse(JSON.stringify(this.storeSettings));
            }

            // API 키 로드
            if (this.$store.state.settings[0] && this.$store.state.settings[0].geminiAPIKey) {
                this.geminiAPIKey = this.$store.state.settings[0].geminiAPIKey;
            }
        },

        updateRatioDisplay() {
            // 마진율 합이 95%를 초과하지 않도록 제한
            const totalMargin = this.settingsData.marginRate + this.settingsData.coupangMarginRate;
            if (totalMargin > 95) {
                // 비율 유지하면서 조정
                const scale = 95 / totalMargin;
                this.settingsData.marginRate = Math.round(this.settingsData.marginRate * scale);
                this.settingsData.coupangMarginRate = Math.round(this.settingsData.coupangMarginRate * scale);
            }
        },

        openGeminiHelp() {
            window.open('https://aistudio.google.com/app/apikey', '_blank');
        },

        async saveSettings() {
            // 원본 설정과 현재 설정을 비교하기 위해 깊은 복사 사용
            const originalSettings = JSON.parse(JSON.stringify(this.storeSettings || {}));
            const currentSettings = JSON.parse(JSON.stringify(this.settingsData));

            // 이미지 업로드가 없고, 설정 변경이 없는 경우 저장 없이 collapse 닫기
            if (!this.imagesToUpload.top && !this.imagesToUpload.bottom &&
                this.isSettingsEqual(originalSettings, currentSettings)) {
                console.log('설정 변경사항 없음, collapse 닫기');
                this.showSettings = false;
                return;
            }

            this.imageUploading = true;
            try {
                console.log('설정 저장 시작, 이미지 업로드 확인...');

                // 이미지 업로드 처리
                if (this.imagesToUpload.top) {
                    console.log('상단 이미지 업로드 시작');
                    const topImageUrl = await this.uploadImageToS3(
                        this.imagesToUpload.top.data,
                        this.imagesToUpload.top.filename,
                        'top'
                    );
                    if (topImageUrl) {
                        console.log('상단 이미지 업로드 성공:', topImageUrl);
                        this.settingsData.imageSettings.topImageUrl = topImageUrl;
                    } else {
                        console.error('상단 이미지 업로드 실패: URL이 반환되지 않음');
                    }
                }

                if (this.imagesToUpload.bottom) {
                    console.log('하단 이미지 업로드 시작');
                    const bottomImageUrl = await this.uploadImageToS3(
                        this.imagesToUpload.bottom.data,
                        this.imagesToUpload.bottom.filename,
                        'bottom'
                    );
                    if (bottomImageUrl) {
                        console.log('하단 이미지 업로드 성공:', bottomImageUrl);
                        this.settingsData.imageSettings.bottomImageUrl = bottomImageUrl;
                    } else {
                        console.error('하단 이미지 업로드 실패: URL이 반환되지 않음');
                    }
                }

                console.log('설정 저장 API 호출...');

                // 서버에 설정 저장
                const result = await axios.post('/api/savesetting', {
                    defaultSettings: this.settingsData,
                    geminiAPIKey: this.geminiAPIKey
                });

                console.log('설정 저장 응답:', result.data);

                if (result.data.success) {
                    this.$store.commit('settingsupdate', [result.data.data]);

                    // 이미지 객체 초기화
                    this.topImageFile = null;
                    this.bottomImageFile = null;
                    this.topImagePreviewUrl = null;
                    this.bottomImagePreviewUrl = null;
                    this.imagesToUpload = {
                        top: null,
                        bottom: null
                    };

                    this.$bvToast.toast('설정이 저장되었습니다.', {
                        title: '저장 완료',
                        variant: 'success',
                        solid: true,
                        toaster: 'b-toaster-bottom-right'
                    });

                    // 저장 후 collapse 닫기
                    this.showSettings = false;

                    // 부모 컴포넌트에 저장 이벤트 전달
                    this.$emit('settings-saved', this.settingsData);
                } else {
                    this.$bvToast.toast('저장 실패 (510)', {
                        title: '저장 오류',
                        variant: 'danger',
                        solid: true,
                        toaster: 'b-toaster-bottom-right'
                    });
                    console.error('설정 저장 실패:', result.data);
                }
            } catch (e) {
                this.$bvToast.toast('저장 실패 (514)', {
                    title: '저장 오류',
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
                console.error('설정 저장 중 예외 발생:', e);
            } finally {
                this.imageUploading = false;
            }
        },

        // 두 설정 객체가 동일한지 비교하는 헬퍼 메서드
        isSettingsEqual(obj1, obj2) {
            // 이미지 URL과 Gemini API Key를 비교에서 제외하기 위해 깊은 복사본 생성
            if (!obj1 || !obj2) return false;

            // 객체 복사
            const obj1Copy = JSON.parse(JSON.stringify(obj1));
            const obj2Copy = JSON.parse(JSON.stringify(obj2));

            // 이미지 URL 제외
            if (obj1Copy.imageSettings) {
                delete obj1Copy.imageSettings.topImageUrl;
                delete obj1Copy.imageSettings.bottomImageUrl;
            }

            if (obj2Copy.imageSettings) {
                delete obj2Copy.imageSettings.topImageUrl;
                delete obj2Copy.imageSettings.bottomImageUrl;
            }

            // Gemini API Key는 별도로 비교
            const currentGeminiKey = this.geminiAPIKey || '';
            const originalGeminiKey = this.$store.state.settings[0]?.geminiAPIKey || '';

            // 객체 비교
            const settingsEqual = JSON.stringify(obj1Copy) === JSON.stringify(obj2Copy);
            const geminiKeyEqual = currentGeminiKey === originalGeminiKey;

            // 설정과 Gemini API Key 모두 동일해야 true 반환
            return settingsEqual && geminiKeyEqual;
        },

        // 이미지 업로드 관련 메서드
        triggerTopImageInput() {
            this.$refs.topImageInput.click();
        },

        handleTopImageSelect(event) {
            const file = event.target.files[0];
            if (file) {
                this.processTopImage(file);
            }
        },

        handleTopImageDrop(event) {
            const file = event.dataTransfer.files[0];
            if (file && file.type.startsWith('image/')) {
                this.processTopImage(file);
            }
        },

        handleTopImagePaste(event) {
            const items = (event.clipboardData || event.originalEvent.clipboardData).items;
            for (const item of items) {
                if (item.type.indexOf('image') === 0) {
                    const file = item.getAsFile();
                    this.processTopImage(file);
                    break;
                }
            }
        },

        async processTopImage(file) {
            if (!file) return;

            const fileExt = file.name.split('.').pop().toLowerCase() || 'png';
            if (!['jpg', 'jpeg', 'png', 'gif'].includes(fileExt)) {
                this.$bvToast.toast('지원되는 이미지 형식이 아닙니다.', {
                    title: '업로드 오류',
                    variant: 'warning',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
                return;
            }

            this.topImageFile = file;
            this.topImagePreviewUrl = URL.createObjectURL(file);

            // 파일을 Base64로 변환하여 저장
            this.imagesToUpload.top = {
                file: file,
                data: await this.fileToBase64(file),
                filename: `${this.$store.state.user.companyid}_topimage.${fileExt}`,
                contentType: file.type,
                type: 'top'
            };

            this.$bvToast.toast('이미지가 선택되었습니다. 설정 저장 시 업로드됩니다.', {
                title: '이미지 선택',
                variant: 'info',
                solid: true,
                toaster: 'b-toaster-bottom-right'
            });
        },

        removeTopImage() {
            this.topImageFile = null;
            this.topImagePreviewUrl = null;

            if (this.settingsData.imageSettings.topImageUrl) {
                // 이미 저장된 이미지가 있으면 DB에서도 삭제 표시
                this.settingsData.imageSettings.topImageUrl = '';
                this.imagesToUpload.top = null;

                this.$bvToast.toast('이미지가 삭제되었습니다. 설정 저장 시 반영됩니다.', {
                    title: '이미지 삭제',
                    variant: 'info',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
            }
        },

        // 하단 이미지 관련 메서드
        triggerBottomImageInput() {
            this.$refs.bottomImageInput.click();
        },

        handleBottomImageSelect(event) {
            const file = event.target.files[0];
            if (file) {
                this.processBottomImage(file);
            }
        },

        handleBottomImageDrop(event) {
            const file = event.dataTransfer.files[0];
            if (file && file.type.startsWith('image/')) {
                this.processBottomImage(file);
            }
        },

        handleBottomImagePaste(event) {
            const items = (event.clipboardData || event.originalEvent.clipboardData).items;
            for (const item of items) {
                if (item.type.indexOf('image') === 0) {
                    const file = item.getAsFile();
                    this.processBottomImage(file);
                    break;
                }
            }
        },

        async processBottomImage(file) {
            if (!file) return;

            const fileExt = file.name.split('.').pop().toLowerCase() || 'png';
            if (!['jpg', 'jpeg', 'png', 'gif'].includes(fileExt)) {
                this.$bvToast.toast('지원되는 이미지 형식이 아닙니다.', {
                    title: '업로드 오류',
                    variant: 'warning',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
                return;
            }

            this.bottomImageFile = file;
            this.bottomImagePreviewUrl = URL.createObjectURL(file);

            // 파일을 Base64로 변환하여 저장
            this.imagesToUpload.bottom = {
                file: file,
                data: await this.fileToBase64(file),
                filename: `${this.$store.state.user.companyid}_bottomimage.${fileExt}`,
                contentType: file.type,
                type: 'bottom'
            };

            this.$bvToast.toast('이미지가 선택되었습니다. 설정 저장 시 업로드됩니다.', {
                title: '이미지 선택',
                variant: 'info',
                solid: true,
                toaster: 'b-toaster-bottom-right'
            });
        },

        removeBottomImage() {
            this.bottomImageFile = null;
            this.bottomImagePreviewUrl = null;

            if (this.settingsData.imageSettings.bottomImageUrl) {
                // 이미 저장된 이미지가 있으면 DB에서도 삭제 표시
                this.settingsData.imageSettings.bottomImageUrl = '';
                this.imagesToUpload.bottom = null;

                this.$bvToast.toast('이미지가 삭제되었습니다. 설정 저장 시 반영됩니다.', {
                    title: '이미지 삭제',
                    variant: 'info',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
            }
        },

        // 유틸리티 메서드
        fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => resolve(e.target.result);
                reader.onerror = (e) => reject(e);
                reader.readAsDataURL(file);
            });
        },

        async uploadImageToS3(imageData, filename, type) {
            try {
                // Vuex 액션 사용
                const result = await this.$store.dispatch('uploadImageToS3', {
                    imageData,
                    filename,
                    type
                });

                if (result && result.imageUrl) {
                    console.log(`S3 업로드 성공: ${result.imageUrl}`);
                    return result.imageUrl;
                } else {
                    console.error('S3 업로드 실패:', result?.message || '알 수 없는 오류');
                    return null;
                }
            } catch (error) {
                console.error('S3 업로드 예외:', error);
                return null;
            }
        }
    }
};
</script>

<style scoped>
.image-drop-area {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    background-color: #f8f9fa;
    border-radius: 5px;
    transition: all 0.3s;
}

.image-drop-area:hover {
    background-color: #e9ecef;
    border-color: #6c757d;
}

.delete-image-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    display: none;
}

.position-relative:hover .delete-image-btn {
    display: block;
}
</style>