<template>
    <div class="container-fluid">
        <!-- 헤더 섹션 -->
        <div class="row">
            <div class="col-lg-12">
                <div class="d-flex flex-wrap align-items-center justify-content-between">
                    <div>
                        <h4 class="mb-3">로켓배송 AI상품등록</h4>
                        <p class="mb-0">상품별 등록 현황 및 관리</p>
                    </div>
                    <div class="d-flex">
                        <b-button variant="secondary" class="mr-2" @click="toggleSettings">
                            <i class="las la-cog mr-1"></i> 기본설정
                        </b-button>
                        <b-button variant="primary" class="mr-2" @click="addProduct">
                            상품 추가
                        </b-button>
                        <b-dropdown :text="getWorkTypeText(selectedWorkType)" variant="outline-primary" class="mr-2">
                            <b-dropdown-item @click="selectWorkType('all')" :active="selectedWorkType === 'all'">전체
                                작업</b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item @click="selectWorkType('seo')"
                                :active="selectedWorkType === 'seo'">SEO설정</b-dropdown-item>
                            <b-dropdown-item @click="selectWorkType('pricing')"
                                :active="selectedWorkType === 'pricing'">가격설정</b-dropdown-item>
                            <b-dropdown-item @click="selectWorkType('mainImage')"
                                :active="selectedWorkType === 'mainImage'">대표이미지</b-dropdown-item>
                            <b-dropdown-item @click="selectWorkType('additionalImage')"
                                :active="selectedWorkType === 'additionalImage'">추가이미지</b-dropdown-item>
                            <b-dropdown-item @click="selectWorkType('detailImage')"
                                :active="selectedWorkType === 'detailImage'">상세이미지</b-dropdown-item>
                            <b-dropdown-item @click="selectWorkType('sizeChart')"
                                :active="selectedWorkType === 'sizeChart'">사이즈표</b-dropdown-item>
                            <b-dropdown-item @click="selectWorkType('koreanLabel')"
                                :active="selectedWorkType === 'koreanLabel'">한글표시사항</b-dropdown-item>
                            <b-dropdown-item @click="selectWorkType('quotation')"
                                :active="selectedWorkType === 'quotation'">등록세부정보</b-dropdown-item>
                        </b-dropdown>
                        <b-button variant="success" class="mr-2" @click="startProcessing"
                            :disabled="!hasSelectedProducts">
                            <span class="d-flex align-items-center">
                                작업 개시
                                <b-spinner small class="ml-2" v-if="isProcessing"></b-spinner>
                            </span>
                        </b-button>
                        <b-button variant="danger" @click="regRocketApi">
                            <span class="d-flex align-items-center">
                                등록 전송
                                <b-spinner small class="ml-2" v-if="isProcessing"></b-spinner>
                            </span>
                        </b-button>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div v-if="showspinner1" class="d-flex justify-content-center">
                    <div><img style="max-width:10%;" :src="require('../../../assets/images/small/loading.gif')">{{
                        loadingtext }}</div>
                </div>
            </div>

            <!-- 기본세팅 컴포넌트 -->
            <div class="col-lg-12 mb-3">
                <RocketSettingsPanel v-model="showSettings" :default-settings="defaultSettings"
                    @settings-saved="handleSettingsSaved" />
            </div>

            <!-- 검색 필터 -->
            <div class="col-lg-12">
                <card>
                    <div class="p-2">
                        <form>
                            <div class="row align-items-center">
                                <div class="col-md-3">
                                    <div class="d-flex align-items-center w-100">
                                        <div class="custom-control custom-radio custom-control-inline mb-0 ml-4">
                                            <input type="radio" id="customRadio7" name="customRadio-2"
                                                class="custom-control-input" v-model="selectdate" value="today"
                                                @change="changedate()">
                                            <label class="custom-control-label" for="customRadio7">오늘</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline mb-0">
                                            <input type="radio" id="customRadio8" name="customRadio-2"
                                                class="custom-control-input" v-model="selectdate" value="week"
                                                @change="changedate()">
                                            <label class="custom-control-label" for="customRadio8">7일</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline mb-0">
                                            <input type="radio" id="customRadio9" name="customRadio-2"
                                                class="custom-control-input" v-model="selectdate" value="month"
                                                @change="changedate()">
                                            <label class="custom-control-label" for="customRadio9">1개월</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="d-flex align-items-center w-100">
                                        <select class="custom-select w-50" style="width:80px; height:38px"
                                            v-model="selectoption">
                                            <option>등록일</option>
                                        </select>
                                        <b-form-datepicker id="example-datepicker1" size="sm" v-model="startdate"
                                            class="mx-2 w-100" style="width:130px"></b-form-datepicker>
                                        <span>~</span>
                                        <b-form-datepicker id="example-datepicker2" size="sm" v-model="enddate"
                                            class="mx-2 w-100" style="width:130px"></b-form-datepicker>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <button type="button" class="btn btn-primary" @click="search">
                                        검색
                                        <b-spinner small v-if="showspinner" class="ml-1"></b-spinner>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </card>
            </div>

            <div class="col-lg-12">
                <!-- 상품 리스트 테이블 -->
                <div class="table-responsive">
                    <CDataTable :headers="headers" :items="products" :items-per-page="10" :pagination="true"
                        :show-select="true" v-model="selectedItems" hover sorter :itemKey="'registrationId'">
                        <!-- 등록번호 -->
                        <template v-slot:registrationId="{ item }">
                            <div class="text-center" @click="editProduct(item)">
                                {{ item.registrationId }}
                            </div>
                        </template>

                        <!-- 상품 이미지 -->
                        <template v-slot:productImage="{ item }">
                            <div class="d-flex align-items-center justify-content-center" @click="editProduct(item)">
                                <img :src="item.productImage" class="img-fluid rounded avatar-50"
                                    :alt="item.productName">
                            </div>
                        </template>

                        <!-- 상품명 -->
                        <template v-slot:productName="{ item }">
                            <div @click="editProduct(item)">
                                <div class="mb-1">
                                    <!-- SEO 설정이 완료된 경우 SEO 상품명 사용 -->
                                    <span v-if="item.seoStatus === 'completed' && item.seo && item.seo.productName">
                                        {{ item.seo.productName }}
                                    </span>
                                    <span v-else>
                                        {{ item.productName }}
                                    </span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <b-badge variant="primary" class="cursor-pointer" @click="toggleOptions(item)">
                                        옵션 {{ item.options.length }}개
                                    </b-badge>
                                    <div class="ml-2">
                                        <b-badge variant="light">
                                            {{ item.productFeature }}
                                        </b-badge>
                                    </div>
                                    <div class="ml-2">
                                        <b-badge variant="success">
                                            {{ item.targetKeyword }}
                                        </b-badge>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-slot:구매링크="{ item }">
                            <div class="text-center">
                                <a :href="item.구매링크" target="_blank">
                                    <b-icon icon="link" scale="2" variant="primary"></b-icon>
                                </a>
                            </div>
                        </template>

                        <!-- SEO설정 -->
                        <template v-slot:seoStatus="{ item }">
                            <div class="text-center" @click="editProduct(item)">
                                <b-badge :variant="getStatusVariant(getWorkStatus(item, 'seoStatus'))"
                                    class="rocket-badge-pill"
                                    :class="{ 'pulsating': getWorkStatus(item, 'seoStatus') === 'processing' }">
                                    {{ getStatusText(item.seoStatus) }}
                                    <b-spinner small v-if="getWorkStatus(item, 'seoStatus') === 'processing'"
                                        class="ml-1"></b-spinner>
                                </b-badge>
                            </div>
                        </template>

                        <!-- 가격설정 -->
                        <template v-slot:pricingStatus="{ item }">
                            <div class="text-center" @click="editProduct(item)">
                                <b-badge :variant="getStatusVariant(getWorkStatus(item, 'pricingStatus'))"
                                    class="rocket-badge-pill"
                                    :class="{ 'pulsating': getWorkStatus(item, 'pricingStatus') === 'processing' }">
                                    {{ getStatusText(item.pricingStatus) }}
                                    <b-spinner small v-if="getWorkStatus(item, 'pricingStatus') === 'processing'"
                                        class="ml-1"></b-spinner>
                                </b-badge>
                            </div>
                        </template>

                        <!-- 대표이미지 상태 -->
                        <template v-slot:mainImageStatus="{ item }">
                            <div class="text-center" @click="editProduct(item)">
                                <b-badge :variant="getStatusVariant(getWorkStatus(item, 'mainImageStatus'))"
                                    class="rocket-badge-pill"
                                    :class="{ 'pulsating': getWorkStatus(item, 'mainImageStatus') === 'processing' }">
                                    {{ getStatusText(item.mainImageStatus) }}
                                    <b-spinner small v-if="getWorkStatus(item, 'mainImageStatus') === 'processing'"
                                        class="ml-1"></b-spinner>
                                </b-badge>
                            </div>
                        </template>

                        <!-- 추가이미지 상태 -->
                        <template v-slot:additionalImageStatus="{ item }">
                            <div class="text-center" @click="editProduct(item)">
                                <b-badge :variant="getStatusVariant(getWorkStatus(item, 'additionalImageStatus'))"
                                    class="rocket-badge-pill"
                                    :class="{ 'pulsating': getWorkStatus(item, 'additionalImageStatus') === 'processing' }">
                                    {{ getStatusText(item.additionalImageStatus) }}
                                    <b-spinner small
                                        v-if="getWorkStatus(item, 'additionalImageStatus') === 'processing'"
                                        class="ml-1"></b-spinner>
                                </b-badge>
                            </div>
                        </template>

                        <!-- 상세이미지 상태 -->
                        <template v-slot:detailImageStatus="{ item }">
                            <div class="text-center" @click="editProduct(item)">
                                <b-badge :variant="getStatusVariant(getWorkStatus(item, 'detailImageStatus'))"
                                    class="rocket-badge-pill"
                                    :class="{ 'pulsating': getWorkStatus(item, 'detailImageStatus') === 'processing' }">
                                    {{ getStatusText(item.detailImageStatus) }}
                                    <b-spinner small v-if="getWorkStatus(item, 'detailImageStatus') === 'processing'"
                                        class="ml-1"></b-spinner>
                                </b-badge>
                            </div>
                        </template>

                        <template v-slot:sizeChartStatus="{ item }">
                            <div class="text-center" @click="editProduct(item)">
                                <b-badge
                                    :variant="getStatusVariant(getWorkStatus(item, 'sizeChartStatus', isSizeChartRequired(item)))"
                                    class="rocket-badge-pill"
                                    :class="{ 'pulsating': getWorkStatus(item, 'sizeChartStatus') === 'processing' }">
                                    {{ getStatusText(isSizeChartRequired(item) ? item.sizeChartStatus : 'disabled') }}
                                    <b-spinner small v-if="getWorkStatus(item, 'sizeChartStatus') === 'processing'"
                                        class="ml-1"></b-spinner>
                                </b-badge>
                            </div>
                        </template>

                        <!-- 한글표시사항 -->
                        <template v-slot:koreanLabelStatus="{ item }">
                            <div class="text-center" @click="editProduct(item)">
                                <b-badge :variant="getStatusVariant(getWorkStatus(item, 'koreanLabelStatus'))"
                                    class="rocket-badge-pill"
                                    :class="{ 'pulsating': getWorkStatus(item, 'koreanLabelStatus') === 'processing' }">
                                    {{ getStatusText(item.koreanLabelStatus) }}
                                    <b-spinner small v-if="getWorkStatus(item, 'koreanLabelStatus') === 'processing'"
                                        class="ml-1"></b-spinner>
                                </b-badge>
                            </div>
                        </template>

                        <!-- 견적서세부정보 -->
                        <template v-slot:quotationStatus="{ item }">
                            <div class="text-center" @click="editProduct(item)">
                                <b-badge :variant="getStatusVariant(getWorkStatus(item, 'quotationStatus'))"
                                    class="rocket-badge-pill"
                                    :class="{ 'pulsating': getWorkStatus(item, 'quotationStatus') === 'processing' }">
                                    {{ getStatusText(item.quotationStatus) }}
                                    <b-spinner small v-if="getWorkStatus(item, 'quotationStatus') === 'processing'"
                                        class="ml-1"></b-spinner>
                                </b-badge>
                            </div>
                        </template>

                        <!-- 관리 버튼 -->
                        <template v-slot:actions="{ item }">
                            <div class="d-flex align-items-center justify-content-center">
                                <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="삭제"
                                    href="javascript:void(0)" @click="deleteProduct(item)">
                                    <i class="ri-delete-bin-line mr-0"></i>
                                </a>
                            </div>
                        </template>
                    </CDataTable>
                </div>
            </div>
        </div>

        <!-- 상품 추가 모달 -->
        <b-modal id="addProductModal" title="로켓배송 상품 추가" @ok="handleAddProduct" ok-title="추가" cancel-title="취소">
            <b-form>
                <b-form-group label="1688 상품 링크" label-for="link-input" :invalid-feedback="linkError"
                    :valid-feedback="validLinkFeedback" :state="linkValidation">
                    <b-form-input id="link-input" autocomplete="off" v-model="newProduct.link"
                        placeholder="1688 상품 링크를 입력해주세요" :state="linkValidation" trim></b-form-input>
                </b-form-group>

                <b-form-group label="선택된 카테고리" label-for="category-info">
                    <b-form-textarea id="category-info" v-model="newProduct.categoryPathString" readonly rows="2"
                        class="bg-light"></b-form-textarea>
                    <small class="form-text text-muted">
                        <span v-if="newProduct.categoryPathString">선택된 카테고리: {{ newProduct.categoryPathString }}</span>
                        <span v-else class="text-warning">카테고리를 선택하려면 취소 후 다시 시도해주세요.</span>
                    </small>
                </b-form-group>

                <b-form-group label="상품특징" label-for="keyword-input">
                    <b-form-input id="keyword-input" autocomplete="off" v-model="newProduct.productFeature"
                        placeholder="상품특징을 입력해주세요" trim></b-form-input>
                    <small class="form-text text-muted">상품의 주요특징을 입력해주세요. (예. 인조가죽)</small>
                </b-form-group>

                <b-form-group label="타겟 키워드" label-for="keyword-input">
                    <b-form-input id="keyword-input" autocomplete="off" v-model="newProduct.keyword"
                        placeholder="타겟 키워드를 입력해주세요" trim></b-form-input>
                    <small class="form-text text-muted">주요 타겟 키워드를 입력해주세요. (예. 크로스백)</small>
                </b-form-group>
            </b-form>
        </b-modal>

        <!-- 옵션 정보 모달 -->
        <RocketOptionsModal v-model="showOptionsModal" :selected-product="selectedProduct"
            @option-deleted="handleOptionDeleted" @open-wizard="handleOpenWizard" />

        <!-- 작업 마법사 모달 -->
        <ProductWizardModal v-if="selectedProduct" ref="productWizard" :default-settings="defaultSettings"
            @auto-save="updateProduct" @update-option-image="updateOptionImage" @closed="handleWizardClosed" />

        <!-- 카테고리 선택 모달 추가 -->
        <b-modal id="categorySelectionModal" size="xl" title="카테고리 선택" hide-footer>
            <div class="row">
                <!-- 카테고리 검색 -->
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label for="categorySearch">카테고리 검색:</label>
                        <div class="input-group">
                            <input 
                                type="text" 
                                class="form-control" 
                                id="categorySearch" 
                                v-model="categorySearchTerm" 
                                placeholder="카테고리 이름으로 검색" 
                                @input="debounceCategorySearch">
                            <div class="input-group-append">
                                <button 
                                    class="btn btn-primary" 
                                    type="button" 
                                    @click="searchCategories" 
                                    :disabled="searchingCategories || !categorySearchTerm.trim()">
                                    <i class="ri-search-line mr-0" v-if="!searchingCategories"></i>
                                    <b-spinner small v-else></b-spinner>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- 검색 결과 -->
                    <div v-if="categorySearchResults.length > 0" class="mt-2 category-search-results">
                        <h6>검색 결과:</h6>
                        <div class="list-group">
                            <button 
                                v-for="category in categorySearchResults" 
                                :key="category.internalCategoryCode"
                                class="list-group-item list-group-item-action"
                                @click="selectSearchedCategory(category)">
                                <span>{{ category.name }}</span>
                                <small class="d-block text-muted">{{ category.fullPathName }}</small>
                                <span v-if="category.leafCategory" class="float-right badge badge-success">최종</span>
                            </button>
                        </div>
                    </div>
                    
                    <div v-if="noSearchResults && lastSearchTerm" class="alert alert-warning mt-2">
                        "{{ lastSearchTerm }}"에 대한 검색 결과가 없습니다.
                    </div>
                </div>

                <div class="col-12 mb-3">
                    <p>등록할 상품의 카테고리를 선택해주세요. 단계별로 선택하면 하위 카테고리가 표시됩니다.</p>
                    <div class="d-flex flex-wrap">
                        <span v-for="(category, index) in selectedCategoryPath" :key="index"
                            class="d-flex align-items-center category-path-badge">
                            <span v-if="index > 0" class="mx-1">></span>
                            <b-badge variant="info" class="p-2">{{ category.displayItemCategoryDto.name }}</b-badge>
                        </span>
                    </div>
                </div>

                <!-- 카테고리 선택 영역 -->
                <div class="col-12">
                    <div class="row">
                        <!-- 동적으로 카테고리 컬럼 생성 -->
                        <div class="col-md-3 mb-3" v-for="(categories, depthIndex) in filteredCategorySelections"
                            :key="'depth-' + depthIndex">
                            <div class="card h-100">
                                <div class="card-header bg-light">
                                    <h6 class="mb-0">{{ depthIndex + 1 }}단계 카테고리</h6>
                                </div>
                                <div class="card-body p-0" style="max-height: 400px; overflow-y: auto;">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item cursor-pointer" v-for="category in categories"
                                            :key="category.displayItemCategoryDto.displayItemCategoryCode"
                                            :class="{ 'active': selectedCategoryPath[depthIndex] && selectedCategoryPath[depthIndex].displayItemCategoryDto.displayItemCategoryCode === category.displayItemCategoryDto.displayItemCategoryCode }"
                                            @click="selectCategory(category, depthIndex)">
                                            {{ category.displayItemCategoryDto.name }}
                                            <!-- 해당 카테고리가 최종 카테고리인지 표시 (leaf가 true인 경우) -->
                                            <span v-if="category.leaf" class="float-right badge badge-success">최종</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 버튼 영역 -->
                <div class="col-12 mt-4 text-right">
                    <b-button variant="secondary" class="mr-2" @click="$bvModal.hide('categorySelectionModal')">
                        취소
                    </b-button>
                    <b-button variant="primary" @click="completeSelection">
                        선택 완료
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import CDataTable from '@/components/common/CDataTable';
import axios from 'axios';
import ProductWizardModal from '../../../components/AIRocket/ProductWizardModal.vue';
import RocketSettingsPanel from '../../../components/AIRocket/RocketSettingsPanel.vue';
import RocketOptionsModal from '../../../components/AIRocket/RocketOptionsModal.vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import workflowService from '../../../services/workflowService';

export default {
    name: 'RocketProductRegistration',
    components: {
        CDataTable,
        ProductWizardModal,
        RocketSettingsPanel,
        RocketOptionsModal
    },
    data() {
        return {
            // 기본 상태 변수
            selectedItems: [],
            showSettings: false,
            showspinner1: false,
            showspinner: false,
            loadingtext: '',
            isProcessing: false,

            // 기본 설정
            defaultSettings: {
                brand: '',
                manufacturer: '',
                transactionType: '기타 도소매업자',
                importStatus: '수입대상아님',
                asInfo: '',
                exchangeRate: 350,
                marginRate: 40,
                coupangMarginRate: 40,
                roundUnit: 100,
                enableBundling: false,
                bundlingType: 'marginAmount',
                minMarginAmount: 3000,
                minCoupangMarginAmount: 3000,
                totalSKUsInBox: 1,          // 박스 내 SKU 수량
                imageSettings: {
                    useTopImage: false,
                    topImageUrl: '',
                    useBottomImage: false,
                    bottomImageUrl: '',
                    translateImages: true,
                    addCopyright: true
                }
            },

            // 테이블 헤더 설정
            headers: [
                {
                    text: '등록번호',
                    value: 'registrationId',
                    align: 'center',
                    sortable: true,
                    width: 120,
                    isSlot: true
                },
                {
                    text: '상품이미지',
                    value: 'productImage',
                    align: 'center',
                    sortable: false,
                    width: 100,
                    isSlot: true
                },
                {
                    text: '상품명',
                    value: 'productName',
                    align: 'left',
                    sortable: true,
                    width: 200,
                    isSlot: true
                },
                {
                    text: '구매링크',
                    value: '구매링크',
                    align: 'left',
                    sortable: true,
                    width: 50,
                    isSlot: true
                },
                {
                    text: 'SEO설정',
                    value: 'seoStatus',
                    align: 'center',
                    sortable: true,
                    width: 100,
                    isSlot: true
                },
                {
                    text: '가격설정',
                    value: 'pricingStatus',
                    align: 'center',
                    sortable: true,
                    width: 100,
                    isSlot: true
                },
                {
                    text: '대표이미지',
                    value: 'mainImageStatus',
                    align: 'center',
                    sortable: true,
                    width: 100,
                    isSlot: true
                },
                {
                    text: '추가이미지',
                    value: 'additionalImageStatus',
                    align: 'center',
                    sortable: true,
                    width: 100,
                    isSlot: true
                },
                {
                    text: '상세이미지',
                    value: 'detailImageStatus',
                    align: 'center',
                    sortable: true,
                    width: 100,
                    isSlot: true
                },
                {
                    text: '사이즈표',
                    value: 'sizeChartStatus',
                    align: 'center',
                    sortable: true,
                    width: 100,
                    isSlot: true
                },
                {
                    text: '한글표시사항',
                    value: 'koreanLabelStatus',
                    align: 'center',
                    sortable: true,
                    width: 100,
                    isSlot: true
                },
                {
                    text: '등록세부정보',
                    value: 'quotationStatus',
                    align: 'center',
                    sortable: true,
                    width: 120,
                    isSlot: true
                },
                {
                    text: '관리',
                    value: 'actions',
                    align: 'center',
                    sortable: false,
                    width: 120,
                    isSlot: true
                }
            ],

            // 옵션 관련 상태
            showOptionsModal: false,

            // 상품 추가 관련 상태
            linkError: '',
            validLinkFeedback: '',
            newProduct: {
                link: '',
                keyword: '',
                brand: ''
            },

            // 검색 관련 상태
            selectdate: 'today',
            startdate: this.getTodaywith(),
            enddate: this.getTodaywith(),
            selectoption: '등록일',
            selectedWorkType: 'all', // 기본값으로 전체 작업 선택
            extensionRequestId: null,
            extensionResponseData: null,
            extensionRequestPending: false,
            pendingProductData: null,
            categoryCodes: [],
            selectedCategoryPath: [],  // 추가
            categoryDepth: 0,  // 추가
            categorySelections: [],
            createdProductId: null,          // 생성된 상품 ID
            pendingOptionCount: 0,           // 처리 예정 옵션 수
            completedOptionCount: 0,         // 처리 완료된 옵션 수
            savedRocketRegInfo: null,
            delay: 500,
            
            // 카테고리 검색 관련 속성
            categorySearchTerm: '',
            categorySearchResults: [],
            searchingCategories: false,
            noSearchResults: false,
            lastSearchTerm: '',
            searchDebounceTimeout: null,
            searchDebounceDelay: 500,
        };
    },

    mounted() {
        // 기본 설정 로드
        this.loadDefaultSettings();
        this.search();

        window.addEventListener('message', this.handleExtensionMessage);
    },
    beforeDestroy() {
        // 크롬 확장 프로그램 메시지 리스너 제거
        window.removeEventListener('message', this.handleExtensionMessage);
    },
    watch: {
        // 기존 watch 항목들...

        // rocketProducts의 변경을 감시
        '$store.state.rocketProducts': {
            handler(newProducts) {
                // 선택된 상품 ID를 유지하면서 최신 데이터로 업데이트
                this.selectedItems = this.selectedItems.map(item => {
                    const updatedProduct = newProducts.find(p => p.registrationId === item.registrationId);
                    return updatedProduct || item; // 찾지 못하면 기존 항목 유지
                });
            },
            deep: true // 깊은 감시 (중첩 객체 변경 감지)
        }
    },
    computed: {
        // Vuex 상태 매핑
        ...mapState({
            products: state => state.rocketProducts,
            vuexSelectedProduct: state => state.selectedRocketProduct,
            processing: state => state.processing,
            progressMessage: state => state.progressMessage
        }),

        // 선택된 상품
        selectedProduct: {
            get() {
                return this.vuexSelectedProduct;
            },
            set(value) {
                this.SET_SELECTED_ROCKET_PRODUCT(value);
            }
        },

        // 링크 유효성 검사
        linkValidation() {
            if (!this.newProduct.link) return null;
            return this.newProduct.link.includes('detail.1688.com');
        },

        // 선택된 상품이 있는지 확인
        hasSelectedProducts() {
            return this.selectedItems.length > 0;
        },

        // 작업 상태 필드 목록
        workStatusFields() {
            return [
                'seoStatus',
                'pricingStatus',
                'mainImageStatus',
                'additionalImageStatus',
                'detailImageStatus',
                'sizeChartStatus',
                'koreanLabelStatus',
                'quotationStatus'
            ];
        },
        filteredCategorySelections() {
            return this.categorySelections.filter((categories) =>
                categories && categories.length > 0
            );
        }
    },

    methods: {
        // Vuex 액션과 뮤테이션 매핑
        ...mapActions([
            'searchRocketProducts',
            'updateRocketProduct',
            'updateOptionImage',
            'processMainImageForAllOptions',
            'testRequestExtension'
        ]),

        ...mapMutations([
            'SET_SELECTED_ROCKET_PRODUCT',
            'SET_SELECTED_OPTION',
            'SET_SELECTED_WORK_TYPE'
        ]),

        async regRocketApi() {
            // 선택된 상품이 있는지 확인
            if (this.selectedItems.length === 0) {
                this.$bvToast.toast('등록 전송할 상품을 선택해주세요.', {
                    title: '알림',
                    variant: 'warning',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
                return;
            }

            // // 모든 필수 작업이 완료되었는지 확인
            // const incompleteProducts = this.selectedItems.filter(product => {
            //     // 필수 작업 목록
            //     const requiredTasks = ['seoStatus', 'mainImageStatus', 'additionalImageStatus', 'detailImageStatus'];

            //     // 하나라도 완료되지 않은 작업이 있으면 불완전한 상품으로 간주
            //     return requiredTasks.some(task => {
            //         const status = this.getWorkStatus(product, task);
            //         return status !== 'completed' && status !== 'partially_completed';
            //     });
            // });

            // if (incompleteProducts.length > 0) {
            //     // 미완료 상품이 있으면 경고 메시지 표시 및 계속 진행 여부 확인
            //     if (!confirm(`${incompleteProducts.length}개 상품의 필수 작업이 완료되지 않았습니다. 계속 진행하시겠습니까?`)) {
            //         return;
            //     }
            // }

            // 로딩 상태 시작
            this.isProcessing = true;
            this.loadingtext = '쿠팡 상품 등록 문서 업데이트 중...';
            this.showspinner1 = true;

            try {
                // 선택된 상품에 대해 쿠팡 문서 업데이트 수행
                for (const product of this.selectedItems) {
                    console.log(product)
                    // 프로세스 메시지 업데이트
                    this.loadingtext = `상품 ${product.registrationId} 문서 업데이트 중...`;

                    // 옵션이 있는 경우 옵션별 처리
                    if (product.options && product.options.length > 0) {
                        for (let i = 0; i < product.options.length; i++) {
                            const option = product.options[i];

                            // coupangDoc이 있는지 확인
                            if (option.coupangDoc && option.coupangDoc.docId) {
                                this.loadingtext = `상품 ${product.registrationId} - 옵션 ${i + 1}/${product.options.length} 업데이트 중...`;

                                console.log('coupangDoc:', option.coupangDoc)
                                try {
                                    // 확장 프로그램에 직접 메시지 전송
                                    window.postMessage({
                                        type: 'UPDATE_ROCKET_REG_DOC',
                                        id: `update_${product.registrationId}_${option.optionNumber}_${Date.now()}`,
                                        data: {
                                            docId: option.coupangDoc.docId,
                                            body: option.coupangDoc,
                                            suppliercode: this.$store.state.user.suppliercode,
                                            delay: this.delay
                                        }
                                    }, '*');

                                    // 요청 간 지연 시간 추가
                                    await new Promise(resolve => setTimeout(resolve, 500));
                                } catch (optError) {
                                    console.error(`옵션 ${option.optionNumber} 업데이트 중 오류:`, optError);
                                    // 오류가 있어도 다음 옵션 계속 처리
                                }
                            }
                        }
                    }
                    // 옵션이 없는 단일 상품인 경우
                    else if (product.coupangDoc && product.coupangDoc.docId) {
                        try {
                            // 확장 프로그램에 직접 메시지 전송
                            window.postMessage({
                                type: 'UPDATE_ROCKET_REG_DOC',
                                id: `update_${product.registrationId}_${Date.now()}`,
                                data: {
                                    docId: product.coupangDoc.docId,
                                    body: product.coupangDoc,
                                    suppliercode: this.$store.state.user.suppliercode,
                                    delay: this.delay
                                }
                            }, '*');
                        } catch (prodError) {
                            console.error(`상품 ${product.registrationId} 업데이트 중 오류:`, prodError);
                        }
                    }

                    // 상품 간 지연 시간 추가
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }

                // 성공 메시지 표시
                this.$bvToast.toast('상품 등록이 완료되었습니다. \nSupplier Hub의 상품 > 개별 상품 등록 > 중간 저장 불러오기에서 확인해주세요', {
                    title: '성공',
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
            } catch (error) {
                console.error('쿠팡 문서 업데이트 중 오류:', error);
                this.$bvToast.toast('상품 등록 중 오류가 발생했습니다.', {
                    title: '오류',
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
            } finally {
                // 로딩 상태 종료
                this.isProcessing = false;
                this.showspinner1 = false;
                this.loadingtext = '';
            }
        },
        /**
 * 선택된 작업 타입에 따라 드롭다운 표시 텍스트 반환
 * @param {string} workType - 선택된 작업 타입
 * @returns {string} 표시할 텍스트
 */
        getWorkTypeText(workType) {
            const workTypeNames = {
                'all': '전체 작업',
                'seo': 'SEO설정',
                'pricing': '가격설정',
                'mainImage': '대표이미지',
                'additionalImage': '추가이미지',
                'detailImage': '상세이미지',
                'sizeChart': '사이즈표',
                'koreanLabel': '한글표시사항',
                'quotation': '견적서세부정보'
            };

            return workTypeNames[workType] || '작업 선택';
        },
        selectWorkType(type) {
            this.selectedWorkType = type;
        },

        // 설정 토글
        toggleSettings() {
            this.showSettings = !this.showSettings;
        },

        // 기본 설정 로드
        loadDefaultSettings() {
            if (this.$store.state.settings[0]?.defaultSettings) {
                this.defaultSettings = this.$store.state.settings[0].defaultSettings;
            }
        },

        // 설정 저장 처리
        handleSettingsSaved(settings) {
            this.defaultSettings = settings;
        },

        handleOpenWizard() {
            console.log('open-wizard 이벤트 수신');
            // ProductWizardModal 열기
            if (this.$refs.productWizard) {
                this.$refs.productWizard.open();
            }
        },

        // 검색 관련 메서드
        async search() {
            this.showspinner = true;
            try {
                const start = this.gettimestamp(this.startdate);
                const end = this.gettimestamp(this.enddate) + (1000 * 60 * 60 * 24) - 1;
                console.log('start:', start, 'end:', end)
                // Vuex 액션 호출
                await this.searchRocketProducts({ start, end });
            } catch (e) {
                console.error('등록상품 불러오기 실패', e);
                alert('등록상품 불러오기 실패');
            } finally {
                this.showspinner = false;
            }
        },

        // 날짜 포맷 관련 메서드
        getdate(timestamp) {
            var date = new Date(timestamp);
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },

        changedate() {
            if (this.selectdate == 'today') {
                this.startdate = this.getTodaywith();
                this.enddate = this.getTodaywith();
            } else if (this.selectdate == 'week') {
                const now = new Date(this.getTodaywith());
                const target = now - 1000 * 60 * 60 * 24 * 7;
                this.startdate = this.getdate(target);
                this.enddate = this.getTodaywith();
            } else if (this.selectdate == 'month') {
                const now = new Date(this.getTodaywith());
                const target = now - 1000 * 60 * 60 * 24 * 30;
                this.startdate = this.getdate(target);
                this.enddate = this.getTodaywith();
            }
        },

        getTodaywith() {
            var date = new Date();
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },

        gettimestamp(date) {
            return new Date(date).getTime();
        },

        // 상품 추가 관련 메서드
        addProduct() {
            // 기본정보 세팅 확인
            if (!this.defaultSettings.brand || !this.defaultSettings.manufacturer || !this.defaultSettings.asInfo) {
                this.$bvToast.toast('기본 등록 정보를 먼저 설정해주세요.', {
                    title: '알림',
                    variant: 'warning',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
                this.showSettings = true; // 기본세팅 패널 자동 열기
                return;
            }

            // 카테고리 선택 모달 표시 전 카테고리 관련 정보 초기화
            this.showCategorySelectionModal();
        },

        // 카테고리 선택 모달 표시
        showCategorySelectionModal() {
            // 카테고리 초기화
            this.categoryCodes = [];
            this.selectedCategoryPath = [];
            this.categoryDepth = 0;
            this.categorySelections = [];
            this.categorySearchTerm = '';
            this.categorySearchResults = [];
            this.noSearchResults = false;

            // 확장 프로그램에 메시지 전송 - 루트 카테고리 요청
            const message = {
                type: 'GET_CATEGORY_INFO',
                data: {
                    categoryCode: "",
                    suppliercode: this.$store.state.user.suppliercode
                }
            };

            console.log("루트 카테고리 요청:", message);
            window.postMessage(message, '*');
        },
        
        /**
         * 카테고리 검색 디바운싱 처리
         */
        debounceCategorySearch() {
            // 기존 타임아웃 제거
            if (this.searchDebounceTimeout) {
                clearTimeout(this.searchDebounceTimeout);
            }
            
            // 새 타임아웃 설정
            this.searchDebounceTimeout = setTimeout(() => {
                if (this.categorySearchTerm.trim().length >= 2) {
                    this.searchCategories();
                }
            }, this.searchDebounceDelay);
        },

        /**
         * 카테고리 검색 실행
         */
        searchCategories() {
            // 검색어가 너무 짧으면 실행하지 않음
            if (this.categorySearchTerm.trim().length < 2) {
                return;
            }
            
            // 검색 상태 설정
            this.searchingCategories = true;
            this.lastSearchTerm = this.categorySearchTerm.trim();
            this.noSearchResults = false;
            
            // 확장 프로그램에 메시지 전송
            const message = {
                type: 'SEARCH_CATEGORIES',
                data: {
                    keyword: this.categorySearchTerm.trim(),
                    suppliercode: this.$store.state.user.suppliercode
                }
            };
            
            console.log("카테고리 검색 요청:", message);
            window.postMessage(message, '*');
        },

        /**
         * 검색 결과에서 카테고리 선택
         * @param {Object} category - 선택된 카테고리 객체
         */
        selectSearchedCategory(category) {
            console.log('검색 결과에서 카테고리 선택:', category);
            
            // 검색 상태 초기화
            this.categorySearchResults = [];
            this.categorySearchTerm = '';
            
            // 선택한 카테고리 정보 저장 (API 응답 구조에 맞게 수정)
            this.newProduct.categoryCode = category.internalCategoryCode.toString();
            this.newProduct.categoryPath = category.fullPathName;
            
            // 카테고리 경로 문자열 생성 (UI 표시용)
            this.newProduct.categoryPathString = category.fullPathName;
            
            // 카테고리 모달 닫기
            this.$bvModal.hide('categorySelectionModal');
            
            // 상품 추가 모달 열기 (completeSelection 메서드와 동일하게 처리)
            this.actionAddProduct();
        },

        // 카테고리 선택 핸들러
        selectCategory(category, depth) {
            console.log(`${depth}단계 카테고리 선택:`, category);

            // 선택된 카테고리 경로 업데이트 (Vue의 반응성을 위해 $set 사용)
            this.$set(this.selectedCategoryPath, depth, category);

            // 현재 depth 이후의 경로 초기화
            const newPath = this.selectedCategoryPath.slice(0, depth + 1);
            this.selectedCategoryPath = newPath;

            // 다음 단계 카테고리 요청
            this.categoryDepth = depth + 1;

            // leaf 속성이 true인 경우 더 이상 하위 카테고리가 없음
            if (category.leaf === true) {
                // 현재 카테고리가 최종 카테고리인 경우 하위 카테고리 요청하지 않음
                console.log('최종 카테고리 선택됨:', category.displayItemCategoryDto.name);

                // 더 이상의 하위 카테고리를 보여주지 않도록 초기화
                for (let i = this.categoryDepth; i < this.categorySelections.length; i++) {
                    this.$set(this.categorySelections, i, []);
                }
            } else {
                // 자식 카테고리 요청
                this.additionalCategoryCode(category.displayItemCategoryDto.displayItemCategoryCode);
            }
        },

        // 카테고리 선택 완료
        completeSelection() {
            // 마지막으로 선택된 카테고리 정보 가져오기
            const lastSelectedCategory = this.selectedCategoryPath[this.selectedCategoryPath.length - 1];

            if (!lastSelectedCategory) {
                this.$bvToast.toast('카테고리를 선택해주세요.', {
                    title: '알림',
                    variant: 'warning',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
                return;
            }

            // 선택된 카테고리가 leaf인지 확인
            if (lastSelectedCategory.leaf === false) {
                // 최종 카테고리가 아닌 경우 확인 메시지 표시
                if (!confirm('선택한 카테고리는 최종 카테고리가 아닙니다. 계속 진행하시겠습니까?')) {
                    return;
                }
            }

            // 선택된 카테고리 정보 저장
            this.newProduct.categoryCode = lastSelectedCategory.displayItemCategoryDto.displayItemCategoryCode;
            this.newProduct.categoryPath = lastSelectedCategory.displayItemCategoryDto.categoryPath;

            // 카테고리 경로 문자열 생성 (UI 표시용)
            const categoryPathNames = this.selectedCategoryPath.map(cat =>
                cat.displayItemCategoryDto.name
            ).join(' > ');
            this.newProduct.categoryPathString = categoryPathNames;

            // 카테고리 선택 모달 닫기
            this.$bvModal.hide('categorySelectionModal');

            // 상품 추가 모달 열기
            this.actionAddProduct();
        },

        additionalCategoryCode(code) {
            // 확장 프로그램에 메시지 전송
            const message = {
                type: 'GET_CATEGORY_INFO',
                data: {
                    categoryCode: code,
                    suppliercode: this.$store.state.user.suppliercode
                }
            };

            console.log(message)

            window.postMessage(message, '*');
        },

        // actionAddProduct 메서드 수정
        actionAddProduct() {
            if (!this.newProduct) {
                this.newProduct = {
                    link: '',
                    keyword: '',
                    brand: this.defaultSettings.brand,
                    categoryPath: '',
                    categoryCode: '',
                    categoryPathString: ''
                };
            } else {
                // 기존 카테고리 정보 유지
                const categoryPath = this.newProduct.categoryPath || '';
                const categoryCode = this.newProduct.categoryCode || '';
                const categoryPathString = this.newProduct.categoryPathString || '';

                this.newProduct = {
                    link: '',
                    keyword: '',
                    brand: this.defaultSettings.brand,
                    categoryPath: categoryPath,
                    categoryCode: categoryCode,
                    categoryPathString: categoryPathString
                };
            }

            this.selectdate = 'today';
            this.search();

            this.$bvModal.show('addProductModal');
        },

        // 수정: handleAddProduct 메서드
        async handleAddProduct(bvModalEvent) {
            bvModalEvent.preventDefault();

            if (!this.newProduct.link) {
                this.linkError = '1688 상품 링크를 입력해주세요.';
                return;
            }

            if (!this.newProduct.keyword) {
                this.$bvToast.toast('타겟 키워드를 입력해주세요.', {
                    title: '알림',
                    variant: 'warning',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
                return;
            }

            try {
                // 로딩 표시
                this.$bvToast.toast('상품 정보를 가져오는 중입니다...', {
                    title: '진행 중',
                    variant: 'info',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });

                // 1688 상품정보 가져오기
                const productNo = this.newProduct.link.slice(
                    this.newProduct.link.search('offer/') + 6,
                    this.newProduct.link.search('.html')
                );

                const res = await axios.post('/api/aiPage/getProductInfo', [{ productId: parseInt(productNo) }]);
                const data = res.data.result[0].result.result;
                console.log(data)

                // 추가: 키워드를 기반으로 쿠팡 카테고리 및 상품등록필요정보 수집 시작
                this.requestProductInfoFromExtension(data, this.newProduct.keyword);

            } catch (error) {
                console.error('상품 추가 실패:', error);
                this.$bvToast.toast('상품 추가 중 오류가 발생했습니다. ' + error.message, {
                    title: '오류',
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
            }
        },

        // 수정 후 코드 - requestProductInfoFromExtension 메서드
        async requestProductInfoFromExtension(productData, keyword) {
            // 이미 요청 중인 경우 중복 요청 방지
            if (this.extensionRequestPending) {
                console.warn('이미 확장 프로그램에 요청 중입니다.');
                return;
            }

            // 옵션 정보 확인
            if (!productData.productSkuInfos || productData.productSkuInfos.length === 0) {
                // 옵션이 없는 경우 단일 상품으로 처리
                await this.requestSingleProductInfo(productData, keyword);
                return;
            }

            // 옵션이 있는 경우 각 옵션별로 요청
            this.pendingProductData = productData;
            this.extensionRequestPending = true;

            // 로딩 메시지 업데이트
            this.loadingtext = `쿠팡 카테고리 및 상품 등록 정보 수집 중... (옵션 ${productData.productSkuInfos.length}개)`;
            this.showspinner1 = true;

            // 옵션 처리를 위한 변수들
            this.pendingOptions = [];
            this.completedOptions = [];
            this.pendingOptionCount = productData.productSkuInfos.length;

            // 각 옵션별로 요청 처리
            for (let i = 0; i < productData.productSkuInfos.length; i++) {
                const sku = productData.productSkuInfos[i];

                // 옵션 이름 구성
                let optionName = '';
                if (sku.skuAttributes && sku.skuAttributes.length > 0) {
                    if (sku.skuAttributes[0]) {
                        optionName = sku.skuAttributes[0].valueTrans || sku.skuAttributes[0].value || '';
                    }
                    if (sku.skuAttributes[1]) {
                        if (optionName) optionName += ', ';
                        optionName += sku.skuAttributes[1].valueTrans || sku.skuAttributes[1].value || '';
                    }
                }

                // 옵션별 상품명 생성 (상품명 + ", " + 옵션명)
                const optionProductName = optionName ?
                    `${productData.subjectTrans || productData.subject}, ${optionName}` :
                    (productData.subjectTrans || productData.subject);

                // 옵션 정보 저장
                this.pendingOptions.push({
                    index: i,
                    sku: sku,
                    optionName: optionName,
                    optionProductName: optionProductName,
                    requestId: `product_info_option_${i}_${Date.now()}`,
                    completed: false
                });

                // 확장 프로그램에 메시지 전송
                const message = {
                    type: 'GET_PRODUCT_INFO',
                    id: `product_info_option_${i}_${Date.now()}`,
                    data: {
                        keyword: keyword,
                        productName: optionProductName, // 옵션별 상품명
                        productNameTrans: optionProductName, // 옵션별 상품명
                        productFeature: this.newProduct.productFeature,
                        supplierCode: this.$store.state.user.suppliercode,
                        categoryCode: this.newProduct.categoryCode,
                        categoryPathString: this.newProduct.categoryPathString,
                        optionIndex: i, // 옵션 인덱스 추가
                        isOption: true // 옵션 여부 표시
                    }
                };

                console.log(`옵션 ${i + 1}/${productData.productSkuInfos.length} 요청:`, message);

                // 동시에 여러 요청을 보내면 확장에서 처리하지 못할 수 있으므로 1초 간격으로 전송
                await new Promise(resolve => setTimeout(resolve, 1000));
                window.postMessage(message, '*');
            }
        },

        // 단일 상품 요청 처리를 위한 새 메서드
        async requestSingleProductInfo(productData, keyword) {
            // 요청 데이터 저장
            this.pendingProductData = productData;
            this.extensionRequestPending = true;

            // 요청 ID 생성
            this.extensionRequestId = 'product_info_single_' + Date.now();

            // 로딩 메시지 업데이트
            this.loadingtext = '쿠팡 카테고리 및 상품 등록 정보 수집 중...';
            this.showspinner1 = true;

            // 확장 프로그램에 메시지 전송
            const message = {
                type: 'GET_PRODUCT_INFO',
                id: this.extensionRequestId,
                data: {
                    keyword: keyword,
                    productName: productData.subject,
                    productNameTrans: productData.subjectTrans,
                    productFeature: this.newProduct.productFeature,
                    supplierCode: this.$store.state.user.suppliercode,
                    categoryCode: this.newProduct.categoryCode,
                    categoryPathString: this.newProduct.categoryPathString,
                    isOption: false // 옵션 아님
                }
            };

            console.log('단일 상품 요청:', message);
            window.postMessage(message, '*');
        },

        // 추가: 확장 프로그램 메시지 핸들러
        handleExtensionMessage(event) {
            console.log(event.data);

            // 메시지 형식 확인
            if (!event.data || typeof event.data !== 'object') return;
            
            // 카테고리 검색 응답 처리
            if (event.data.type === 'SEARCH_CATEGORIES_RESPONSE') {
                this.searchingCategories = false;
                
                if (Array.isArray(event.data.results) && event.data.results.length > 0) {
                    this.categorySearchResults = event.data.results;
                    this.noSearchResults = false;
                } else {
                    this.categorySearchResults = [];
                    this.noSearchResults = true;
                }
                return;
            }

            if (event.data.msg == 'CoupangRegAlert') {
                this.extensionRequestPending = false;
                this.showspinner1 = false;
                alert(event.data.res);
                return;
            }

            // GET_CATEGORY_INFO_RESPONSE 처리 부분 수정
            if (event.data.type == 'GET_CATEGORY_INFO_RESPONSE') {
                this.extensionRequestPending = false;
                this.showspinner1 = false;

                // 카테고리 정보 처리
                if (Array.isArray(event.data.categoryCode) && event.data.categoryCode.length > 0) {
                    // 현재 depth에 맞는 카테고리 목록 저장
                    // Vue의 반응성을 위해 $set 사용
                    if (!this.categorySelections[this.categoryDepth]) {
                        this.$set(this.categorySelections, this.categoryDepth, event.data.categoryCode);
                    } else {
                        this.$set(this.categorySelections, this.categoryDepth, event.data.categoryCode);
                    }

                    // 현재 depth 이후의 카테고리 정보는 초기화
                    for (let i = this.categoryDepth + 1; i < this.categorySelections.length; i++) {
                        this.$set(this.categorySelections, i, []);
                    }

                    console.log(this.categoryselections)

                    // 카테고리 선택 모달 표시
                    this.$bvModal.show('categorySelectionModal');
                } else {
                    // 마지막 카테고리인 경우 (더 이상 하위 카테고리가 없는 경우)
                    if (this.categoryDepth > 0 && this.selectedCategoryPath.length > 0) {
                        this.$bvToast.toast('선택한 카테고리의 하위 카테고리가 없습니다.', {
                            title: '알림',
                            variant: 'info',
                            solid: true,
                            toaster: 'b-toaster-bottom-right'
                        });
                    } else {
                        this.$bvToast.toast('카테고리 정보를 가져오는데 실패했습니다.', {
                            title: '오류',
                            variant: 'danger',
                            solid: true,
                            toaster: 'b-toaster-bottom-right'
                        });
                    }
                }
                return;
            }


            // 중요: PRODUCT_INFO_RESPONSE 처리 부분 수정
            if (event.data.type === 'PRODUCT_INFO_RESPONSE') {
                // 응답 데이터 저장
                console.log('확장 프로그램 응답 수신:', event.data);

                // 상품 객체가 이미 생성되었는지 확인
                const isFirstResponse = !this.createdProductId;

                // 첫 번째 응답인 경우: 상품 객체 생성
                if (isFirstResponse) {
                    this.extensionResponseData = event.data;

                    // 첫 번째 응답에서 rocketRegInfo 저장
                    if (event.data.rocketRegInfo) {
                        this.savedRocketRegInfo = event.data.rocketRegInfo;
                    }

                    // 상품 객체 생성 및 저장
                    if (this.pendingProductData) {
                        this.createProductObject(this.pendingProductData, this.extensionResponseData)
                            .then(createdProduct => {
                                // 생성된 상품 ID 저장
                                this.createdProductId = createdProduct.registrationId;

                                // 옵션 매핑을 위한 인덱스 초기화
                                this.pendingOptionCount = this.pendingProductData.productSkuInfos?.length || 0;
                                this.completedOptionCount = 1; // 첫 번째 응답 처리 완료

                                // 단일 상품이거나 모든 처리가 완료된 경우
                                if (this.pendingOptionCount <= 1) {
                                    // 요청 상태 초기화
                                    this.extensionRequestPending = false;
                                    this.showspinner1 = false;
                                    this.createdProductId = null;

                                    // 완료 메시지 표시
                                    this.$bvToast.toast('상품 등록이 완료되었습니다.', {
                                        title: '성공',
                                        variant: 'success',
                                        solid: true,
                                        toaster: 'b-toaster-bottom-right'
                                    });

                                    // 모달 닫기
                                    this.$bvModal.hide('addProductModal');
                                } else {
                                    // 로딩 메시지 업데이트
                                    this.loadingtext = `상품 등록 중... (${this.completedOptionCount}/${this.pendingOptionCount})`;
                                }
                            })
                            .catch(error => {
                                console.error('상품 생성 실패:', error);
                                this.$bvToast.toast('상품 추가 중 오류가 발생했습니다: ' + error.message, {
                                    title: '오류',
                                    variant: 'danger',
                                    solid: true,
                                    toaster: 'b-toaster-bottom-right'
                                });

                                // 요청 상태 초기화
                                this.extensionRequestPending = false;
                                this.showspinner1 = false;
                            });
                    }
                }
                // 추가 응답(옵션)인 경우: 기존 상품에 옵션 coupangDoc 추가
                else if (this.createdProductId) {
                    // 완료된 옵션 수 증가
                    this.completedOptionCount++;

                    // 로딩 메시지 업데이트
                    if (this.pendingOptionCount > 1) {
                        this.loadingtext = `상품 등록 중... (${this.completedOptionCount}/${this.pendingOptionCount})`;
                    }

                    // 생성된 상품 찾기
                    const createdProduct = this.products.find(p => p.registrationId === this.createdProductId);

                    // 상품을 찾은 경우 옵션 업데이트
                    if (createdProduct && createdProduct.options && createdProduct.options.length > 0) {
                        // 옵션 매칭을 위한 정보 확인 (옵션 인덱스나 SKU ID)
                        // 이 부분은 확장에서 보내주는 응답 형식에 따라 다르게 처리 필요
                        // 여기서는 응답마다 순차적으로 옵션에 할당한다고 가정
                        const optionIndex = this.completedOptionCount - 1;

                        if (optionIndex < createdProduct.options.length) {
                            const option = createdProduct.options[optionIndex];

                            // 옵션에 coupangDoc 할당
                            option.coupangDoc = this.initializeCoupangDoc(
                                event.data.createResult,
                                this.defaultSettings,
                                this.savedRocketRegInfo  // 첫 번째 응답에서 저장한 rocketRegInfo 전달
                            );

                            console.log(`옵션 ${optionIndex + 1} coupangDoc 설정:`, option.coupangDoc.docId);

                            // 상품 정보 업데이트
                            this.updateRocketProduct(createdProduct)
                                .catch(error => console.error('상품 업데이트 실패:', error));
                        }
                    }

                    // 모든 옵션 처리가 완료된 경우
                    if (this.completedOptionCount >= this.pendingOptionCount) {
                        // 요청 상태 초기화
                        this.extensionRequestPending = false;
                        this.showspinner1 = false;
                        this.createdProductId = null;

                        // 완료 메시지 표시
                        this.$bvToast.toast('상품 등록이 완료되었습니다.', {
                            title: '성공',
                            variant: 'success',
                            solid: true,
                            toaster: 'b-toaster-bottom-right'
                        });

                        // 모달 닫기
                        this.$bvModal.hide('addProductModal');
                    }
                }
            }

            if (event.data.type == 'UPDATE_ROCKET_REG_RESPONSE') {
                if (!event.data.success) {
                    this.$bvToast.toast(event.data.result, {
                        title: '오류',
                        variant: 'danger',
                        solid: true,
                        toaster: 'b-toaster-bottom-right'
                    });
                } else {
                    this.$bvToast.toast(event.data.text, {
                        title: '정상',
                        variant: 'success',
                        solid: true,
                        toaster: 'b-toaster-bottom-right'
                    });
                }
            }
        },

        // 옵션별 상품 생성 메서드
        async createMultipleOptionProduct(productData, optionsWithResponses) {
            try {
                const now = new Date().getTime();

                // 등록번호 생성
                const newRegistrationId = this.generateRegistrationId(now);

                // 브랜드명 설정
                const brandName = this.newProduct.brand || this.defaultSettings.brand;

                // 모든 옵션 정보 처리
                const allOptions = [];

                for (const optionWithResponse of optionsWithResponses) {
                    const { sku, optionName, optionProductName, responseData } = optionWithResponse;

                    // 가격 정보
                    const price = parseFloat(
                        (sku.fenxiaoPriceInfo && sku.fenxiaoPriceInfo.offerPrice)
                            ? sku.fenxiaoPriceInfo.offerPrice
                            : sku.consignPrice
                    );

                    // 옵션 번호 형식
                    const optionNumber = `${newRegistrationId.substring(0, 9)}${String(optionWithResponse.index + 1).padStart(3, '0')}`;

                    // 옵션 이미지 결정
                    const optionImage = sku.skuAttributes[0].skuImageUrl
                        || (sku.skuAttributes[1] && sku.skuAttributes[1].skuImageUrl)
                        || productData.productImage.images[0];

                    // coupangDoc 초기화 - 응답 데이터 활용
                    const coupangDoc = this.initializeCoupangDoc(responseData.createResult, this.defaultSettings);

                    // 옵션 정보 생성
                    const option = {
                        id: sku.skuId,
                        optionNumber,
                        option1: {
                            name: sku.skuAttributes[0].value,
                            nameTrans: sku.skuAttributes[0].valueTrans,
                            imageUrl: sku.skuAttributes[0].skuImageUrl || ''
                        },
                        option2: sku.skuAttributes[1] ? {
                            name: sku.skuAttributes[1].value,
                            nameTrans: sku.skuAttributes[1].valueTrans,
                            imageUrl: sku.skuAttributes[1].skuImageUrl || ''
                        } : null,
                        korName: optionName,
                        fullProductName: optionProductName,
                        stock: sku.amountOnSale,
                        price: price,
                        coupangDoc: coupangDoc, // 옵션별 coupangDoc 설정
                        mainImageStatus: {
                            status: 'pending',
                            image: optionImage,
                            processedImage: null,
                            textRemoved: false,
                            dimensions: '800x800',
                            filesize: '100KB'
                        },
                        additionalImageStatus: {
                            status: 'pending',
                            images: productData.productImage.images
                        },
                        detailImageStatus: {
                            status: 'pending',
                            images: productData.description ? this.extractDetailImages(productData.description) : []
                        },
                        koreanLabelStatus: 'pending',
                        pricingStatus: 'pending',
                        seoStatus: 'pending',
                        sizeChartStatus: 'pending',
                        quotationStatus: 'pending'
                    };

                    // 가격 계산 및 업데이트
                    const processedOption = this.updateOptionPricing(option);
                    allOptions.push(processedOption);
                }

                // 새 상품 객체 생성
                const newProductObj = {
                    createdAt: now,
                    registrationId: newRegistrationId,
                    productImage: productData.productImage.images[0],
                    productName: productData.subject,
                    productNameTrans: productData.subjectTrans,
                    categoryPath: this.newProduct.categoryPath || optionsWithResponses[0].responseData.categoryPath,
                    categoryCode: this.newProduct.categoryCode || optionsWithResponses[0].responseData.categoryCode,
                    rocketRegInfo: optionsWithResponses[0].responseData.rocketRegInfo,
                    // coupangDoc은 포함하지 않음 (옵션별로 개별 문서 사용)
                    mainImageStatus: 'pending',
                    additionalImageStatus: 'pending',
                    detailImageStatus: 'pending',
                    koreanLabelStatus: 'pending',
                    pricingStatus: 'pending',
                    seoStatus: 'pending',
                    sizeChartStatus: 'pending',
                    quotationStatus: 'pending',
                    targetKeyword: this.newProduct.keyword,
                    brand: brandName,
                    productFeature: this.newProduct.productFeature,
                    구매링크: this.newProduct.link.split('?')[0],
                    options: allOptions,
                    // 추가 정보
                    originalPrice: productData.productSaleInfo.priceRangeList[0].price,
                    minOrderQuantity: productData.minOrderQuantity,
                    attributes: productData.productAttribute,
                    mainImages: productData.productImage.images,
                    detailImages: this.extractDetailImages(productData.description),
                    // 등록 정보 복사
                    manufacturer: this.defaultSettings.manufacturer,
                    transactionType: this.defaultSettings.transactionType,
                    importStatus: this.defaultSettings.importStatus,
                    asInfo: this.defaultSettings.asInfo,
                    exchangeRate: this.defaultSettings.exchangeRate,
                    marginRate: this.defaultSettings.marginRate,
                    coupangMarginRate: this.defaultSettings.coupangMarginRate,
                    roundUnit: this.defaultSettings.roundUnit,
                    createDate: now,
                    updateDate: now
                };

                console.log('생성된 상품 객체:', newProductObj);

                // 서버에 저장
                const saveRes = await axios.post('/api/rocketReg/saveItems', [newProductObj]);
                if (saveRes.data.res !== 'success') {
                    throw new Error(saveRes.data.alert || 'rocketReg/saveItems API 호출 실패');
                }

                // 서버 응답으로부터 저장된 상품 정보 반영
                let savedProductObj = newProductObj;
                if (saveRes.data.savedItems && saveRes.data.savedItems.length > 0) {
                    savedProductObj = saveRes.data.savedItems[0];
                }

                // 로컬 목록에 반영
                this.products.push(savedProductObj);

                this.$bvModal.hide('addProductModal');
                this.$bvToast.toast('상품이 추가되었습니다.', {
                    title: '성공',
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });

                // 성공 후 목록 새로고침
                this.$nextTick(() => {
                    this.search();
                });

                return savedProductObj;
            } catch (error) {
                console.error('상품 객체 생성 실패:', error);
                throw error;
            }
        },
        isSizeChartEnabled(product) {
            if (!product) return false;
            return this.isSizeChartRequired(product);
        },
        // AIRocketReg.vue나 별도 유틸리티 함수로 구현
        isSizeChartRequired(product) {
            try {
                if (product.rocketRegInfo && product.rocketRegInfo.schemaString) {
                    const schema = JSON.parse(product.rocketRegInfo.schemaString);

                    // 스키마의 imagePage > images > required 배열에 sizeChartImage가 있는지 확인
                    if (schema.properties &&
                        schema.properties.imagePage &&
                        schema.properties.imagePage.properties &&
                        schema.properties.imagePage.properties.images &&
                        schema.properties.imagePage.properties.images.properties &&
                        schema.properties.imagePage.properties.images.required) {

                        return schema.properties.imagePage.properties.images.required.includes('sizeChartImage');
                    }
                }
            } catch (error) {
                console.error('스키마 파싱 오류:', error);
            }
            return false; // 기본값은 필수 아님
        },
        async createProductObject(data, extensionData) {
            try {
                const now = new Date().getTime();

                // 등록번호 생성
                const newRegistrationId = this.generateRegistrationId(now);

                // 옵션 처리
                const processedOptions = this.processOptions(data, newRegistrationId);

                // 브랜드명 설정
                const brandName = this.newProduct.brand || this.defaultSettings.brand;

                // 새 상품 객체 기본 구조
                const newProductObj = {
                    createdAt: now,
                    registrationId: newRegistrationId,
                    productImage: data.productImage.images[0],
                    productName: data.subject,
                    productNameTrans: data.subjectTrans,
                    categoryPath: this.newProduct.categoryPath || extensionData.categoryPath,
                    categoryCode: this.newProduct.categoryCode || extensionData.categoryCode,
                    rocketRegInfo: extensionData.rocketRegInfo,
                    mainImageStatus: 'pending',
                    additionalImageStatus: 'pending',
                    detailImageStatus: 'pending',
                    koreanLabelStatus: 'pending',
                    pricingStatus: 'pending',
                    seoStatus: 'pending',
                    sizeChartStatus: 'pending',
                    quotationStatus: 'pending',
                    targetKeyword: this.newProduct.keyword,
                    brand: brandName,
                    productFeature: this.newProduct.productFeature,
                    productShippingInfo: data.productShippingInfo,
                    구매링크: this.newProduct.link.split('?')[0],
                    options: processedOptions,
                    // 추가 정보
                    originalPrice: data.productSaleInfo.priceRangeList[0].price,
                    minOrderQuantity: data.minOrderQuantity,
                    attributes: data.productAttribute,
                    mainImages: data.productImage.images,
                    detailImages: this.extractDetailImages(data.description),
                    // 등록 정보 복사
                    manufacturer: this.defaultSettings.manufacturer,
                    transactionType: this.defaultSettings.transactionType,
                    importStatus: this.defaultSettings.importStatus,
                    asInfo: this.defaultSettings.asInfo,
                    exchangeRate: this.defaultSettings.exchangeRate,
                    marginRate: this.defaultSettings.marginRate,
                    coupangMarginRate: this.defaultSettings.coupangMarginRate,
                    roundUnit: this.defaultSettings.roundUnit,
                    createDate: now,
                    updateDate: now
                };

                // 옵션 또는 상품에 coupangDoc 설정
                if (processedOptions.length > 0) {
                    // 옵션이 있는 경우: 첫 번째 옵션에 coupangDoc 설정
                    processedOptions[0].coupangDoc = this.initializeCoupangDoc(
                        extensionData.createResult,
                        this.defaultSettings,
                        extensionData.rocketRegInfo
                    );
                } else {
                    // 옵션이 없는 경우: 상품 자체에 coupangDoc 설정
                    newProductObj.coupangDoc = this.initializeCoupangDoc(
                        extensionData.createResult,
                        this.defaultSettings,
                        extensionData.rocketRegInfo
                    );
                }

                console.log('생성된 상품 객체:', newProductObj);

                // 서버에 저장
                const saveRes = await axios.post('/api/rocketReg/saveItems', [newProductObj]);
                if (saveRes.data.res !== 'success') {
                    throw new Error(saveRes.data.alert || 'rocketReg/saveItems API 호출 실패');
                }

                // 서버 응답으로부터 저장된 상품 정보 반영
                let savedProductObj = newProductObj;
                if (saveRes.data.savedItems && saveRes.data.savedItems.length > 0) {
                    savedProductObj = saveRes.data.savedItems[0];
                }

                // 로컬 목록에 반영 - 중요: skipUpdate 플래그 추가
                savedProductObj.skipUpdate = true; // 초기 추가 시 업데이트 스킵 플래그
                this.products.push(savedProductObj);

                this.$bvModal.hide('addProductModal');
                this.$bvToast.toast('상품이 추가되었습니다.', {
                    title: '성공',
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });

                // 초기 상품 추가 후 skipUpdate 플래그 제거 (일정 시간 후)
                setTimeout(() => {
                    const addedProduct = this.products.find(p => p.registrationId === savedProductObj.registrationId);
                    if (addedProduct) {
                        delete addedProduct.skipUpdate;
                    }
                }, 3000); // 3초 후 플래그 제거

                // 성공 후 목록 새로고침
                this.$nextTick(() => {
                    this.search();
                });

                return savedProductObj;
            } catch (error) {
                console.error('상품 객체 생성 실패:', error);
                throw error;
            }
        },

        // coupangDoc 초기화 함수 개선
        initializeCoupangDoc(createResult, defaultSettings, rocketRegInfo = null) {
            // createResult 확인
            if (!createResult) {
                console.error('initializeCoupangDoc: createResult가 없습니다.');
                return {};
            }

            // rocketRegInfo 정보 확인 (직접 전달되지 않았다면 createResult에서 찾기)
            const regInfo = rocketRegInfo || createResult.rocketRegInfo || this.extensionResponseData?.rocketRegInfo;

            // 최종 coupangDoc 구조의 기본 틀 생성
            let coupangDoc = {
                docId: createResult.docId,
                productName: createResult.jsonDocument?.[0]?.startPage?.productName || "",
                categoryPath: createResult.categoryPath || createResult.jsonDocument?.[0]?.startPage?.categoryPath || "",
                categoryId: createResult.categoryId,
                displayCategoryCode: createResult.displayCategoryCode,
                jsonDocument: [{}],
                scope: createResult.scope || "Retail_Categorized_Single",
                productNoticeNumber: createResult.productNoticeNumber,
                version: createResult.version,
                remark: createResult.remark
            };

            // createResult의 jsonDocument[0].startPage가 있으면 복사
            if (createResult.jsonDocument?.[0]?.startPage) {
                coupangDoc.jsonDocument[0].startPage = {
                    ...createResult.jsonDocument[0].startPage
                };
            } else {
                // 기본 startPage 생성
                coupangDoc.jsonDocument[0].startPage = {
                    productName: coupangDoc.productName,
                    categoryPath: coupangDoc.categoryPath
                };
            }

            try {
                // 스키마 정보가 있는지 확인
                let schema = null;
                let schemaString = null;

                // 다양한 소스에서 스키마 정보 추출 시도 (우선순위 적용)
                if (createResult.schemaString) {
                    schemaString = createResult.schemaString;
                } else if (regInfo?.schemaString) {
                    schemaString = regInfo.schemaString;
                } else if (this.extensionResponseData?.rocketRegInfo?.schemaString) {
                    schemaString = this.extensionResponseData.rocketRegInfo.schemaString;
                }

                // 스키마 문자열이 있으면 파싱
                if (schemaString) {
                    try {
                        schema = JSON.parse(schemaString);
                        console.log('스키마 정보 파싱 성공:', schema.required?.length || 0, '개 필수 필드');
                    } catch (parseError) {
                        console.error('스키마 문자열 파싱 실패:', parseError);
                    }
                } else {
                    console.warn('스키마 정보를 찾을 수 없습니다.');
                }

                // 스키마가 있으면 필요한 구조 추출하여 적용
                if (schema) {
                    // 스키마의 required 필드 기반으로 필수 구조 추출하여 적용
                    this.extractAndApplySchema(coupangDoc.jsonDocument[0], schema);

                    // defaultSettings 적용
                    this.applyDefaultSettings(coupangDoc, defaultSettings);

                    console.log('coupangDoc 초기화 완료:', coupangDoc.docId);
                } else {
                    console.warn('스키마 정보가 없어 기본 구조만 초기화됩니다.');
                }

                // 원본 rocketRegInfo 정보도 저장
                // coupangDoc.rocketRegInfo = regInfo;
                if (coupangDoc.jsonDocument[0].imagePage) {
                    coupangDoc.jsonDocument[0].imagePage.msrpAgree = true;
                } else if (coupangDoc.jsonDocument[0]) {
                    // imagePage가 없으면 생성
                    coupangDoc.jsonDocument[0].imagePage = {
                        images: { mainImage: "", additionalImage: "", labelImages: [] },
                        details: { detailedImage: "", htmlProductDetailContent: "", altText: "" },
                        msrpAgree: true
                    };
                }
                return coupangDoc;
            } catch (error) {
                console.error('initializeCoupangDoc 오류:', error);
                return coupangDoc; // 오류 발생 시 기본 문서 반환
            }
        },

        // 스키마와 coupangDoc 비교 로깅 - 필수 필드만 로깅하도록 수정
        logSchemaAndDocComparison(schema, coupangDoc) {
            if (!schema || !coupangDoc.jsonDocument || !coupangDoc.jsonDocument[0]) {
                console.log("스키마 또는 coupangDoc이 없어 비교할 수 없습니다.");
                return;
            }

            console.group("📋 스키마와 coupangDoc 필수 필드 비교");

            // 필수 페이지 순회
            const requiredPages = schema.required || [];
            console.log("📑 필수 페이지:", requiredPages);

            requiredPages.forEach(pageName => {
                if (!schema.properties[pageName]) return;

                const pageSchema = schema.properties[pageName];
                const pageDoc = coupangDoc.jsonDocument[0][pageName] || {};
                const requiredFields = pageSchema.required || [];

                console.group(`🔹 ${pageName} 페이지 (필수 필드: ${requiredFields.join(', ')})`);

                // 페이지 description 출력 (새로 추가)
                if (pageSchema.description) {
                    console.log(`📄 페이지 설명: ${pageSchema.description}`);
                }

                // 필수 필드만 순회
                requiredFields.forEach(propName => {
                    if (!pageSchema.properties[propName]) return;

                    const propSchema = pageSchema.properties[propName];
                    const propValue = pageDoc[propName];

                    // 특별한 타입(객체, 배열) 처리
                    if (propSchema.type === 'object' && propSchema.properties) {
                        console.group(`🔸 ${propName} (객체)`);

                        // 객체 description 출력
                        if (propSchema.description) {
                            console.log(`📄 객체 설명: ${propSchema.description}`);
                        }

                        // 객체의 필수 필드만 로깅
                        const requiredSubFields = propSchema.required || [];
                        requiredSubFields.forEach(subPropName => {
                            if (!propSchema.properties[subPropName]) return;

                            const subPropSchema = propSchema.properties[subPropName];
                            const subPropValue = propValue ? propValue[subPropName] : undefined;

                            this.logPropertyComparison(subPropName, subPropSchema, subPropValue);
                        });

                        console.groupEnd();
                    } else if (propSchema.type === 'array') {
                        console.group(`🔸 ${propName} (배열)`);

                        // 배열 description 출력
                        if (propSchema.description) {
                            console.log(`📄 배열 설명: ${propSchema.description}`);
                        }

                        // 배열 스키마 정보 - allOf가 있는 경우만 처리
                        if (propSchema.allOf) {
                            console.log("📌 필수 배열 항목 스키마 정의:", propSchema.allOf.length, "개");

                            propSchema.allOf.forEach((itemSchema, index) => {
                                console.group(`📎 항목 스키마 #${index + 1}`);
                                if (itemSchema.contains && itemSchema.contains.properties) {
                                    Object.keys(itemSchema.contains.properties).forEach(itemPropName => {
                                        console.log(`   - ${itemPropName}:`, itemSchema.contains.properties[itemPropName]);
                                    });
                                }
                                console.groupEnd();
                            });
                        }

                        // 실제 배열 값
                        if (Array.isArray(propValue)) {
                            console.log("📊 실제 배열 값:", propValue.length, "개");
                            propValue.forEach((item, index) => {
                                console.log(`   #${index + 1}:`, item);
                            });
                        } else {
                            console.log("⚠️ 배열이 없거나 비어 있습니다.");
                        }

                        console.groupEnd();
                    } else {
                        // 일반 속성
                        this.logPropertyComparison(propName, propSchema, propValue);
                    }
                });

                console.groupEnd(); // 페이지 그룹 종료
            });

            console.groupEnd(); // 전체 그룹 종료
        },

        // 개별 속성 비교 로깅 (description 정보 추가)
        logPropertyComparison(propName, propSchema, propValue) {
            const schemaInfo = [];

            // 스키마 정보 수집
            if (propSchema.type) {
                schemaInfo.push(`타입: ${Array.isArray(propSchema.type) ? propSchema.type.join('/') : propSchema.type}`);
            }

            if (propSchema.dropdown && propSchema.dropdown.length) {
                schemaInfo.push(`드롭다운: [${propSchema.dropdown.join(', ')}]`);
            } else if (propSchema.enum && propSchema.enum.length) {
                schemaInfo.push(`ENUM: [${propSchema.enum.join(', ')}]`);
            }

            if (propSchema.requirement) {
                schemaInfo.push(`필수여부: ${propSchema.requirement}`);
            }

            // 값 상태 표시
            let valueStatus = '';
            if (propSchema.dropdown && propSchema.dropdown.length) {
                // 드롭다운 목록에 있는지 확인
                valueStatus = propSchema.dropdown.includes(propValue)
                    ? '✅ 유효함'
                    : '❌ 유효하지 않음 (드롭다운 목록에 없음)';
            } else if (propSchema.enum && propSchema.enum.length) {
                // enum 목록에 있는지 확인
                valueStatus = propSchema.enum.includes(propValue)
                    ? '✅ 유효함'
                    : '❌ 유효하지 않음 (ENUM 목록에 없음)';
            } else if (propValue === undefined || propValue === null) {
                valueStatus = propSchema.requirement === '필수'
                    ? '❌ 필수값 누락'
                    : '⚠️ 값 없음 (선택사항)';
            } else {
                valueStatus = '✅ 설정됨';
            }

            // 콘솔 출력
            console.group(`🔹 ${propName}`);
            console.log(`📝 스키마 정보: ${schemaInfo.join(', ')}`);

            // description 정보가 있으면 출력 (새로 추가)
            if (propSchema.description) {
                console.log(`📄 설명: ${propSchema.description}`);
            }

            // inputGuide 정보가 있으면 출력 (새로 추가)
            if (propSchema.inputGuide) {
                console.log(`📋 입력 가이드: ${propSchema.inputGuide}`);
            }

            // inputHint 정보가 있으면 출력 (새로 추가)
            if (propSchema.inputHint) {
                console.log(`💡 입력 힌트: ${propSchema.inputHint}`);
            }

            console.log(`📊 설정된 값: ${JSON.stringify(propValue)}`);
            console.log(`🔍 상태: ${valueStatus}`);
            console.groupEnd();
        },

        // 스키마에서 필요한 구조 추출 및 적용 (개선된 버전)
        extractAndApplySchema(targetDoc, schema) {
            // schema.required에서 필요한 페이지 목록 가져오기
            const pages = schema.required || [];
            console.log("필수 페이지:", pages);

            pages.forEach(page => {
                if (schema.properties && schema.properties[page]) {
                    const pageSchema = schema.properties[page];

                    // 해당 페이지가 targetDoc에 없으면 생성
                    if (!targetDoc[page]) {
                        targetDoc[page] = {};
                    }

                    // 페이지 속성을 분석해서 필요한 구조 추가 (필수 필드 중심)
                    if (pageSchema.required && pageSchema.properties) {
                        console.log(`${page} 페이지 필수 필드:`, pageSchema.required);

                        // 필수 필드 먼저 처리
                        pageSchema.required.forEach(fieldName => {
                            if (pageSchema.properties[fieldName]) {
                                this.createFieldStructure(targetDoc[page], fieldName, pageSchema.properties[fieldName]);
                            }
                        });
                    }
                }
            });
        },

        // 필드 구조 생성 (새 메서드)
        createFieldStructure(targetObj, fieldName, fieldSchema) {
            // 이미 필드가 존재하면 그대로 유지
            if (targetObj[fieldName] !== undefined) {
                return;
            }

            // 객체인 경우 재귀적으로 처리
            if (fieldSchema.type === 'object') {
                targetObj[fieldName] = {};

                // 객체의 필수 필드를 처리
                if (fieldSchema.required && fieldSchema.properties) {
                    fieldSchema.required.forEach(subFieldName => {
                        if (fieldSchema.properties[subFieldName]) {
                            this.createFieldStructure(targetObj[fieldName], subFieldName, fieldSchema.properties[subFieldName]);
                        }
                    });
                }
            }
            // 배열인 경우 특별 처리
            else if (fieldSchema.type === 'array') {
                targetObj[fieldName] = [];

                // 배열 항목이 allOf로 정의된 경우 (exposedAttributes, notices 등)
                if (fieldSchema.allOf) {
                    fieldSchema.allOf.forEach(requirement => {
                        if (requirement.contains && requirement.contains.properties) {
                            const properties = requirement.contains.properties;
                            const nameKey = Object.keys(properties)[0]; // attributeName 등의 키

                            if (properties[nameKey].enum && properties[nameKey].enum.length > 0) {
                                // 값 키 찾기 (attributeValue 등)
                                const valueKey = Object.keys(properties).length > 1 ? Object.keys(properties)[1] : null;

                                // 필수 항목 추가
                                const name = properties[nameKey].enum[0];

                                const newItem = {
                                    [nameKey]: name
                                };

                                // 값 키에 기본값 설정
                                if (valueKey) {
                                    // 인증 항목인 경우 '해당사항없음' 기본값 설정
                                    if (nameKey === 'certificateName') {
                                        newItem[valueKey] = '해당사항없음';
                                    } else {
                                        // 기본 타입에 따른 기본값 설정
                                        const valueSchema = properties[valueKey];
                                        if (valueSchema.type === 'string' || (Array.isArray(valueSchema.type) && valueSchema.type.includes('string'))) {
                                            newItem[valueKey] = '';
                                        } else if (valueSchema.type === 'integer' || valueSchema.type === 'number') {
                                            newItem[valueKey] = 0;
                                        } else {
                                            newItem[valueKey] = null;
                                        }
                                    }
                                }

                                targetObj[fieldName].push(newItem);
                            }
                        }
                    });
                }
            }
            // 기본 타입인 경우 기본값 설정
            else {
                // 타입에 따른 기본값 설정
                if (fieldSchema.type === 'string' || (Array.isArray(fieldSchema.type) && fieldSchema.type.includes('string'))) {
                    // dropdown 또는 enum이 있는 경우 첫 번째 값 사용
                    if (fieldSchema.dropdown && fieldSchema.dropdown.length > 0) {
                        // '해당사항없음'이 있으면 우선 사용
                        const defaultIndex = fieldSchema.dropdown.indexOf('해당사항없음');
                        targetObj[fieldName] = defaultIndex >= 0 ? fieldSchema.dropdown[defaultIndex] : fieldSchema.dropdown[0];
                    } else if (fieldSchema.enum && fieldSchema.enum.length > 0) {
                        // '해당사항없음'이 있으면 우선 사용
                        const defaultIndex = fieldSchema.enum.indexOf('해당사항없음');
                        targetObj[fieldName] = defaultIndex >= 0 ? fieldSchema.enum[defaultIndex] : fieldSchema.enum[0];
                    } else {
                        targetObj[fieldName] = '';
                    }
                } else if (fieldSchema.type === 'integer' || fieldSchema.type === 'number' ||
                    (Array.isArray(fieldSchema.type) && (fieldSchema.type.includes('integer') || fieldSchema.type.includes('number')))) {
                    targetObj[fieldName] = 0;
                } else if (fieldSchema.type === 'boolean') {
                    targetObj[fieldName] = false;
                } else {
                    targetObj[fieldName] = null;
                }
            }
        },
        // defaultSettings 적용
        applyDefaultSettings(coupangDoc, defaultSettings) {
            if (!defaultSettings || !coupangDoc.jsonDocument || !coupangDoc.jsonDocument.length) return;

            const docBody = coupangDoc.jsonDocument[0];

            // 스키마 정보 가져오기
            let schema = null;
            try {
                // 스키마 정보가 있다면 파싱
                if (coupangDoc.rocketRegInfo && coupangDoc.rocketRegInfo.schemaString) {
                    schema = JSON.parse(coupangDoc.rocketRegInfo.schemaString);
                }
            } catch (error) {
                console.error('스키마 파싱 오류:', error);
            }

            // 기본 정보 적용
            if (docBody.productPage) {
                // 브랜드
                this.applySettingWithDropdownCheck(
                    docBody.productPage,
                    'brand',
                    defaultSettings.brand,
                    schema?.properties?.productPage?.properties?.brand
                );

                // 제조사
                docBody.productPage.manufacturer = defaultSettings.manufacturer || '';

                // 거래타입
                this.applySettingWithDropdownCheck(
                    docBody.productPage,
                    'businessType',
                    defaultSettings.transactionType,
                    schema?.properties?.productPage?.properties?.businessType
                );

                // 수입여부
                this.applySettingWithDropdownCheck(
                    docBody.productPage,
                    'importType',
                    defaultSettings.importStatus,
                    schema?.properties?.productPage?.properties?.importType
                );
            }

            // A/S 정보 적용 (법적 고시 정보)
            if (defaultSettings.asInfo && docBody.legalPage && docBody.legalPage.notices) {
                const asNotice = docBody.legalPage.notices.find(n => n.noticeItemName === 'A/S 책임자와 전화번호');
                if (asNotice) {
                    asNotice.noticeItemValue = defaultSettings.asInfo;
                }
            }

            // 물류 정보 적용
            if (docBody.logisticsPage) {
                // 박스 내 SKU 수량
                if (defaultSettings.totalSKUsInBox) {
                    docBody.logisticsPage.totalSKUsInBox = defaultSettings.totalSKUsInBox;
                }

                // 유통기간
                if (defaultSettings.daysToExpiration !== undefined) {
                    docBody.logisticsPage.daysToExpiration = defaultSettings.daysToExpiration;
                }

                // 취급주의 사유
                this.applySettingWithDropdownCheck(
                    docBody.logisticsPage,
                    'specialHandlingReason',
                    defaultSettings.specialHandlingReason,
                    schema?.properties?.logisticsPage?.properties?.specialHandlingReason
                );
            }

            // 세탁방법 및 취급시 주의사항 적용
            if ((defaultSettings.washingMethod || defaultSettings.handlingPrecautions) &&
                docBody.legalPage && docBody.legalPage.notices) {

                const washingNotice = docBody.legalPage.notices.find(n =>
                    n.noticeItemName === '세탁방법 및 취급시 주의사항'
                );

                if (washingNotice) {
                    let noticeValue = '';

                    if (defaultSettings.washingMethod && defaultSettings.handlingPrecautions) {
                        noticeValue = `세탁방법: ${defaultSettings.washingMethod}, 취급시 주의사항: ${defaultSettings.handlingPrecautions}`;
                    } else if (defaultSettings.washingMethod) {
                        noticeValue = defaultSettings.washingMethod;
                    } else if (defaultSettings.handlingPrecautions) {
                        noticeValue = defaultSettings.handlingPrecautions;
                    }

                    washingNotice.noticeItemValue = noticeValue;
                }
            }

            // 제조연월 적용
            if (defaultSettings.manufactureDate && docBody.legalPage && docBody.legalPage.notices) {
                const dateNotice = docBody.legalPage.notices.find(n =>
                    n.noticeItemName === '출시년월' || n.noticeItemName === '제조년월'
                );

                const now = new Date();
                // 지난달로 설정
                now.setMonth(now.getMonth() - 1);

                const year = now.getFullYear();
                // 월이 한 자릿수일 때 앞에 0을 붙이도록 처리
                const month = String(now.getMonth() + 1).padStart(2, '0');

                if (dateNotice) {
                    dateNotice.noticeItemValue = `${year}.${month}`;
                }
            }

            // 품질보증기준 적용
            if (docBody.legalPage && docBody.legalPage.notices) {
                const qualityNotice = docBody.legalPage.notices.find(n => n.noticeItemName === '품질보증기준');
                if (qualityNotice && !qualityNotice.noticeItemValue) {
                    qualityNotice.noticeItemValue = '제품 이상 시 공정거래위원회 고시 소비자분쟁해결기준에 의거하여 보상합니다.';
                }
            }
        },

        // dropdown 목록 체크 후 설정값 적용 (새로 추가된 함수)
        applySettingWithDropdownCheck(targetObj, fieldName, value, schemaProp) {
            // 값이 없으면 적용하지 않음
            if (!value) return;

            // 스키마 속성이 없으면 그냥 값 적용
            if (!schemaProp) {
                targetObj[fieldName] = value;
                return;
            }

            // dropdown 목록 확인
            if (schemaProp.dropdown && Array.isArray(schemaProp.dropdown)) {
                // dropdown 목록에 있는 값인지 확인
                if (schemaProp.dropdown.includes(value)) {
                    targetObj[fieldName] = value;
                } else {
                    // dropdown 목록에 없으면 첫 번째 값 사용
                    targetObj[fieldName] = schemaProp.dropdown[0] || '';
                    console.warn(`경고: '${fieldName}'의 값 '${value}'은(는) dropdown 목록에 없습니다. 대신 '${targetObj[fieldName]}'을(를) 사용합니다.`);
                }
            } else if (schemaProp.enum && Array.isArray(schemaProp.enum)) {
                // enum 목록에 있는 값인지 확인
                if (schemaProp.enum.includes(value)) {
                    targetObj[fieldName] = value;
                } else {
                    // enum 목록에 없으면 첫 번째 값 사용
                    targetObj[fieldName] = schemaProp.enum[0] || '';
                    console.warn(`경고: '${fieldName}'의 값 '${value}'은(는) enum 목록에 없습니다. 대신 '${targetObj[fieldName]}'을(를) 사용합니다.`);
                }
            } else {
                // dropdown이나 enum이 없으면 그냥 값 적용
                targetObj[fieldName] = value;
            }
        },

        // 상세 이미지 추출
        extractDetailImages(description) {
            if (!description) return [];

            const parser = new DOMParser();
            const doc = parser.parseFromString(description, 'text/html');
            const imgElements = doc.querySelectorAll('img');
            return Array.from(imgElements)
                .map(img => img.src)
                .filter(src => src && src.length > 10);
        },

        // 등록번호 생성
        generateRegistrationId(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear().toString().slice(-2);
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');

            // 현재 날짜와 일치하는 등록번호들 필터링
            const todayPrefix = `${year}${month}${day}`;
            const todayRegistrations = this.products.filter(p =>
                p.registrationId && p.registrationId.startsWith(todayPrefix)
            );

            // 오늘 등록된 상품이 있으면 최대 sequence 번호 찾기
            let maxSequence = 0;
            if (todayRegistrations.length > 0) {
                todayRegistrations.forEach(p => {
                    // 등록번호에서 날짜 부분 이후의 sequence 추출 (6자리 중 앞 3자리)
                    const sequencePart = p.registrationId.substring(6, 9);
                    const sequence = parseInt(sequencePart, 10);
                    if (!isNaN(sequence) && sequence > maxSequence) {
                        maxSequence = sequence;
                    }
                });
            }

            // 다음 시퀀스 번호 생성 (기존 최대값 + 1)
            const nextSequence = (maxSequence + 1).toString().padStart(3, '0');
            const revision = '001'; // 리비전 번호 (향후 변경 가능)

            return `${todayPrefix}${nextSequence}${revision}`;
        },

        // 옵션 처리
        processOptions(data, regId) {
            // SKU가 없는 경우(단일 상품)
            if (!data.productSkuInfos || data.productSkuInfos.length === 0) {
                const singleOption = {
                    id: `default-${Date.now()}`,
                    optionNumber: `${regId.substring(0, 9)}001`,  // 등록번호 앞 9자리 + 001
                    name: data.subject,
                    nameKor: data.subjectTrans,
                    stock: data.productSaleInfo.amountOnSale,
                    // 가격 정보를 priceRangeList의 첫 번째 항목에서 가져옴
                    price: parseFloat(data.productSaleInfo.priceRangeList[0].price),
                    status: 'pending',
                    mainImageStatus: {
                        status: 'pending',
                        image: data.productImage.images[0],
                        processedImage: null,
                        textRemoved: false,
                        dimensions: '800x800',
                        filesize: '100KB'
                    },
                    additionalImageStatus: {
                        status: 'pending',
                        images: data.productImage.images
                    },
                    detailImageStatus: {
                        status: 'pending',
                        images: data.description ? this.extractDetailImages(data.description) : []
                    },
                    koreanLabelStatus: 'pending',
                    pricingStatus: 'pending',
                    seoStatus: 'pending',
                    quotationStatus: 'pending'
                };

                // 가격 정보 계산 및 업데이트
                return [this.updateOptionPricing(singleOption)];
            }

            // SKU가 여러 개 있는 경우
            return data.productSkuInfos.map((sku, index) => {
                // 옵션 번호 형식: 등록번호 앞 9자리 + 3자리 옵션 시퀀스
                const optionNumber = `${regId.substring(0, 9)}${String(index + 1).padStart(3, '0')}`;

                // 옵션 이미지를 결정
                const optionImage = sku.skuAttributes[0].skuImageUrl
                    || (sku.skuAttributes[1] && sku.skuAttributes[1].skuImageUrl)
                    || data.productImage.images[0];

                // 가격 정보 가져오기 - fenxiaoPriceInfo.offerPrice 우선, 없으면 consignPrice 사용
                const price = parseFloat(
                    (sku.fenxiaoPriceInfo && sku.fenxiaoPriceInfo.offerPrice)
                        ? sku.fenxiaoPriceInfo.offerPrice
                        : sku.consignPrice
                );

                const option = {
                    id: sku.skuId,
                    optionNumber,
                    option1: {
                        name: sku.skuAttributes[0].value,
                        nameTrans: sku.skuAttributes[0].valueTrans,
                        imageUrl: sku.skuAttributes[0].skuImageUrl || ''
                    },
                    option2: sku.skuAttributes[1] ? {
                        name: sku.skuAttributes[1].value,
                        nameTrans: sku.skuAttributes[1].valueTrans,
                        imageUrl: sku.skuAttributes[1].skuImageUrl || ''
                    } : null,
                    stock: sku.amountOnSale,
                    price: price, // 수정된 부분: 위에서 계산한 가격 정보 사용
                    mainImageStatus: {
                        status: 'pending',
                        image: optionImage,
                        processedImage: null,
                        textRemoved: false,
                        dimensions: '800x800',
                        filesize: '100KB'
                    },
                    additionalImageStatus: {
                        status: 'pending',
                        images: data.productImage.images
                    },
                    detailImageStatus: {
                        status: 'pending',
                        images: data.description ? this.extractDetailImages(data.description) : []
                    },
                    koreanLabelStatus: 'pending',
                    pricingStatus: 'pending',
                    seoStatus: 'pending',
                    sizeChartStatus: 'pending',
                    quotationStatus: 'pending'
                };

                // 가격 계산 및 업데이트 후 반환
                return this.updateOptionPricing(option);
            });
        },

        // 옵션 가격 업데이트
        updateOptionPricing(option) {
            // 중국 위안화 가격
            const cnyPrice = option.price;

            // 번들링 계산
            let bundleQuantity = 1; // 기본 번들 수량

            if (this.defaultSettings.enableBundling) {
                bundleQuantity = this.calculateBundleQuantity(
                    cnyPrice,
                    this.defaultSettings.exchangeRate,
                    this.defaultSettings.marginRate,
                    this.defaultSettings.coupangMarginRate,
                    this.defaultSettings.bundlingType,
                    this.defaultSettings.minMarginAmount,
                    this.defaultSettings.minCoupangMarginAmount
                );
            }

            // 번들링된 가격으로 계산
            const bundledCnyPrice = cnyPrice * bundleQuantity;
            const purchasePrice = bundledCnyPrice * this.defaultSettings.exchangeRate;

            // 공급가 계산 (매입가 ÷ (1 - 공급마진율/100))
            const marginRate = this.defaultSettings.marginRate / 100;
            const rawSupplyPrice = purchasePrice / (1 - marginRate);
            const supplyPrice = Math.round(rawSupplyPrice / this.defaultSettings.roundUnit) * this.defaultSettings.roundUnit;

            // 판매가 계산 (공급가 ÷ (1 - 쿠팡마진율/100))
            const coupangRate = this.defaultSettings.coupangMarginRate / 100;
            const rawSellingPrice = supplyPrice / (1 - coupangRate);
            const sellingPrice = Math.round(rawSellingPrice / this.defaultSettings.roundUnit) * this.defaultSettings.roundUnit;

            // 마진 계산
            const supplyMargin = supplyPrice - purchasePrice;
            const sellingMargin = sellingPrice - supplyPrice;

            // 옵션 객체에 가격 정보 추가/업데이트
            option.supplyPrice = supplyPrice;
            option.sellingPrice = sellingPrice;
            option.purchasePrice = purchasePrice;
            option.supplyMargin = supplyMargin;
            option.sellingMargin = sellingMargin;
            option.bundleQuantity = bundleQuantity;

            return option;
        },

        // 번들 수량 계산
        calculateBundleQuantity(cnyPrice, exchangeRate, marginRate, coupangMarginRate, bundlingType, minMarginAmount, minCoupangMarginAmount) {
            // 단일 상품으로 계산한 가격
            const purchasePrice = cnyPrice * exchangeRate;
            const marginRateDecimal = marginRate / 100;
            const coupangRateDecimal = coupangMarginRate / 100;

            const rawSupplyPrice = purchasePrice / (1 - marginRateDecimal);
            const supplyPrice = Math.round(rawSupplyPrice / 100) * 100;

            const rawSellingPrice = supplyPrice / (1 - coupangRateDecimal);
            const sellingPrice = Math.round(rawSellingPrice / 100) * 100;

            // 마진 계산
            const supplyMargin = supplyPrice - purchasePrice;
            const sellingMargin = sellingPrice - supplyPrice;

            // 번들링 기준에 따른 조건 확인
            let bundleQuantity = 1;

            if (bundlingType === 'marginAmount') {
                // 공급 마진액 기준
                if (supplyMargin < minMarginAmount) {
                    // 2개, 4개, 6개... 단위로 번들링
                    while (supplyMargin * bundleQuantity < minMarginAmount && bundleQuantity < 20) {
                        bundleQuantity += 2;
                    }
                }
            } else { // 'coupangMarginAmount'
                // 쿠팡 마진액 기준
                if (sellingMargin < minCoupangMarginAmount) {
                    // 2개, 4개, 6개... 단위로 번들링
                    while (sellingMargin * bundleQuantity < minCoupangMarginAmount && bundleQuantity < 20) {
                        bundleQuantity += 2;
                    }
                }
            }

            return bundleQuantity;
        },

        // 옵션 및 모달 관련 메서드
        toggleOptions(product) {
            if (!product || !product.registrationId) {
                console.error("유효하지 않은 제품 정보입니다.");
                return;
            }

            // Vuex로 선택된 상품 저장
            this.SET_SELECTED_ROCKET_PRODUCT(product);

            // 모달 표시
            this.showOptionsModal = true;
        },

        // 상품 편집
        editProduct(product) {
            this.selectedProduct = product;
            this.showOptionsModal = true;

            // 여기에 Wizard를 열지 않도록!
        },

        // 상품 삭제
        async deleteProduct(product) {
            if (!product || !product.registrationId) {
                console.error("유효하지 않은 제품 정보입니다.");
                return;
            }

            // 옵션이 있고 각 옵션에 coupangDoc이 있는지 확인
            const hasOptions = product.options && product.options.length > 0;
            const optionsWithDocs = hasOptions ?
                product.options.filter(opt => opt.coupangDoc && opt.coupangDoc.docId).length : 0;

            const confirmMessage = hasOptions && optionsWithDocs > 0 ?
                `'${product.productName}' 상품과 관련된 ${optionsWithDocs}개의 쿠팡 문서를 삭제하시겠습니까?` :
                `'${product.productName}' 상품을 삭제하시겠습니까?`;

            if (!confirm(confirmMessage)) {
                return;
            }

            try {
                // 옵션별 쿠팡 문서 삭제 요청
                if (hasOptions) {
                    for (const option of product.options) {
                        if (option.coupangDoc && option.coupangDoc.docId) {
                            // 확장 프로그램에 문서 삭제 요청 메시지 전송
                            window.postMessage({
                                type: 'DELETE_ROCKET_REG_DOC',
                                id: `delete_option_${option.optionNumber}_${Date.now()}`,
                                data: {
                                    docId: option.coupangDoc.docId,
                                    suppliercode: this.$store.state.user.suppliercode
                                }
                            }, '*');

                            console.log(`옵션 ${option.optionNumber}의 쿠팡 문서 삭제 요청 (docId: ${option.coupangDoc.docId})`);

                            // 연속 요청 시 확장 프로그램에 부하가 가지 않도록 짧은 딜레이 추가
                            await new Promise(resolve => setTimeout(resolve, 100));
                        }
                    }
                }
                // 상품 자체에 coupangDoc이 있는 경우 (옵션이 없는 단일 상품)
                else if (product.coupangDoc && product.coupangDoc.docId) {
                    window.postMessage({
                        type: 'DELETE_ROCKET_REG_DOC',
                        id: `delete_product_${product.registrationId}_${Date.now()}`,
                        data: {
                            docId: product.coupangDoc.docId,
                            suppliercode: this.$store.state.user.suppliercode
                        }
                    }, '*');

                    console.log(`상품의 쿠팡 문서 삭제 요청 (docId: ${product.coupangDoc.docId})`);
                }

                // API 호출 - 서버 데이터베이스에서 상품 삭제
                const res = await axios.post('/api/rocketReg/deleteItem', product);

                if (res.data.res === 'success') {
                    // 목록에서 제거
                    const index = this.products.findIndex(p => p.registrationId === product.registrationId);
                    if (index !== -1) {
                        this.products.splice(index, 1);
                    }

                    // 선택된 상품 목록에서도 제거
                    const selectedIndex = this.selectedItems.findIndex(p => p.registrationId === product.registrationId);
                    if (selectedIndex !== -1) {
                        this.selectedItems.splice(selectedIndex, 1);
                    }

                    this.$bvToast.toast('상품이 삭제되었습니다.', {
                        title: '성공',
                        variant: 'success',
                        solid: true,
                        toaster: 'b-toaster-bottom-right'
                    });
                } else {
                    this.$bvToast.toast('상품 삭제에 실패하였습니다.', {
                        title: '실패',
                        variant: 'warning',
                        solid: true,
                        toaster: 'b-toaster-bottom-right'
                    });
                }
            } catch (error) {
                console.error('상품 삭제 오류', error);
                this.$bvToast.toast('상품 삭제에 실패하였습니다.', {
                    title: '실패',
                    variant: 'warning',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
            }
        },

        // 옵션 삭제 처리
        handleOptionDeleted(product) {
            // 상품 업데이트 처리
            this.updateProduct(product);
        },

        // 상품 업데이트
        async updateProduct(product) {
            try {
                // Vuex 액션 호출
                await this.updateRocketProduct(product);
                return true;
            } catch (error) {
                console.error('상품 정보 업데이트 중 오류:', error);
                this.$bvToast.toast(`상품 정보 업데이트 실패: ${error.message}`, {
                    title: '실패',
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
                return false;
            }
        },

        // 마법사 모달 닫힘 처리
        handleWizardClosed() {
            console.log("위자드 모달 닫힘");

            try {
                // 선택된 상품이 있으면
                if (this.selectedProduct) {
                    // 테이블 UI 강제 갱신을 위한 코드
                    const index = this.products.findIndex(p => p.registrationId === this.selectedProduct.registrationId);
                    if (index !== -1) {
                        // Vuex 상태에서 최신 상품 정보 가져오기
                        const updatedProduct = JSON.parse(JSON.stringify(this.selectedProduct));

                        // Vue의 반응성을 보장하기 위해 $set 사용
                        this.$set(this.products, index, updatedProduct);

                        // 현재 변경사항을 데이터베이스에 반영
                        this.updateProduct(updatedProduct).catch(error => {
                            console.error('옵션 업데이트 중 오류 발생:', error);
                        });
                    }

                    // 옵션 모달이 열려있는 경우 선택된 상품도 동기화 업데이트
                    if (this.showOptionsModal) {
                        // 최신 제품 정보 가져오기
                        const latestProduct = this.products.find(p =>
                            p.registrationId === this.selectedProduct.registrationId
                        );

                        if (latestProduct) {
                            // 갱신된 정보로 Vuex 상태 업데이트
                            this.SET_SELECTED_ROCKET_PRODUCT(JSON.parse(JSON.stringify(latestProduct)));
                        }
                    }
                }

                // 선택된 작업 초기화 (옵션 유지)
                this.SET_SELECTED_WORK_TYPE("");
            } catch (error) {
                console.error("위자드 닫힘 처리 중 오류:", error);
            }
        },
        // AIRocketReg.vue의 getStatusVariant 메서드 수정
        getStatusVariant(status) {
            // 콘솔에 현재 처리 중인 상태 출력 (디버깅용)
            // console.log('현재 상태:', status);

            switch (status) {
                case 'completed': return 'success';
                case 'partially_completed': return 'info';
                case 'processing': return 'primary';
                case 'pending': return 'secondary';
                case 'error': return 'danger';
                case 'disabled': return 'light';
                default: return 'secondary';
            }
        },

        // AIRocketReg.vue - getStatusText 메서드 수정
        getStatusText(status) {
            const texts = {
                completed: '완료',
                partially_completed: '일부완료',
                processing: '진행중',
                pending: '대기',
                error: '오류',
                disabled: '미사용'
            };

            // status가 객체인 경우(mainImageStatus 등) status.status 값을 사용
            if (status && typeof status === 'object' && status.status) {
                return texts[status.status] || '대기';
            }

            return texts[status] || '대기';
        },
        // AIRocketReg.vue의 getWorkStatus 메서드 수정
        getWorkStatus(product, workType) {
            if (workType === 'sizeChartStatus') {
                // 사이즈표가 필요 없는 경우 'disabled' 반환
                if (!this.isSizeChartRequired(product)) {
                    return 'disabled';
                }
            }
            if (product[workType] && product[workType] !== 'pending') {
                return product[workType];
            }
            // 1. 상품 자체의 상태 확인
            if (product[workType]) {
                // 상태가 객체인 경우 (예: { status: 'completed' })
                if (typeof product[workType] === 'object' && product[workType].status) {
                    return product[workType].status;
                }
                // 상태가 문자열인 경우
                if (typeof product[workType] === 'string') {
                    return product[workType];
                }
            }

            // 2. 옵션이 없으면 'disabled' 반환
            if (!product.options || product.options.length === 0) return 'disabled';

            const totalOptions = product.options.length;

            // 옵션별 상태를 집계
            const statuses = product.options.map(option => {
                // 객체 형태인 경우 (예: mainImageStatus: { status: 'completed' })
                if (option[workType] && typeof option[workType] === 'object') {
                    return option[workType].status || 'pending';
                }
                // 문자열 형태인 경우 (예: mainImageStatus: 'completed')
                return option[workType] || 'pending';
            });

            // 모든 옵션이 완료되었는지 확인
            const completedCount = statuses.filter(status => status === 'completed').length;

            // 처리 중인 옵션이 있는지 확인
            const processingCount = statuses.filter(status => status === 'processing').length;

            // 오류가 있는 옵션 수
            const errorCount = statuses.filter(status => status === 'error').length;

            // 상태 결정 로직
            if (completedCount === totalOptions) {
                return 'completed'; // 모든 옵션 완료
            } else if (completedCount > 0) {
                return 'partially_completed'; // 일부 옵션만 완료
            } else if (processingCount > 0) {
                return 'processing'; // 처리 중인 옵션 있음
            } else if (errorCount > 0) {
                return 'error'; // 오류 발생한 옵션 있음
            } else {
                return 'pending'; // 대기 상태
            }
        },
        // 수정 후 코드
        async startProcessing() {
            if (!this.hasSelectedProducts) {
                this.$bvToast.toast('처리할 상품을 선택해주세요.', {
                    title: '알림',
                    variant: 'warning',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
                return;
            }

            if (!this.$store.state.settings[0]?.geminiAPIKey) {
                this.$bvToast.toast('계정관리 > 설정 > Gemini API 설정 에서 Gemini API Key를 먼저 설정해 주세요', {
                    title: '설정 필요',
                    variant: 'warning',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
                return;
            }

            this.isProcessing = true;
            try {
                // 선택된 작업 유형에 따라 처리할 작업 목록 설정
                let tasksToProcess = [];

                if (this.selectedWorkType === 'all') {
                    tasksToProcess = [
                        'seo',
                        'pricing',
                        'mainImage',
                        'additionalImage',
                        'detailImage',
                        'sizeChart',
                        'koreanLabel',
                        'quotation'
                    ];
                } else {
                    tasksToProcess = [this.selectedWorkType];
                }
                console.log(`작업 개시: ${tasksToProcess.join(', ')}`);

                // 선택된 상품들 처리
                for (const product of this.selectedItems) {
                    console.log(`상품 ${product.registrationId} 처리 시작`);

                    // 선택된 작업만 처리
                    const result = await workflowService.processProduct(product, tasksToProcess);
                    console.log(`상품 ${product.registrationId} 처리 결과:`, result);

                    // 처리 후 상태 확인
                    const updatedProduct = this.products.find(p => p.registrationId === product.registrationId);
                    if (updatedProduct) {
                        console.log(`처리 후 상품 상태:`, {
                            mainImageStatus: updatedProduct.mainImageStatus,
                            optionsStatus: updatedProduct.options.map(o => ({
                                optionNumber: o.optionNumber,
                                mainImageStatus: o.mainImageStatus?.status || o.mainImageStatus
                            }))
                        });

                        // SEO 작업이 포함되었고 완료된 경우, 여기서는 쿠팡 문서 업데이트를 건너뜁니다.
                        // 실제 문서 업데이트는 '등록 전송' 버튼을 통해 진행하게 됩니다.
                    }
                }

                this.$bvToast.toast('선택한 상품의 작업이 완료되었습니다.', {
                    title: '성공',
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
            } catch (error) {
                console.error('작업 처리 중 오류:', error);
                this.$bvToast.toast('작업 처리 중 오류가 발생했습니다.', {
                    title: '오류',
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
            } finally {
                this.isProcessing = false;
            }
        },

        // AI 테스트 요청
        async testRequestExtension() {
            // 선택된 상품이 있는지 확인
            if (this.selectedItems.length === 0) {
                this.$bvToast.toast('테스트할 상품을 먼저 선택해주세요', {
                    title: '알림',
                    variant: 'warning',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
                return;
            }

            // API 키 확인
            if (!this.$store.state.settings[0]?.geminiAPIKey) {
                this.$bvToast.toast('계정관리 > 설정 > Gemini API 설정 에서 Gemini API Key를 먼저 설정해 주세요', {
                    title: '설정 필요',
                    variant: 'warning',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
                return;
            }

            // 테스트할 상품 선택
            const testProduct = this.selectedItems[0];

            // Vuex 액션 호출
            try {
                await this.testRequestExtension(testProduct);
            } catch (error) {
                console.error('AI 테스트 중 오류:', error);
                this.$bvToast.toast(`AI 테스트 중 오류가 발생했습니다: ${error.message}`, {
                    title: '오류',
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-bottom-right'
                });
            }
        }
    }
};
</script>

<style scoped>
::v-deep .reg-detail-modal .modal-dialog {
    max-width: 95%;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

/* AIRocketReg.vue의 <style> 섹션에 추가 */
@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

.pulsating {
    animation: pulse 1.5s infinite ease-in-out;
}

.rocket-badge-pill.badge-primary {
    position: relative;
}

.rocket-badge-pill.badge-primary.pulsating::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    z-index: -1;
    animation: pulse-shadow 1.5s infinite;
}

@keyframes pulse-shadow {
    0% {
        transform: scale(1);
        opacity: 0.8;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.2;
    }

    100% {
        transform: scale(1);
        opacity: 0.8;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.list-group-item.active {
    background-color: #007bff;
    border-color: #007bff;
    color: white;
}

.list-group-item:hover:not(.active) {
    background-color: #f8f9fa;
}

/* 카테고리 경로 스타일 */
.category-path-badge {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
}

/* 스크롤바 스타일 개선 */
.card-body::-webkit-scrollbar {
    width: 8px;
}

.card-body::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.card-body::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
}

.card-body::-webkit-scrollbar-thumb:hover {
    background: #888;
}
</style>