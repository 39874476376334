<template>
  <b-modal :id="`product-wizard-modal`" size="xl" hide-header hide-footer v-model="showModal" modal-class="wizard-modal"
    dialog-class="product-wizard-modal" no-close-on-backdrop no-close-on-esc>
    <div class="modal-wrapper">
      <!-- 우측 상단 닫기 버튼 -->
      <b-button variant="link" class="modal-close-button" @click="closeWizard">
        <span aria-hidden="true">&times;</span>
      </b-button>

      <div class="wizard-container px-4">
        <!-- 상단 헤더: ProgressBar 고정(sticky) -->
        <div class="wizard-header">
          <h4 class="mb-2">상품 등록 진행: {{ productName }}</h4>
          <progress-bar :current-step="currentStep" :steps="filteredStepsTitles" class="thin-progress"
            @go-to-step="goToStep" />
        </div>

        <!-- 중앙 스크롤 영역 -->
        <div class="wizard-content">
          <component :is="currentComponent" @next="nextStep" @prev="prevStep" />
        </div>

        <!-- 하단 버튼 -->
        <div class="wizard-navigation">
          <b-button v-if="currentStep > 1" @click="prevStep" variant="outline-secondary" class="mx-2"
            :disabled="isProcessing">
            <i class="ri-arrow-left-line"></i> 이전
          </b-button>

          <b-button v-if="currentStep < steps.length" @click="nextStep" variant="outline-primary" class="mx-2"
            :disabled="isProcessing">
            다음 <i class="ri-arrow-right-line"></i>
          </b-button>

          <b-button v-if="currentStep === steps.length" @click="closeWizard" variant="success" class="mx-2"
            :disabled="isProcessing">
            완료 <i class="ri-check-line"></i>
          </b-button>

          <span class="step-indicator">
            {{ currentStep }} / {{ filteredSteps.length }}
          </span>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import ProgressBar from './ProgressBar.vue';
import { mapState, mapMutations } from 'vuex';

// 각 단계별 컴포넌트 import
import MainImageEdit from './MainImageEdit.vue';
import AdditionalImageEdit from './AdditionalImageEdit.vue';
import DetailImageEdit from './DetailImageEdit.vue';
import KoreanLabelEdit from './KoreanLabelEdit.vue';
import PricingEdit from './PricingEdit.vue';
import SeoEdit from './SeoEdit.vue';
import QuotationEdit from './QuotationEdit.vue';
// import FinalPreview from './FinalPreview.vue';
import SizeChartEdit from './SizeChartEdit.vue';

export default {
  name: "ProductWizardModal",
  components: {
    ProgressBar,
    MainImageEdit,
    AdditionalImageEdit,
    DetailImageEdit,
    KoreanLabelEdit,
    SizeChartEdit, // 추가된 부분
    PricingEdit,
    SeoEdit,
    QuotationEdit,
    // FinalPreview
  },

  data() {
    return {
      showModal: false,
      currentStep: 1,

      // 각 단계별 컴포넌트
      steps: [
        SeoEdit,
        PricingEdit,
        MainImageEdit,
        AdditionalImageEdit,
        DetailImageEdit,
        SizeChartEdit, // 추가된 부분
        KoreanLabelEdit,


        QuotationEdit,
        // FinalPreview
      ],

      // 각 단계별 타이틀
      stepsTitles: [
        'SEO 설정',
        '가격 설정',
        '대표 이미지',
        '추가 이미지',
        '상세 이미지',
        '사이즈표',
        '한글 표시사항',


        '견적서 작업'
      ]
    };
  },

  computed: {
    // Vuex 상태 매핑
    ...mapState({
      selectedProduct: state => state.selectedRocketProduct,
      selectedOption: state => state.selectedOption,
      selectedWorkType: state => state.selectedWorkType,
      isProcessing: state => state.processing
    }),

    // 현재 선택된 상품명
    productName() {
      return this.getProductTitle()
    },

    // 현재 단계의 컴포넌트
    currentComponent() {
      return this.filteredSteps[this.currentStep - 1];
    },

    filteredSteps() {
      // 사이즈표가 필요하지 않은 경우 해당 스텝 제외
      if (this.selectedProduct && !this.isSizeChartRequired(this.selectedProduct)) {
        return this.steps.filter(step => step !== SizeChartEdit);
      }
      return this.steps;
    },

    filteredStepsTitles() {
      // 사이즈표가 필요하지 않은 경우 해당 스텝 제목 제외
      if (this.selectedProduct && !this.isSizeChartRequired(this.selectedProduct)) {
        // sizeChart 인덱스 찾기 (steps 배열에서의 인덱스와 일치해야 함)
        const sizeChartIndex = this.steps.findIndex(step => step === SizeChartEdit);
        return this.stepsTitles.filter((_, index) => index !== sizeChartIndex);
      }
      return this.stepsTitles;
    },
  },

  watch: {
    // 선택된 작업 유형에 따라 초기 단계 설정
    selectedWorkType: {
      handler(newWorkType) {
        if (newWorkType) {
          const stepMap = {
            'seo': 1,
            'pricing': 2,

            'mainImage': 3,
            'additionalImage': 4,
            'detailImage': 5,
            'sizeChart': 6,
            'koreanLabel': 7,
            'quotation': 8
          };

          this.currentStep = stepMap[newWorkType] || 1;
        }
      },
      immediate: true
    }
  },

  methods: {
    ...mapMutations([
      'SET_SELECTED_WORK_TYPE'
    ]),
    isSizeChartRequired(product) {
      try {
        if (product && product.rocketRegInfo && product.rocketRegInfo.schemaString) {
          const schema = JSON.parse(product.rocketRegInfo.schemaString);

          if (schema.properties &&
            schema.properties.imagePage &&
            schema.properties.imagePage.properties &&
            schema.properties.imagePage.properties.images &&
            schema.properties.imagePage.properties.images.properties &&
            schema.properties.imagePage.properties.images.required) {

            return schema.properties.imagePage.properties.images.required.includes('sizeChartImage');
          }
        }
      } catch (error) {
        console.error('스키마 파싱 오류:', error);
      }
      return false;
    },
    // 모달 열기
    open() {
      this.showModal = true;
    },

    // 모달 닫기
    closeWizard() {
      this.showModal = false;
      this.$emit('closed');
    },

    // 다음 단계로 이동
    nextStep() {
      if (this.currentStep < this.filteredSteps.length) {
        this.currentStep++;
      }
    },

    // 이전 단계로 이동
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },

    // 특정 단계로 직접 이동
    goToStep(step) {
      if (step >= 1 && step <= this.filteredSteps.length) {
        this.currentStep = step;

        // 작업 유형 업데이트
        const workTypes = [
          'seo',
          'pricing',
          'mainImage',
          'additionalImage',
          'detailImage',
          'sizeChart',
          'koreanLabel',


          'quotation'
        ];

        if (step <= workTypes.length) {
          this.SET_SELECTED_WORK_TYPE(workTypes[step - 1]);
        } else {
          this.SET_SELECTED_WORK_TYPE('');
        }
      }
    },

    // 상품 제목 가져오기
    getProductTitle() {
      if (this.selectedProduct) {
        // SEO 설정이 완료된 경우 SEO 상품명 사용
        if (this.selectedProduct.seoStatus === 'completed' && this.selectedProduct.seo && this.selectedProduct.seo.productName) {
          return this.selectedProduct.seo.productName;
        }
        // 아니면 기존 상품명 사용
        return this.selectedProduct.productName || this.selectedProduct.productNameTrans;
      }
      return '상품 등록';
    }
  }
};
</script>

<style scoped>
::v-deep .modal-wrapper {
  position: relative;
  height: 100%;
}

.wizard-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  color: #000;
}

/* 헤더(ProgressBar) */
.wizard-header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}

/* 중앙 스크롤 영역 */
.wizard-content {
  flex: 1;
  overflow: auto;
  padding: 20px 0;
}

/* 하단 버튼 */
.wizard-navigation {
  position: sticky;
  bottom: 0;
  text-align: center;
  padding: 15px 0;
  background-color: #f9f9f9;
  border-top: 1px solid #eee;
  z-index: 10;
}

/* 닫기 버튼 */
.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
  font-size: 24px;
  background: transparent;
  border: none;
  z-index: 20;
}

/* 전체 모달 스타일 */
:deep(.product-wizard-modal) {
  width: 99% !important;
  max-width: 100% !important;
  height: 99% !important;
  max-height: 100% !important;
  margin: 0 auto !important;
}

:deep(.product-wizard-modal .modal-content) {
  height: 100% !important;
  max-height: 100% !important;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden !important;
}

:deep(.product-wizard-modal .modal-body) {
  flex: 1;
  overflow: hidden !important;
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
}

/* 단계 표시 */
.step-indicator {
  display: inline-block;
  margin-left: 10px;
  color: #666;
  font-size: 14px;
}
</style>