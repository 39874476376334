<template>
  <div class="seo-edit">
    <h4 class="mb-3">SEO 설정</h4>

    <!-- 로딩 중일 때 표시 -->
    <div v-if="loading" class="loading-container">
      <b-spinner variant="primary"></b-spinner>
      <p>{{ progressMessage || 'SEO 설정 작업 중...' }}</p>
    </div>

    <!-- 기본 입력 영역 -->
    <div v-else class="initial-state-container">

      <!-- AI 자동 생성 섹션 -->
      <div class="card mb-4">
        <div class="card-header bg-light">
          <h5 class="mb-0">AI 자동 생성</h5>
        </div>
        <div class="card-body">
          <p>AI가 상품 정보를 분석하여 최적의 SEO 설정을 자동으로 생성합니다.</p>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>브랜드</label>
                <input type="text" class="form-control" v-model="brandName"
                  :placeholder="defaultBrand || '브랜드명을 입력하세요 (예: 쿠플러스 등등)'" />
                <small class="form-text text-muted">
                  기본 설정의 브랜드가 자동으로 입력됩니다
                </small>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>상품 특징</label>
                <input type="text" class="form-control" v-model="productFeature"
                  placeholder="상품 특징을 입력하세요 (예: 인조가죽 등)" />
                <small class="form-text text-muted">
                  상품명에 포함될 주요 특징을 입력하세요
                </small>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>타겟 키워드</label>
                <input type="text" class="form-control" v-model="aiTargetKeyword"
                  placeholder="핵심 타겟 키워드를 입력하세요 (예: 크로스백 등)" />
                <small class="form-text text-muted">
                  이 키워드를 중심으로 SEO 최적화가 진행됩니다
                </small>
              </div>
            </div>
          </div>

          <div class="alert alert-info mt-3">
            <p class="mb-1"><strong>생성될 상품명 예시:</strong></p>
            <p class="mb-0">{{ brandName || defaultBrand || '쿠플러스' }} {{ productFeature || '인조가죽' }} {{ aiTargetKeyword
              || '크로스백' }}</p>
          </div>

          <div class="text-center mt-3">
            <b-button variant="outline-primary" @click="startAiGeneration" :disabled="!canStartAiGeneration">
              <i class="las la-robot"></i> AI로 자동 생성
            </b-button>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <!-- 상품명 및 검색 키워드 -->
        <div class="col-md-6">
          <div class="card">
            <div class="card-header bg-light">
              <h5 class="mb-0">상품명 / 검색태그</h5>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label>상품명</label>
                <input type="text" class="form-control" v-model="seoData.productName" placeholder="검색 최적화된 상품명을 입력하세요"
                  maxlength="100" />
                <div class="text-muted mt-1">
                  <small>{{ seoData.productName.length }}/100자</small>
                </div>
              </div>

              <div class="form-group">
                <label>검색태그 (쉼표로 구분)</label>
                <textarea class="form-control" v-model="keywordsText" rows="3"
                  placeholder="키워드1, 키워드2, 키워드3 형태로 입력하세요"></textarea>
                <small class="form-text text-muted">
                  검색에 노출되길 원하는 키워드를 쉼표로 구분하여 입력하세요
                </small>
              </div>
            </div>
          </div>
        </div>

        <!-- 옵션명 설정 -->
        <div class="col-md-6">
          <div class="card">
            <div class="card-header bg-light">
              <h5 class="mb-0">옵션명</h5>
            </div>
            <div class="card-body">
              <div class="options-container">
                <div v-if="!productOptions || productOptions.length === 0" class="text-center p-4">
                  <p class="text-muted">옵션 정보가 없습니다.</p>
                </div>
                <div v-else class="options-list">
                  <div v-for="(option, index) in productOptions" :key="index"
                    class="option-item mb-3 p-2 border-bottom">
                    <div class="d-flex">
                      <div class="option-image mr-3">
                        <img :src="getOptionImage(option)" class="img-thumbnail" style="width: 60px; height: 60px;"
                          alt="옵션 이미지">
                      </div>
                      <div class="option-details flex-grow-1">
                        <div class="option-original mb-1">
                          <small class="text-muted">원본: {{ getOptionOriginalName(option) }}</small>
                        </div>
                        <div class="form-group mb-0">
                          <input type="text" class="form-control" v-model="option.korName" placeholder="한글 옵션명 입력" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <!-- 저장 버튼 -->
      <div class="action-button-container text-center mt-4">
        <b-button variant="primary" size="lg" @click="saveSeoData" :disabled="!canSave">
          <i class="las la-save"></i> SEO 설정 저장
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import seoService from '../../services/works/seoService';
import workflowService from '../../services/workflowService';

export default {
  name: "SeoEdit",
  data() {
    return {
      // AI 자동 생성 옵션
      aiTargetKeyword: '',
      brandName: '',
      productFeature: '',

      // 수동 입력 데이터
      targetKeyword: '',
      keywordsText: '',

      // 옵션 데이터
      productOptions: [],

      // SEO 데이터
      seoData: {
        productName: '',
        keywords: []
      }
    };
  },
  computed: {
    // Vuex 상태 매핑
    ...mapState({
      product: state => state.selectedRocketProduct,
      selectedOption: state => state.selectedOption,
      selectedWorkType: state => state.selectedWorkType,
      processing: state => state.processing,
      progressMessage: state => state.progressMessage
    }),
    defaultBrand() {
      return this.$store.state.settings[0]?.defaultSettings?.brand || '';
    },

    // 로딩 상태
    loading() {
      return this.processing;
    },

    // 저장 가능 여부 체크
    canSave() {
      return !!this.seoData.productName.trim();
    },

    // AI 생성 시작 가능 여부 체크
    canStartAiGeneration() {
      return !!this.aiTargetKeyword.trim();
    }
  },
  mounted() {
    // 초기 데이터 설정
    this.initializeData();
  },
  methods: {
    // Vuex 액션 매핑
    ...mapActions([
      'updateRocketProduct',
      'setProcessing'
    ]),

    // 초기 데이터 설정
    initializeData() {
      if (this.product) {
        // 타겟 키워드 초기화
        this.targetKeyword = this.product.targetKeyword || '';
        this.aiTargetKeyword = this.product.targetKeyword || '';

        // 브랜드명 초기화
        this.brandName = this.defaultBrand || this.product.brand || '';

        // 상품 특징 초기화
        this.productFeature = this.product.productFeature || '';

        // 상품명 초기화
        this.seoData.productName = this.product.productNameTrans || this.product.productName || '';

        // 기존 SEO 데이터가 있으면 로드
        if (this.product.seo) {
          this.seoData.productName = this.product.seo.productName || this.seoData.productName;
          this.seoData.keywords = this.product.seo.keywords || [];
          this.keywordsText = this.seoData.keywords.join(', ');
        } else {
          this.keywordsText = this.targetKeyword || '';
        }

        // 옵션 데이터 초기화
        this.initializeOptions();
      }
    },

    // 옵션 데이터 초기화
    initializeOptions() {
      if (this.product && this.product.options && this.product.options.length > 0) {
        // 깊은 복사로 옵션 데이터 복사
        this.productOptions = JSON.parse(JSON.stringify(this.product.options)).map(option => {
          // korName이 없으면 추가
          if (!option.korName) {
            option.korName = '';
          }
          return option;
        });
      } else {
        this.productOptions = [];
      }
    },

    // 옵션 이미지 가져오기
    getOptionImage(option) {
      // mainImageStatus에서 이미지 가져오기
      if (option.mainImageStatus && option.mainImageStatus.image) {
        return option.mainImageStatus.image;
      }
      // option1에서 이미지 가져오기
      else if (option.option1 && option.option1.imageUrl) {
        return option.option1.imageUrl;
      }
      // 기본 이미지 반환
      return "";
    },

    // 옵션 원본 이름 가져오기
    getOptionOriginalName(option) {
      let originalName = '';

      // option1 정보 추가
      if (option.option1) {
        originalName += option.option1.name || '';
        // option1 번역 정보가 있으면 추가
        if (option.option1.nameTrans) {
          originalName += ` (${option.option1.nameTrans})`;
        }
      }

      // option2 정보가 있으면 추가
      if (option.option2) {
        originalName += ' / ' + (option.option2.name || '');
        // option2 번역 정보가 있으면 추가
        if (option.option2.nameTrans) {
          originalName += ` (${option.option2.nameTrans})`;
        }
      }

      return originalName || '옵션 정보 없음';
    },

    // SeoEdit.vue의 startAiGeneration 메서드 수정
    async startAiGeneration() {
      if (!this.canStartAiGeneration) return;

      try {
        // 로딩 상태 시작
        this.setProcessing({
          isProcessing: true,
          message: 'SEO 최적화 준비 중...'
        });

        // 연관 키워드 수집
        if (this.aiTargetKeyword.trim()) {
          try {
            // workflowService를 통해 연관 키워드 수집
            this.relatedKeywords = await workflowService.collectCoupangKeywords(
              this.aiTargetKeyword,
              { cycle: 1, relatedKeywords: true, autoComplete: true }
            );

            // 키워드 수집 성공 알림
            this.$bvToast.toast(`${this.relatedKeywords.length}개의 연관 키워드를 수집했습니다.`, {
              title: '성공',
              variant: 'success',
              solid: true
            });
          } catch (error) {
            console.warn('연관 키워드 수집 실패:', error);
            // 실패해도 계속 진행
          }
        }

        // SEO 생성 요청 (비동기)
        this.requestSeoGeneration();

        // 사용자에게 알림
        this.$bvToast.toast('SEO 정보 생성 요청을 보냈습니다. 결과가 도착하면 자동으로 적용됩니다.', {
          title: '정보',
          variant: 'info',
          solid: true
        });
      } catch (error) {
        this.setProcessing({ isProcessing: false });

        this.$bvToast.toast(`SEO 생성 준비 중 오류: ${error.message}`, {
          title: '오류',
          variant: 'danger',
          solid: true
        });
      }
    },

    // 비동기 SEO 생성 요청 메서드 추가
    requestSeoGeneration() {
      // 옵션 정보 구성
      const options = {
        targetKeyword: this.aiTargetKeyword,
        brandName: this.brandName || this.defaultBrand,
        productFeature: this.productFeature,
        relatedKeywords: this.relatedKeywords
      };

      // workflowService를 통해 AI SEO 생성 요청
      const productId = this.product.registrationId;

      // 프롬프트 생성
      const prompt = seoService.promptForSeo(this.product, options);

      // 확장 프로그램에 요청
      workflowService.requestExtension({
        type: 'SEO',
        productId: productId,
        prompt: prompt
      });

      // 요청 중 상태 표시
      this.setProcessing({
        isProcessing: true,
        message: 'SEO 정보 생성 중...'
      });
    },

    // SEO 설정 저장
    async saveSeoData() {
      if (!this.product || !this.canSave) return;

      try {
        // 로딩 상태 시작
        this.setProcessing({
          isProcessing: true,
          message: 'SEO 설정 저장 중...'
        });

        // 키워드 처리
        const keywords = this.keywordsText
          .split(',')
          .map(keyword => keyword.trim())
          .filter(keyword => keyword.length > 0);

        // 제품 업데이트 준비
        const updatedProduct = JSON.parse(JSON.stringify(this.product));

        // SEO 데이터가 없으면 초기화
        if (!updatedProduct.seo) {
          updatedProduct.seo = {};
        }

        // SEO 데이터 설정
        updatedProduct.seo.productName = this.seoData.productName;
        updatedProduct.seo.keywords = keywords;
        updatedProduct.targetKeyword = this.targetKeyword;

        // 옵션 정보 업데이트
        if (updatedProduct.options && updatedProduct.options.length > 0) {
          updatedProduct.options.forEach(option => {
            const matchingOption = this.productOptions.find(opt =>
              opt.optionNumber === option.optionNumber || opt.id === option.id
            );
            if (matchingOption && matchingOption.korName) {
              option.korName = matchingOption.korName;
            }
          });
        }

        // SEO 상태 업데이트
        updatedProduct.seoStatus = 'completed';

        // 상품 업데이트
        await this.updateRocketProduct(updatedProduct);

        // 성공 메시지
        this.$bvToast.toast('SEO 설정이 저장되었습니다.', {
          title: '성공',
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-bottom-right'
        });
      } catch (error) {
        console.error('SEO 설정 저장 중 오류:', error);

        // 오류 메시지
        this.$bvToast.toast('SEO 설정 저장 중 오류가 발생했습니다.', {
          title: '오류',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-right'
        });
      } finally {
        // 로딩 상태 종료
        this.setProcessing({ isProcessing: false });
      }
    }
  },
  watch: {
    // 제품 변경 감지
    product: {
      handler(newVal) {
        if (newVal) {
          this.initializeData();
        }
      },
      deep: true
    },
    // 진행 메시지 변경 감지
    progressMessage(newMessage) {
      // SEO 작업 완료 메시지인 경우
      if (newMessage.includes('SEO 정보가 성공적으로 생성되었습니다')) {
        // 토스트 메시지 표시
        this.$bvToast.toast(newMessage, {
          title: '성공',
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-bottom-right'
        });

        // 컴포넌트 데이터 새로고침 (필요한 경우)
        this.initializeData();
      }
      // 오류 메시지인 경우
      else if (newMessage.includes('오류')) {
        this.$bvToast.toast(newMessage, {
          title: '오류',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-right'
        });
      }
    },
  }
}
</script>

<style scoped>
.seo-edit {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* 로딩 컨테이너 */
.loading-container {
  text-align: center;
  padding: 40px 0;
}

/* 초기 상태 */
.initial-state-container {
  background-color: #fff;
  padding: 20px;
}

/* 옵션 리스트 스타일 */
.options-list {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 10px;
}

.option-item {
  transition: background-color 0.2s;
}

.option-item:hover {
  background-color: #f8f9fa;
}

.option-image img {
  object-fit: cover;
}

/* 키워드 태그 */
.keyword-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.keyword-tag {
  background-color: #e9f5ff;
  color: #0366d6;
  padding: 4px 10px;
  border-radius: 12px;
  font-size: 0.9rem;
}

/* 액션 버튼 */
.action-button-container {
  margin-top: 30px;
}
</style>