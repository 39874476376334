<template>
  <div class="pricing-edit w-100">
    <h3 class="section-title">가격 설정</h3>

    <!-- 로딩 중일 때 표시 -->
    <div v-if="processing" class="loading-container">
      <b-spinner variant="primary"></b-spinner>
      <p>{{ progressMessage || '가격 설정 작업 중...' }}</p>
    </div>

    <!-- 가격 정책 설정 및 결과 동시 표시 -->
    <div v-else class="pricing-content">
      <div class="row mb-4">
        <!-- 왼쪽: 가격 정책 설정 패널 -->
        <div class="col-md-2">
          <div class="card h-100">
            <div class="card-header bg-light">
              <span class="font-weight-bold">가격 정책 설정</span>
            </div>
            <div class="card-body pricing-policy-panel">
              <div class="form-group">
                <label>적용 환율 (CNY → KRW)</label>
                <input type="number" class="form-control" v-model.number="editExchangeRate" min="150" max="400"
                  @change="updatePricing" />
              </div>

              <div class="form-group">
                <label>공급 마진율 (%)</label>
                <input type="number" class="form-control" v-model.number="editMarginRate" min="0" max="90"
                  @change="updatePricing" />
              </div>

              <div class="form-group">
                <label>쿠팡 마진율 (%)</label>
                <input type="number" class="form-control" v-model.number="editCoupangMarginRate" min="0" max="90"
                  @change="updatePricing" />
              </div>

              <div class="form-group">
                <label>반올림 단위 (원)</label>
                <select v-model.number="editRoundUnit" class="form-control" @change="updatePricing">
                  <option :value="1">1원 단위</option>
                  <option :value="10">10원 단위</option>
                  <option :value="100">100원 단위</option>
                  <option :value="1000">1,000원 단위</option>
                </select>
              </div>

              <div class="form-group">
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="bundlingSwitch" v-model="editEnableBundling"
                    @change="updatePricing" />
                  <label class="custom-control-label" for="bundlingSwitch">번들링 활성화</label>
                </div>
                <small class="form-text text-muted">낮은 마진 상품을 자동으로 묶어서 판매합니다.</small>
              </div>

              <div v-if="editEnableBundling" class="bundling-details mt-3">
                <div class="form-group">
                  <label>번들링 기준</label>
                  <div class="custom-control custom-radio">
                    <input type="radio" id="marginAmountRadio" name="bundlingType" class="custom-control-input"
                      value="marginAmount" v-model="editBundlingType" @change="updatePricing" />
                    <label class="custom-control-label" for="marginAmountRadio">최소 공급 마진액 기준</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input type="radio" id="coupangMarginAmountRadio" name="bundlingType" class="custom-control-input"
                      value="coupangMarginAmount" v-model="editBundlingType" @change="updatePricing" />
                    <label class="custom-control-label" for="coupangMarginAmountRadio">최소 쿠팡 마진액 기준</label>
                  </div>
                </div>

                <div v-if="editBundlingType === 'marginAmount'" class="form-group">
                  <label>최소 공급 마진액 (원)</label>
                  <input type="number" class="form-control" v-model.number="editMinMarginAmount" min="1000" step="1000"
                    @change="updatePricing" />
                </div>

                <div v-else class="form-group">
                  <label>최소 쿠팡 마진액 (원)</label>
                  <input type="number" class="form-control" v-model.number="editMinCoupangMarginAmount" min="1000"
                    step="1000" @change="updatePricing" />
                </div>
              </div>

              <div class="mt-3">
                <b-button variant="primary" @click="savePricing" class="btn-block">저장하기</b-button>
              </div>
            </div>
          </div>
        </div>

        <!-- 오른쪽: 옵션별 가격 정보 테이블 (인라인 편집 방식으로 변경) -->
        <div class="col-md-10">
          <div class="card h-100">
            <div class="card-header bg-light d-flex justify-content-between align-items-center">
              <span class="font-weight-bold">옵션별 가격 정보</span>
              <b-button size="sm" variant="outline-primary" @click="updatePricing">일괄 계산</b-button>
            </div>
            <div class="card-body p-0">
              <div class="table-responsive">
                <table class="table table-hover table-bordered mb-0">
                  <thead class="thead-light">
                    <tr>
                      <th>옵션명</th>
                      <th class="text-center">번들/재고</th>
                      <th class="text-right">중국 원가 (CNY)</th>
                      <th class="text-right">공급가 (KRW)</th>
                      <th class="text-right">공급 마진</th>
                      <th class="text-right">판매가 (KRW)</th>
                      <th class="text-right">쿠팡 마진</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(option, index) in calculatedOptions" :key="option.optionNumber">
                      <td>
                        <div class="font-weight-medium">{{ getOptionName(option) }}</div>
                        <small class="text-muted">{{ option.optionNumber }}</small>
                      </td>
                      <!-- 번들 수량 입력 -->
                      <td class="text-center">
                        <div class="input-group">
                          <input type="number" class="form-control form-control-sm"
                            v-model.number="option.bundleQuantity" min="1" @change="recalculateOption(index)" />
                          <div class="input-group-append">
                            <span class="input-group-text">개</span>
                          </div>
                        </div>
                        <div class="mt-1">
                          <input type="number" class="form-control form-control-sm" v-model.number="option.stock"
                            min="0" />
                          <small>재고</small>
                        </div>
                      </td>
                      <!-- 중국 원가 입력 -->
                      <td class="text-right">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">¥</span>
                          </div>
                          <input type="number" class="form-control form-control-sm text-right"
                            v-model.number="option.price" min="0" step="0.1" @change="recalculateOption(index)" />
                        </div>
                        <div class="text-muted small mt-1">
                          {{ formatNumber(option.price * editExchangeRate) }}원
                        </div>
                      </td>
                      <!-- 공급가 입력 -->
                      <td class="text-right">
                        <div class="input-group">
                          <input type="number" class="form-control form-control-sm text-right"
                            v-model.number="option.supplyPrice" min="0" step="100"
                            @change="recalculateMargins(index)" />
                          <div class="input-group-append">
                            <span class="input-group-text">원</span>
                          </div>
                        </div>
                      </td>
                      <!-- 공급 마진 표시 -->
                      <td class="text-right">
                        <div
                          :class="{ 'text-success': option.supplyMargin > 0, 'text-danger': option.supplyMargin < 0 }">
                          {{ formatNumber(option.supplyMargin) }}원
                        </div>
                        <div class="text-muted small">
                          ({{ calculateMarginRate(option.purchasePrice, option.supplyPrice) }}%)
                        </div>
                      </td>
                      <!-- 판매가 입력 -->
                      <td class="text-right">
                        <div class="input-group">
                          <input type="number" class="form-control form-control-sm text-right"
                            v-model.number="option.sellingPrice" min="0" step="100"
                            @change="recalculateMargins(index)" />
                          <div class="input-group-append">
                            <span class="input-group-text">원</span>
                          </div>
                        </div>
                      </td>
                      <!-- 쿠팡 마진 표시 -->
                      <td class="text-right">
                        <div
                          :class="{ 'text-success': option.sellingMargin > 0, 'text-danger': option.sellingMargin < 0 }">
                          {{ formatNumber(option.sellingMargin) }}원
                        </div>
                        <div class="text-muted small">
                          ({{ calculateMarginRate(option.supplyPrice, option.sellingPrice) }}%)
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 가격 구성 비율 시각화 -->
      <div class="card mb-4">
        <div class="card-header bg-light">
          <span>가격 구성 비율</span>
        </div>
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-4 text-center">상품 원가</div>
            <div class="col-md-4 text-center">공급 마진</div>
            <div class="col-md-4 text-center">쿠팡 마진</div>
          </div>
          <div class="progress mb-3" style="height: 30px; border-radius: 5px;">
            <div class="progress-bar bg-warning" role="progressbar" :style="{ width: costRatio + '%' }"
              :aria-valuenow="costRatio" aria-valuemin="0" aria-valuemax="100">
              {{ costRatio.toFixed(0) }}%
            </div>
            <div class="progress-bar bg-success" role="progressbar" :style="{ width: editMarginRate + '%' }"
              :aria-valuenow="editMarginRate" aria-valuemin="0" aria-valuemax="100">
              {{ editMarginRate.toFixed(0) }}%
            </div>
            <div class="progress-bar bg-primary" role="progressbar" :style="{ width: editCoupangMarginRate + '%' }"
              :aria-valuenow="editCoupangMarginRate" aria-valuemin="0" aria-valuemax="100">
              {{ editCoupangMarginRate.toFixed(0) }}%
            </div>
          </div>
        </div>
      </div>

      <!-- 전체 가격 요약 카드 -->
      <div class="card mb-4">
        <div class="card-header bg-light">
          <span>가격 요약</span>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="card border-light mb-3">
                <div class="card-header bg-transparent border-light">평균 공급가</div>
                <div class="card-body">
                  <h5 class="card-title">{{ formatNumber(averageSupplyPrice) }}원</h5>
                  <p class="card-text">평균 공급 마진: {{ formatNumber(averageSupplyMargin) }}원</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card border-light mb-3">
                <div class="card-header bg-transparent border-light">평균 판매가</div>
                <div class="card-body">
                  <h5 class="card-title">{{ formatNumber(averageSellingPrice) }}원</h5>
                  <p class="card-text">평균 쿠팡 마진: {{ formatNumber(averageSellingMargin) }}원</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card border-light mb-3">
                <div class="card-header bg-transparent border-light">평균 마진율</div>
                <div class="card-body">
                  <h5 class="card-title">공급: {{ averageSupplyMarginRate }}%</h5>
                  <p class="card-text">쿠팡: {{ averageCoupangMarginRate }}%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 다음 단계 버튼 -->
      <div class="navigation-buttons mt-4 text-center">
        <b-button variant="primary" @click="savePricing" class="mr-2">
          저장하기
        </b-button>
        <!-- <b-button variant="success" @click="goToNextStep">
          다음 단계로 (SEO 설정)
        </b-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "PricingEdit",
  data() {
    return {
      // 가격 정책 편집 데이터
      editExchangeRate: 350,
      editMarginRate: 40,
      editCoupangMarginRate: 40,
      editRoundUnit: 100,
      editEnableBundling: false,
      editBundlingType: 'marginAmount',
      editMinMarginAmount: 3000,
      editMinCoupangMarginAmount: 3000,

      // 옵션 가격 계산 결과 캐시
      calculatedOptions: []
    };
  },
  computed: {
    ...mapState({
      selectedProduct: state => state.selectedRocketProduct,
      processing: state => state.processing,
      progressMessage: state => state.progressMessage,
      settings: state => state.settings[0]?.defaultSettings || {}
    }),

    // 제품 옵션 리스트
    productOptions() {
      return this.selectedProduct?.options || [];
    },

    // 원가 비율
    costRatio() {
      // 100% - 마진율 - 쿠팡마진율
      return Math.max(1, 100 - this.editMarginRate - this.editCoupangMarginRate);
    },

    // 가격 정책 정보
    pricingInfo() {
      return {
        exchangeRate: this.editExchangeRate,
        marginRate: this.editMarginRate,
        coupangMarginRate: this.editCoupangMarginRate,
        roundUnit: this.editRoundUnit,
        enableBundling: this.editEnableBundling,
        bundlingType: this.editBundlingType,
        minMarginAmount: this.editMinMarginAmount,
        minCoupangMarginAmount: this.editMinCoupangMarginAmount
      };
    },

    // 평균 공급가
    averageSupplyPrice() {
      if (!this.calculatedOptions.length) return 0;
      const total = this.calculatedOptions.reduce((sum, opt) => sum + opt.supplyPrice, 0);
      return Math.round(total / this.calculatedOptions.length);
    },

    // 평균 판매가
    averageSellingPrice() {
      if (!this.calculatedOptions.length) return 0;
      const total = this.calculatedOptions.reduce((sum, opt) => sum + opt.sellingPrice, 0);
      return Math.round(total / this.calculatedOptions.length);
    },

    // 평균 공급 마진
    averageSupplyMargin() {
      if (!this.calculatedOptions.length) return 0;
      const total = this.calculatedOptions.reduce((sum, opt) => sum + opt.supplyMargin, 0);
      return Math.round(total / this.calculatedOptions.length);
    },

    // 평균 쿠팡 마진
    averageSellingMargin() {
      if (!this.calculatedOptions.length) return 0;
      const total = this.calculatedOptions.reduce((sum, opt) => sum + opt.sellingMargin, 0);
      return Math.round(total / this.calculatedOptions.length);
    },

    // 평균 공급 마진율
    averageSupplyMarginRate() {
      if (!this.calculatedOptions.length) return 0;
      const rates = this.calculatedOptions.map(opt =>
        this.calculateMarginRate(opt.purchasePrice, opt.supplyPrice)
      );
      const total = rates.reduce((sum, rate) => sum + rate, 0);
      return Math.round(total / rates.length);
    },

    // 평균 쿠팡 마진율
    averageCoupangMarginRate() {
      if (!this.calculatedOptions.length) return 0;
      const rates = this.calculatedOptions.map(opt =>
        this.calculateMarginRate(opt.supplyPrice, opt.sellingPrice)
      );
      const total = rates.reduce((sum, rate) => sum + rate, 0);
      return Math.round(total / rates.length);
    }
  },
  watch: {
    // 선택된 상품이 변경되면 기본 설정 로드
    selectedProduct: {
      handler(newProduct) {
        if (newProduct) {
          this.loadDefaultSettings();
          this.initializeCalculatedOptions();
        }
      },
      immediate: true
    },

    // 설정이 변경되면 편집 데이터 업데이트
    settings: {
      handler() {
        this.loadDefaultSettings();
      },
      immediate: true
    }
  },
  methods: {
    // 기본 설정 로드
    loadDefaultSettings() {
      // 상품에 저장된 가격 정보가 있으면 사용
      if (this.selectedProduct && this.selectedProduct.pricing && this.selectedProduct.pricing.info) {
        const pricing = this.selectedProduct.pricing.info;
        this.editExchangeRate = pricing.exchangeRate || 350;
        this.editMarginRate = pricing.marginRate || 40;
        this.editCoupangMarginRate = pricing.coupangMarginRate || 40;
        this.editRoundUnit = pricing.roundUnit || 100;
        this.editEnableBundling = pricing.enableBundling || false;
        this.editBundlingType = pricing.bundlingType || 'marginAmount';
        this.editMinMarginAmount = pricing.minMarginAmount || 3000;
        this.editMinCoupangMarginAmount = pricing.minCoupangMarginAmount || 3000;
      }
      // 아니면 기본 설정 사용
      else if (this.settings) {
        this.editExchangeRate = this.settings.exchangeRate || 350;
        this.editMarginRate = this.settings.marginRate || 40;
        this.editCoupangMarginRate = this.settings.coupangMarginRate || 40;
        this.editRoundUnit = this.settings.roundUnit || 100;
        this.editEnableBundling = this.settings.enableBundling || false;
        this.editBundlingType = this.settings.bundlingType || 'marginAmount';
        this.editMinMarginAmount = this.settings.minMarginAmount || 3000;
        this.editMinCoupangMarginAmount = this.settings.minCoupangMarginAmount || 3000;
      }
    },

    // 계산된 옵션 초기화
    initializeCalculatedOptions() {
      if (!this.selectedProduct || !this.selectedProduct.options) return;

      // 상품에 저장된 가격 정보가 있으면 사용
      if (this.selectedProduct.pricing && this.selectedProduct.pricing.options) {
        this.calculatedOptions = JSON.parse(JSON.stringify(this.selectedProduct.pricing.options));
      }
      // 아니면 옵션을 기반으로 계산
      else {
        this.calculatedOptions = this.selectedProduct.options.map(option => ({
          optionNumber: option.optionNumber,
          optionName: this.getOptionName(option),
          korName: option.korName,
          price: option.price || 0,
          stock: option.stock || 0,
          bundleQuantity: option.bundleQuantity || 1,
          option1: option.option1,
          option2: option.option2
        }));
        this.updatePricing();
      }
    },

    // 가격 정책 변경 시 모든 옵션의 가격 다시 계산
    updatePricing() {
      if (!this.calculatedOptions.length) return;

      this.calculatedOptions.forEach((option, index) => {
        this.recalculateOption(index);
      });
    },

    // 개별 옵션 재계산
    recalculateOption(index) {
      const option = this.calculatedOptions[index];
      if (!option) return;

      // 매입가 계산 (CNY × 환율 × 번들수량)
      const purchasePrice = option.price * this.editExchangeRate * option.bundleQuantity;

      // 공급가 계산 (매입가 ÷ (1 - 공급마진율/100))
      const marginRateDecimal = this.editMarginRate / 100;
      const rawSupplyPrice = purchasePrice / (1 - marginRateDecimal);
      const supplyPrice = Math.round(rawSupplyPrice / this.editRoundUnit) * this.editRoundUnit;

      // 판매가 계산 (공급가 ÷ (1 - 쿠팡마진율/100))
      const coupangRateDecimal = this.editCoupangMarginRate / 100;
      const rawSellingPrice = supplyPrice / (1 - coupangRateDecimal);
      const sellingPrice = Math.round(rawSellingPrice / this.editRoundUnit) * this.editRoundUnit;

      // 마진 계산
      const supplyMargin = supplyPrice - purchasePrice;
      const sellingMargin = sellingPrice - supplyPrice;

      // 번들 수량 자동 계산 (번들링 활성화된 경우)
      if (this.editEnableBundling) {
        option.bundleQuantity = this.calculateBundleQuantity(
          option.price,
          this.editExchangeRate,
          this.editMarginRate,
          this.editCoupangMarginRate,
          this.editBundlingType,
          this.editMinMarginAmount,
          this.editMinCoupangMarginAmount
        );
      }

      // 옵션 업데이트
      option.purchasePrice = purchasePrice;
      option.supplyPrice = supplyPrice;
      option.sellingPrice = sellingPrice;
      option.supplyMargin = supplyMargin;
      option.sellingMargin = sellingMargin;
    },

    // 마진 재계산 (공급가나 판매가가 직접 수정됐을 때)
    recalculateMargins(index) {
      const option = this.calculatedOptions[index];
      if (!option) return;

      // 매입가 계산 (변경 없음)
      const purchasePrice = option.price * this.editExchangeRate * option.bundleQuantity;
      option.purchasePrice = purchasePrice;

      // 마진 계산 (직접 입력한 값 기준)
      option.supplyMargin = option.supplyPrice - purchasePrice;
      option.sellingMargin = option.sellingPrice - option.supplyPrice;
    },

    // 번들 수량 계산
    calculateBundleQuantity(cnyPrice, exchangeRate, marginRate, coupangMarginRate, bundlingType, minMarginAmount, minCoupangMarginAmount) {
      // 단일 상품으로 계산한 가격
      const purchasePrice = cnyPrice * exchangeRate;
      const marginRateDecimal = marginRate / 100;
      const coupangRateDecimal = coupangMarginRate / 100;

      const rawSupplyPrice = purchasePrice / (1 - marginRateDecimal);
      const supplyPrice = Math.round(rawSupplyPrice / 100) * 100;

      const rawSellingPrice = supplyPrice / (1 - coupangRateDecimal);
      const sellingPrice = Math.round(rawSellingPrice / 100) * 100;

      // 마진 계산
      const supplyMargin = supplyPrice - purchasePrice;
      const sellingMargin = sellingPrice - supplyPrice;

      // 번들링 기준에 따른 조건 확인
      let bundleQuantity = 1;

      if (bundlingType === 'marginAmount') {
        // 공급 마진액 기준
        if (supplyMargin < minMarginAmount) {
          // 2개, 4개, 6개... 단위로 번들링
          while (supplyMargin * bundleQuantity < minMarginAmount && bundleQuantity < 20) {
            bundleQuantity += 2;
          }
        }
      } else { // 'coupangMarginAmount'
        // 쿠팡 마진액 기준
        if (sellingMargin < minCoupangMarginAmount) {
          // 2개, 4개, 6개... 단위로 번들링
          while (sellingMargin * bundleQuantity < minCoupangMarginAmount && bundleQuantity < 20) {
            bundleQuantity += 2;
          }
        }
      }

      return bundleQuantity;
    },

    // 가격 설정 저장
    async savePricing() {
      if (!this.selectedProduct) {
        this.$bvToast.toast('선택된 상품이 없습니다.', {
          title: '알림',
          variant: 'warning',
          solid: true,
          toaster: 'b-toaster-bottom-right'
        });
        return;
      }

      try {
        // 계산된 결과 정리
        const pricingData = {
          info: this.pricingInfo,
          options: this.calculatedOptions
        };

        // 상품 객체 업데이트
        const updatedProduct = { ...this.selectedProduct };
        updatedProduct.pricing = pricingData;
        updatedProduct.pricingStatus = 'completed';

        // 각 옵션의 가격 정보도 업데이트
        if (updatedProduct.options && updatedProduct.options.length > 0) {
          updatedProduct.options.forEach(option => {
            const matchedOption = this.calculatedOptions.find(o => o.optionNumber === option.optionNumber);
            if (matchedOption) {
              option.price = matchedOption.price;
              option.bundleQuantity = matchedOption.bundleQuantity;
              option.supplyPrice = matchedOption.supplyPrice;
              option.sellingPrice = matchedOption.sellingPrice;
              option.purchasePrice = matchedOption.purchasePrice;
              option.supplyMargin = matchedOption.supplyMargin;
              option.sellingMargin = matchedOption.sellingMargin;
              option.stock = matchedOption.stock;
              option.pricingStatus = 'completed';
            }
          });
        }

        // Vuex 스토어에 저장
        await this.$store.dispatch('updateRocketProduct', updatedProduct);

        // 성공 메시지 표시
        this.$bvToast.toast('가격 설정이 완료되었습니다.', {
          title: '성공',
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-bottom-right'
        });

        return true;
      } catch (error) {
        console.error('가격 설정 저장 중 오류:', error);
        this.$bvToast.toast('가격 설정 저장 중 오류가 발생했습니다: ' + error.message, {
          title: '오류',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-right'
        });

        return false;
      }
    },

    // // 다음 단계로 이동
    // async goToNextStep() {
    //   // 먼저 현재 설정 저장
    //   const saved = await this.savePricing();
    //   if (saved) {
    //     // SEO 설정 또는 다음 작업 단계로 이동
    //     this.$emit('next-step', 'seo');
    //   }
    // },

    // 숫자 포맷팅
    formatNumber(value) {
      return new Intl.NumberFormat().format(value || 0);
    },

    // 마진율 계산
    calculateMarginRate(cost, price) {
      if (!cost || !price || cost >= price) return 0;
      return Math.round(((price - cost) / price) * 100);
    },

    // 옵션명 가져오기 (korName 우선 사용)
    getOptionName(option) {
      if (!option) return '기본 옵션';

      // 1. 우선순위: option.korName (직접 접근)
      if (option.korName) {
        return option.korName;
      }

      // 2. 원본 옵션에서 korName 찾기 (옵션이 계산된 옵션인 경우)
      if (option.optionNumber && this.selectedProduct && this.selectedProduct.options) {
        const originalOption = this.selectedProduct.options.find(
          opt => opt.optionNumber === option.optionNumber
        );
        if (originalOption && originalOption.korName) {
          return originalOption.korName;
        }
      }

      // 3. optionName 사용
      if (option.optionName) {
        return option.optionName;
      }

      // 4. option1과 option2 조합
      let name = '';
      if (option.option1) {
        name += option.option1.nameTrans || option.option1.name || '';
      }
      if (option.option2) {
        name += ' / ' + (option.option2.nameTrans || option.option2.name || '');
      }

      return name || '기본 옵션';
    }
  }
}
</script>

<style scoped>
.pricing-edit {
  padding: 20px;
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.section-title {
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

/* 로딩 컨테이너 */
.loading-container {
  text-align: center;
  padding: 40px 0;
}

/* 가격 정책 패널 */
.pricing-policy-panel {
  max-height: 600px;
  overflow-y: auto;
}

/* 테이블 숫자 정렬 */
.text-right {
  text-align: right !important;
}

/* 옵션 입력 스타일 */
.form-control-sm {
  font-size: 0.875rem;
}

/* 입력 필드 너비 조정 */
input[type="number"] {
  min-width: 80px;
}

/* 마진 강조 스타일 */
.text-success {
  font-weight: 600;
}

.text-danger {
  font-weight: 600;
}

/* 테이블 셀 패딩 조정 */
.table td {
  padding: 0.5rem;
  vertical-align: middle;
}

/* 요약 정보 카드 스타일 */
.card-title {
  font-weight: bold;
  color: #495057;
}
</style>