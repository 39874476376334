<template>
  <div class="size-chart-edit">
    <h3 class="section-title">사이즈표 작업</h3>

    <!-- 로딩 중일 때 표시 -->
    <div v-if="isProcessing" class="loading-container">
      <b-spinner variant="primary"></b-spinner>
      <p>{{ progressMessage || '사이즈표 작업 중...' }}</p>
    </div>

    <!-- 작업 완료 후 표시 -->
    <div v-else-if="sizeChartImage" class="completed-work-container">
      <h4>사이즈표 정보</h4>

      <div class="card mb-4">
        <div class="card-header bg-light d-flex justify-content-between align-items-center">
          <span>사이즈표 결과</span>
          <div>
            <b-button variant="outline-primary" size="sm" @click="downloadSizeChartImage">
              <i class="las la-download"></i> 이미지 다운로드
            </b-button>
            <b-button variant="outline-secondary" size="sm" @click="resetSizeChart" class="ml-2">
              <i class="las la-edit"></i> 재작성
            </b-button>
          </div>
        </div>
        <div class="card-body">
          <img :src="sizeChartImage" alt="사이즈표" class="img-fluid">
        </div>
      </div>

      <!-- 다음 단계 버튼 -->
      <div class="navigation-buttons mt-4 text-center">
        <b-button variant="primary" @click="goToNextStep">
          다음 단계로
        </b-button>
      </div>
    </div>

    <!-- 초기 상태 (작업 전) -->
    <div v-else class="initial-state-container">
      <p class="text-center mb-4">
        제품에 필요한 사이즈표를 작성해야 합니다. AI를 활용하여 자동으로 생성하거나 직접 입력할 수 있습니다.
      </p>

      <div class="options-container">
        <div class="manual-input-container p-4">
          <p class="mb-4">필요한 사이즈표 정보를 직접 입력할 수 있습니다.</p>

          <div class="form-group">
            <label>사이즈 유형</label>
            <input v-model="sizeType" type="text" class="form-control"
              placeholder="사이즈 유형을 입력하세요 (예: 일반 사이즈, 의류 사이즈)" />
          </div>

          <div class="form-group">
            <label>단위</label>
            <select v-model="sizeUnits" class="form-control">
              <option value="cm">cm</option>
              <option value="mm">mm</option>
              <option value="inch">inch</option>
              <option value="㎡">㎡</option>
            </select>
          </div>

          <div class="form-group">
            <label>사이즈 목록 (쉼표로 구분)</label>
            <input v-model="sizesInput" type="text" class="form-control"
              placeholder="사이즈를 쉼표로 구분하여 입력하세요 (예: S, M, L, XL)" />
          </div>

          <div class="measurements-container mb-3">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <label><strong>측정 항목</strong></label>
              <b-button size="sm" variant="outline-primary" @click="addMeasurement">
                <i class="las la-plus"></i> 항목 추가
              </b-button>
            </div>

            <div v-for="(measurement, index) in measurements" :key="`measurement-${index}`"
              class="measurement-item mb-3 p-3 border rounded">
              <div class="d-flex justify-content-between">
                <div class="form-group mb-0 flex-grow-1 mr-2">
                  <label>측정 부위</label>
                  <input v-model="measurement.name" type="text" class="form-control"
                    :placeholder="`측정 부위 ${index + 1}`" />
                </div>
                <b-button variant="outline-danger" size="sm" @click="removeMeasurement(index)"
                  class="ml-2 align-self-end mb-2">
                  <i class="las la-trash"></i>
                </b-button>
              </div>

              <div class="values-container mt-2">
                <label>측정값 (사이즈 순서대로)</label>
                <div class="d-flex flex-wrap">
                  <div v-for="(_, valueIndex) in getSizes" :key="`value-${index}-${valueIndex}`"
                    class="size-value-input mr-2 mb-2">
                    <span class="size-label">{{ getSizes[valueIndex] }}</span>
                    <input v-model="measurement.values[valueIndex]" type="text" class="form-control"
                      :placeholder="getSizes[valueIndex]" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 사이즈표 미리보기 -->
          <div class="size-chart-preview mb-4" v-if="measurements.length > 0">
            <h5>사이즈표 미리보기</h5>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>측정 부위</th>
                  <th v-for="(size, index) in getSizes" :key="`header-${index}`">{{ size }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(measurement, index) in measurements" :key="`row-${index}`">
                  <td><strong>{{ measurement.name || `측정 부위 ${index + 1}` }}</strong></td>
                  <td v-for="(_, valueIndex) in getSizes" :key="`cell-${index}-${valueIndex}`">
                    {{ measurement.values[valueIndex] || '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- 버튼 영역 -->
          <div class="action-button-container text-center mt-4 d-flex justify-content-center">
            <b-button variant="success" class="mr-3" @click="saveManualSizeChart" :disabled="isProcessing">
              <i class="las la-save"></i> 정보 저장하기
            </b-button>
            <b-button variant="primary" @click="startSizeChartProcessing" :disabled="isProcessing">
              <i class="las la-ruler"></i> AI 사이즈표 생성 시작
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import sizeChartService from '../../services/works/sizeChartService';

export default {
  name: "SizeChartEdit",
  data() {
    return {
      // 로딩 상태
      loading: false,
      // 사이즈표 데이터
      sizeType: '일반 사이즈',
      sizeUnits: 'cm',
      sizesInput: 'S, M, L, XL',
      measurements: [
        { name: '총장', values: ['60', '65', '70', '75'] },
        { name: '어깨너비', values: ['40', '42', '44', '46'] },
        { name: '가슴단면', values: ['48', '50', '52', '54'] }
      ]
    };
  },
  computed: {
    // Vuex 상태 매핑
    ...mapState({
      selectedProduct: state => state.selectedRocketProduct,
      defaultSettings: state => state.settings[0]?.defaultSettings,
      sizeChartImage: state => state.selectedRocketProduct?.sizeChartImage,
      isProcessing: state => state.processing,
      progressMessage: state => state.progressMessage,
    }),
    // 사이즈 배열 (쉼표로 구분된 문자열을 배열로 변환)
    getSizes() {
      return this.sizesInput.split(',').map(size => size.trim()).filter(size => size);
    }
  },
  watch: {
    // 선택된 상품의 sizeChart 데이터 변경 감시
    'selectedProduct.sizeChart': {
      handler(newSizeChart) {
        if (newSizeChart) {
          console.log('사이즈표 데이터 변경 감지:', newSizeChart);

          // 사이즈 유형 업데이트
          if (newSizeChart.sizeType && this.sizeType !== newSizeChart.sizeType) {
            this.sizeType = newSizeChart.sizeType;
          }

          // 사이즈 단위 업데이트
          if (newSizeChart.sizeUnits && this.sizeUnits !== newSizeChart.sizeUnits) {
            this.sizeUnits = newSizeChart.sizeUnits;
          }

          // 사이즈 목록 업데이트
          if (newSizeChart.sizes && Array.isArray(newSizeChart.sizes)) {
            const sizesStr = newSizeChart.sizes.join(', ');
            if (this.sizesInput !== sizesStr) {
              this.sizesInput = sizesStr;
            }
          }

          // 측정 항목 업데이트
          if (newSizeChart.measurements && Array.isArray(newSizeChart.measurements)) {
            // 기존 측정 항목과 비교하여 업데이트
            const currentMeasurementNames = this.measurements.map(m => m.name);
            const newMeasurementNames = newSizeChart.measurements.map(m => m.name);

            // 새 측정 항목이 있거나 기존 항목과 다른 경우 전체 업데이트
            if (newMeasurementNames.length !== currentMeasurementNames.length ||
              !newMeasurementNames.every(name => currentMeasurementNames.includes(name))) {
              this.measurements = JSON.parse(JSON.stringify(newSizeChart.measurements));
              console.log('측정 항목 업데이트:', this.measurements);
            }
          }
        }
      },
      deep: true,
      immediate: true  // 컴포넌트 마운트 시에도 즉시 실행
    },
    // 사이즈 입력이 변경되면 측정값 배열 길이 조정
    getSizes(newSizes) {
      // 각 측정 항목의 값 배열 길이 조정
      this.measurements.forEach(measurement => {
        const currentValues = measurement.values || [];
        const newValues = [...currentValues];

        // 부족한 만큼 빈 문자열 추가
        while (newValues.length < newSizes.length) {
          newValues.push('');
        }

        // 초과분 잘라내기 (더 많은 경우)
        if (newValues.length > newSizes.length) {
          newValues.splice(newSizes.length);
        }

        measurement.values = newValues;
      });
    }
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    ...mapActions([
      'updateRocketProduct',
      'setProcessing',
      'requestSizeChartData',
    ]),
    ...mapMutations([
      'SET_SELECTED_ROCKET_PRODUCT',
    ]),
    initializeData() {
      // 선택된 상품의 사이즈표 정보가 있으면 로드
      if (this.selectedProduct && this.selectedProduct.sizeChart) {
        const sizeChart = this.selectedProduct.sizeChart;

        // 사이즈 유형 설정
        this.sizeType = sizeChart.sizeType || '일반 사이즈';

        // 사이즈 단위 설정
        this.sizeUnits = sizeChart.sizeUnits || 'cm';

        // 사이즈 목록 설정
        if (sizeChart.sizes && Array.isArray(sizeChart.sizes)) {
          this.sizesInput = sizeChart.sizes.join(', ');
        }

        // 측정 항목 설정
        if (sizeChart.measurements && Array.isArray(sizeChart.measurements)) {
          this.measurements = JSON.parse(JSON.stringify(sizeChart.measurements));
        }
      } else {
        // 상품 카테고리에 따라 기본 사이즈표 정보 설정
        this.setupDefaultSizeChart();
      }
    },

    // 카테고리에 따른 기본 사이즈표 설정
    setupDefaultSizeChart() {
      // 카테고리 경로 확인
      const categoryPath = this.selectedProduct?.categoryPath || '';

      // 의류인 경우
      if (categoryPath.includes('의류') || categoryPath.includes('패션') ||
        categoryPath.includes('의상') || categoryPath.includes('옷')) {
        this.sizeType = '의류 사이즈';
        this.sizesInput = 'S, M, L, XL';
        this.measurements = [
          { name: '총장', values: ['60', '65', '70', '75'] },
          { name: '어깨너비', values: ['40', '42', '44', '46'] },
          { name: '가슴단면', values: ['48', '50', '52', '54'] },
          { name: '소매길이', values: ['55', '57', '59', '61'] }
        ];
      }
      // 신발인 경우
      else if (categoryPath.includes('신발') || categoryPath.includes('슈즈') ||
        categoryPath.includes('운동화') || categoryPath.includes('구두')) {
        this.sizeType = '신발 사이즈';
        this.sizesInput = '240, 250, 260, 270';
        this.measurements = [
          { name: '발길이', values: ['24.0', '25.0', '26.0', '27.0'] },
          { name: '발폭', values: ['9.0', '9.5', '10.0', '10.5'] },
          { name: '굽높이', values: ['2.0', '2.0', '2.0', '2.0'] }
        ];
      }
      // 가구인 경우
      else if (categoryPath.includes('가구') || categoryPath.includes('테이블') ||
        categoryPath.includes('의자') || categoryPath.includes('소파')) {
        this.sizeType = '가구 규격';
        this.sizesInput = '1인용, 2인용, 3인용';
        this.measurements = [
          { name: '가로', values: ['80', '120', '180'] },
          { name: '세로', values: ['70', '70', '70'] },
          { name: '높이', values: ['80', '80', '80'] }
        ];
      }
      // 기본 사이즈표
      else {
        this.sizeType = '일반 사이즈';
        this.sizesInput = 'S, M, L, XL';
        this.measurements = [
          { name: '가로', values: ['30', '35', '40', '45'] },
          { name: '세로', values: ['20', '25', '30', '35'] },
          { name: '높이', values: ['10', '12', '14', '16'] }
        ];
      }
    },

    // 측정 항목 추가
    addMeasurement() {
      // 빈 값으로 초기화된 새 측정 항목 추가
      const emptyValues = Array(this.getSizes.length).fill('');
      this.measurements.push({
        name: '',
        values: emptyValues
      });
    },

    // 측정 항목 삭제
    removeMeasurement(index) {
      this.measurements.splice(index, 1);
    },

    // 수동으로 입력한 사이즈표 저장
    async saveManualSizeChart() {
      try {
        // 로딩 상태 시작
        this.setProcessing({
          isProcessing: true,
          message: '사이즈표 저장 중...'
        });

        // 사이즈 목록 변환 (문자열 -> 배열)
        const sizes = this.sizesInput.split(',').map(size => size.trim()).filter(size => size);

        // 측정 항목 정리 (빈 항목 필터링)
        const cleanMeasurements = this.measurements
          .filter(m => m.name)
          .map(m => ({
            name: m.name,
            values: m.values.slice(0, sizes.length)
          }));

        // 사이즈표 데이터 객체 생성
        const sizeChartData = {
          sizeType: this.sizeType,
          sizeUnits: this.sizeUnits,
          sizes: sizes,
          measurements: cleanMeasurements
        };

        // 상품 복제 (깊은 복사)
        const updatedProduct = JSON.parse(JSON.stringify(this.selectedProduct));

        // 사이즈표 정보 업데이트
        updatedProduct.sizeChart = sizeChartData;

        // 이미지가 없으면 이미지 생성
        if (!updatedProduct.sizeChartImage) {
          const imageBase64 = await sizeChartService.generateSizeChartImage(sizeChartData);
          const imageUrl = await sizeChartService.uploadSizeChartImage(updatedProduct, imageBase64);
          updatedProduct.sizeChartImage = imageUrl;
        }

        // 상태 업데이트
        updatedProduct.sizeChartStatus = 'completed';

        // 각 옵션의 coupangDoc 업데이트
        if (updatedProduct.options && updatedProduct.options.length > 0) {
          for (const option of updatedProduct.options) {
            option.sizeChartStatus = 'completed';

            // coupangDoc 업데이트
            if (option.coupangDoc && option.coupangDoc.jsonDocument &&
              option.coupangDoc.jsonDocument[0] &&
              option.coupangDoc.jsonDocument[0].imagePage &&
              option.coupangDoc.jsonDocument[0].imagePage.images) {

              // sizeChartImage 필드 업데이트
              option.coupangDoc.jsonDocument[0].imagePage.images.sizeChartImage = updatedProduct.sizeChartImage;
            }
          }
        }

        // Vuex 상태 업데이트
        await this.updateRocketProduct(updatedProduct);

        this.setProcessing({ isProcessing: false });
        alert('사이즈표가 저장되었습니다.');

      } catch (error) {
        console.error('사이즈표 저장 중 오류:', error);
        this.setProcessing({ isProcessing: false });
        alert('사이즈표 저장 중 오류가 발생했습니다.');
      }
    },

    // SizeChartEdit.vue - startSizeChartProcessing 메서드

    /**
     * AI 사이즈표 생성 시작
     */
    async startSizeChartProcessing() {
      try {
        // 로딩 상태 시작
        this.setProcessing({
          isProcessing: true,
          message: '사이즈표 생성 요청 중...'
        });

        // 선택된 상품의 복사본 생성
        const product = JSON.parse(JSON.stringify(this.selectedProduct));

        // sizeChartService를 사용하여 사이즈표 생성
        const success = await sizeChartService.process(product);

        if (!success) {
          throw new Error('사이즈표 생성에 실패했습니다.');
        }

      } catch (error) {
        console.error('사이즈표 생성 중 오류:', error);
        this.setProcessing({ isProcessing: false });
        alert('사이즈표 생성 중 오류가 발생했습니다.');
      }
    },

    // 사이즈표 이미지 다운로드
    downloadSizeChartImage() {
      if (!this.sizeChartImage) return;

      const link = document.createElement('a');
      link.href = this.sizeChartImage;
      link.download = 'size_chart.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // 사이즈표 초기화
    resetSizeChart() {
      if (confirm('사이즈표를 재작성 하시겠습니까?')) {
        const product = JSON.parse(JSON.stringify(this.selectedProduct));
        product.sizeChartImage = null;
        this.SET_SELECTED_ROCKET_PRODUCT(product);
      }
    },

    // 다음 단계로 이동
    goToNextStep() {
      this.$emit('next');
    }
  }
}
</script>

<style scoped>
.section-title {
  margin-bottom: 20px;
  color: #333;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.loading-container p {
  margin-top: 15px;
  color: #666;
}

.completed-work-container {
  margin-bottom: 30px;
}

.size-value-input {
  position: relative;
  width: 80px;
}

.size-label {
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 12px;
  font-weight: bold;
  color: #666;
}

.measurement-item {
  background-color: #f8f9fa;
}

.size-chart-preview {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

.size-chart-preview h5 {
  margin-bottom: 15px;
  color: #333;
}

.size-chart-preview table {
  width: 100%;
}

.action-button-container {
  margin-top: 30px;
}
</style>