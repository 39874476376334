<template>
    <div class="progress-bar-container">
      <!-- 진행률 바 -->
      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{ width: progressPercent + '%' }"
          :aria-valuenow="progressPercent"
          aria-valuemin="0"
          :aria-valuemax="100"
        ></div>
      </div>
      <!-- 단계별 라벨 표시 -->
      <div class="steps-labels">
        <div
          v-for="(step, index) in steps"
          :key="index"
          class="step-label"
          :class="{ 
            active: index < currentStep, 
            current: index + 1 === currentStep 
          }"
          @click="$emit('go-to-step', index + 1)"
        >
          <span class="step-number">{{ index + 1 }}</span>
          <span class="step-text">{{ step }}</span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ProgressBar",
    props: {
      currentStep: {
        type: Number,
        required: true
      },
      steps: {
        type: Array,
        required: true
      }
    },
    computed: {
      progressPercent() {
        return (this.currentStep / this.steps.length) * 100;
      }
    }
  }
  </script>
  
  <style scoped>
  .progress-bar-container {
    margin: 15px 0;
  }
  
  .progress {
    height: 6px;
    background-color: #e9ecef;
    border-radius: 3px;
    overflow: hidden;
  }
  
  .progress-bar {
    background-color: #3498db;
    transition: width 0.3s ease;
  }
  
  .steps-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  
  .step-label {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    color: #aaa;
    cursor: pointer;
    margin: 5px 0;
    transition: all 0.2s ease;
  }
  
  .step-label:hover {
    color: #666;
  }
  
  .step-label.active {
    color: #3498db;
  }
  
  .step-label.current {
    font-weight: bold;
  }
  
  .step-number {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #eee;
    color: #666;
    margin-right: 5px;
    font-size: 0.75rem;
  }
  
  .step-label.active .step-number {
    background-color: #3498db;
    color: white;
  }
  
  .step-text {
    white-space: nowrap;
  }
  
  @media (max-width: 768px) {
    .steps-labels {
      justify-content: flex-start;
      gap: 10px;
    }
  }
  </style>